import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { truncateOnWordBoundary } from 'app/utilities/truncate';
import { metaTime, publishedTime } from 'app/utilities/date-time';

const CHARACTER_LIMIT = {
    DESCRIPTION: 90,
    TITLE: 90
};

class ExtendedArticleTile extends React.Component {
    render() {
        const { item } = this.props;

        let style = {};

        const {
            author,
            name,
            publish_at: publishDate,
            short_description: description,
            thumbnail
        } = item.data;

        if (thumbnail) {
            style = { backgroundImage: `url(${thumbnail})` };
        }

        return (
            <article className="extended-article-tile">
                <div className="extended-article-tile-inner">
                    <EntityLink
                        item={item}
                        promoterBuName="Auckland Zoo"
                        className="tile-image-header || image-overlay"
                        style={style}
                    >
                        <div className="heading-group">
                            <span className="flag"><Icon name="chevron" /></span>
                            <h3 className="title">{truncateOnWordBoundary(name, CHARACTER_LIMIT.TITLE)}</h3>
                        </div>
                    </EntityLink>
                    <div className="tile-content">
                        {description && <p className="subtitle">{truncateOnWordBoundary(description, CHARACTER_LIMIT.DESCRIPTION)}</p>}
                        <p className="meta">
                            {publishDate && <time dateTime={metaTime(publishDate)}>{publishedTime(publishDate)}</time>}
                            {author && <br />}
                            {author && <span className="author">by {author}</span>}
                        </p>
                    </div>
                </div>
            </article>
        );
    }
}

ExtendedArticleTile.propTypes = {
    item: PropTypes.object.isRequired
};

export default ExtendedArticleTile;
