import classnames from 'classnames';
import Controls from './scalable-object-controls';
import PropTypes from 'prop-types';
import updateBody from 'app/utilities/update-body';
import { clamp, coordChange, eventNames, isTouchDevice, midpoint, touchDistance, touchPoint } from 'app/utilities/scalable-object';
import React, { Component } from 'react';

const COMPONENT_NAME = 'scalable-object';
const INTERVAL = 1000;
const MAX_ZOOM = 5;
const BREAKPOINT = 800;

/*
  This contains logic for providing a map-like interaction to any DOM node.
  It allows a user to pinch, zoom, translate, etc, as they would an interactive map.
  It renders its children with the current state of the translation and  does not do any  scaling
  or translating on its own. This works on both desktop, and mobile.
*/
class MapInteraction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHowToScroll: true,
            isFullscreen: false,
            isTouchDevice: false,
            allowWheel: true,
            dragged: false,
            scale: 0,
            translation: {
                x: 0,
                y: 0
            },
            minScale: 0,
            dimensions: {
                imgWidth: 0,
                imgHeight: 0,
                containerWidth: 0,
                containerHeight: 0
            }
        };

        this.startPointerInfo = undefined;

        // Mouse Events
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);

        // Wheel Events
        this.onWheel = this.onWheel.bind(this);

        // Touch Events
        this.onTouchDown = this.onTouchDown.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
    }


    // Lifecycle
    componentDidMount() {
        const events = eventNames();
        const handlers = this.handlers();

        this.containerNode.addEventListener(events.down, handlers.down);
        this.containerNode.addEventListener(events.move, handlers.move);
        this.containerNode.addEventListener(events.up, handlers.up);

        // Set touch device to toggle options
        this.setState({ isTouchDevice: isTouchDevice() });

        this.interval = setInterval(() => {
            if (this.handleReset()) {
                clearInterval(this.interval);
            }
        }, INTERVAL);
    }

    componentWillUnmount() {
        const events = eventNames();
        const handlers = this.handlers();

        this.containerNode.removeEventListener(events.down, handlers.down);
        this.containerNode.removeEventListener(events.move, handlers.move);
        this.containerNode.removeEventListener(events.up, handlers.up);

        clearInterval(this.interval);
    }


    // Helpers
    onMouseDown(event) {
        this.setPointerState([event]);
    }

    onTouchDown(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setPointerState(event.touches);
    }

    onMouseUp() {
        this.setPointerState();
    }

    onTouchEnd(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setPointerState(event.touches);
    }

    onMouseMove(event) {
        if (!this.startPointerInfo) {
            return;
        }

        this.onDrag(event);
    }

    onTouchMove(event) {
        if (!this.startPointerInfo) {
            return;
        }

        this.setState({ showHowToScroll: false });

        // Allow regular scroll with one finger on isFullscreen
        const minTouchPointsForScrolling = this.state.isFullscreen ? 1 : 2;

        if (event.touches.length === 2 && this.startPointerInfo.pointers.length > 1) {
            // Pinch and zoom requires two fingers
            this.scaleFromMultiTouch(event);
        } else if (event.touches.length >= minTouchPointsForScrolling && this.startPointerInfo) {
            // Scroll requires two fingers
            this.onDrag(event.touches[0]);
        }
    }

    onDrag(pointer) {
        this.setState({ showHowToScroll: false });

        // Handles both touch and mouse drags
        const { translation, pointers } = this.startPointerInfo;
        const startPointer = pointers[0];
        const dragX = pointer.clientX - startPointer.clientX;
        const dragY = pointer.clientY - startPointer.clientY;

        this.setState({
            translation: this.clampTranslation(translation.x + dragX, translation.y + dragY, this.state.scale),
            dragged: Boolean(dragX || dragY)
        });
    }

    onWheel(event) {
        // Only allow mouse scroll to zoom if ctrlKey is being pressed
        if (event.ctrlKey) {
            this.setState({ showHowToScroll: false });

            event.preventDefault();
            event.stopPropagation();

            const scaleChange = 2 ** (event.deltaY * 0.002); // eslint-disable-line no-magic-numbers

            const newScale = clamp(this.state.minScale, this.state.scale + (1 - scaleChange), this.state.minScale * MAX_ZOOM);

            const mousePos = this.clientPosToTranslatedPos({ x: event.clientX, y: event.clientY });

            this.scaleFromPoint(newScale, mousePos);
        }
    }


    // Helpers
    setPointerState(pointers) {
        if (!pointers) {
            this.startPointerInfo = undefined;

            return;
        }

        this.startPointerInfo = {
            pointers,
            scale: this.state.scale,
            translation: this.state.translation
        };
    }

    translatedOrigin(translation = this.state.translation) {
        const clientOffset = this.containerNode.getBoundingClientRect();

        return {
            x: clientOffset.left + translation.x,
            y: clientOffset.top + translation.y
        };
    }

    clientPosToTranslatedPos({ x, y }, translation = this.state.translation) {
        const origin = this.translatedOrigin(translation);

        return {
            x: x - origin.x,
            y: y - origin.y
        };
    }

    handlers() {
        const isTouch = isTouchDevice();

        return {
            down: isTouch ? this.onTouchDown : this.onMouseDown,
            move: isTouch ? this.onTouchMove : this.onMouseMove,
            up:   isTouch ? this.onTouchEnd : this.onMouseUp
        };
    }

    scaleFromPoint(newScale, focalPt) {
        const { translation, scale } = this.state;
        const scaleRatio = newScale / scale;

        const focalPtDelta = {
            x: coordChange(focalPt.x, scaleRatio),
            y: coordChange(focalPt.y, scaleRatio)
        };

        const newTranslation = this.clampTranslation(translation.x - focalPtDelta.x, translation.y - focalPtDelta.y, newScale);

        this.setState({ scale: newScale, translation: newTranslation });
    }

    scaleFromMultiTouch(event) {
        const startTouches = this.startPointerInfo.pointers;
        const newTouches = event.touches;

        // Calculate new scale
        const dist0 = touchDistance(startTouches[0], startTouches[1]);
        const dist1 = touchDistance(newTouches[0], newTouches[1]);
        const scaleChange = dist1 / dist0;
        const targetScale = this.startPointerInfo.scale + (scaleChange - 1);
        const newScale = clamp(this.state.minScale, targetScale, this.state.minScale * MAX_ZOOM);

        // Calculate mid points
        const newMidPoint = midpoint(touchPoint(newTouches[0]), touchPoint(newTouches[1]));
        const startMidpoint = midpoint(touchPoint(startTouches[0]), touchPoint(startTouches[1]));

        const dragDelta = {
            x: newMidPoint.x - startMidpoint.x,
            y: newMidPoint.y - startMidpoint.y
        };

        const scaleRatio = newScale / this.startPointerInfo.scale;

        const focalPt = this.clientPosToTranslatedPos(startMidpoint, this.startPointerInfo.translation);
        const focalPtDelta = {
            x: coordChange(focalPt.x, scaleRatio),
            y: coordChange(focalPt.y, scaleRatio)
        };

        const newTranslation = this.clampTranslation(this.startPointerInfo.translation.x - focalPtDelta.x + dragDelta.x, this.startPointerInfo.translation.y - focalPtDelta.y + dragDelta.y, newScale);

        this.setState({ scale: newScale, translation: newTranslation });
    }

    discreteScaleStepSize() {
        const BASE_STEP_SIZE = 10;
        const { minScale } = this.state;
        const delta = Math.abs(minScale * MAX_ZOOM - minScale);

        return delta / BASE_STEP_SIZE;
    }

    changeScale(delta) {
        this.setState({ showHowToScroll: false });

        const targetScale = this.state.scale + delta;
        const { minScale } = this.state;
        const scale = clamp(minScale, targetScale, minScale * MAX_ZOOM);

        const rect = this.containerNode.getBoundingClientRect();
        const x = rect.left + (rect.width / 2);
        const y = rect.top + (rect.height / 2);

        const focalPoint = this.clientPosToTranslatedPos({ x, y });
        this.scaleFromPoint(scale, focalPoint);
    }


    // Handlers
    handleToggleFullscreen() {
        const { isFullscreen } = this.state;

        this.setState({
            showHowToScroll: false,
            isFullscreen: !isFullscreen
        }, () => {
            // Add a setTimout to remove render blocking state (Weird bug with Safari not re-rendering the map)
            setTimeout(() => {
                // Will disable or enable scrolling for new state
                updateBody(!isFullscreen);

                this.handleReset();
            }, 1);
        });
    }

    handleReset() {
        const img = this.containerNode.querySelector(`img.${COMPONENT_NAME}-image`);
        if (img) {
            const imgWidth = img.naturalWidth;
            const imgHeight = img.naturalHeight;

            if (imgWidth && imgHeight) {
                const { width: containerWidth, height: containerHeight } = this.containerNode.getBoundingClientRect();
                const centerMap = window.innerWidth >= BREAKPOINT;
                const minScale = centerMap ? Math.min(containerWidth / imgWidth, containerHeight / imgHeight) : Math.max(containerWidth / imgWidth, containerHeight / imgHeight);
                const x = containerWidth - imgWidth * minScale;
                const y = containerHeight - imgHeight * minScale;

                this.setState({
                    scale: minScale,
                    translation: {
                        x: centerMap ? (x / 2) : x,
                        y: centerMap ? (y / 2) : y
                    },
                    minScale,
                    dimensions: {
                        imgWidth,
                        imgHeight,
                        containerWidth,
                        containerHeight
                    }
                });

                return true;
            }

            return false;
        }

        return true;
    }

    clampTranslation(x, y, scale) {
        const { dimensions } = this.state;

        const nextX = clamp(dimensions.containerWidth - dimensions.imgWidth * scale, x, 0);
        const nextY = clamp(dimensions.containerHeight - dimensions.imgHeight * scale, y, 0);

        return {
            x: nextX > 0 ? (nextX / 2) : nextX,
            y: nextY > 0 ? (nextY / 2) : nextY
        };
    }


    // Render
    render() {
        const { showControls, children } = this.props;
        const { dragged, isFullscreen, isTouchDevice, scale, showHowToScroll, translation, minScale } = this.state;

        const step = this.discreteScaleStepSize();

        const componentClass = classnames(COMPONENT_NAME, {
            'is-fullscreen': isFullscreen
        });

        return (
            <section className={componentClass}>
                <div
                    className={`${COMPONENT_NAME}-inner`}
                    ref={(node) => { this.containerNode = node; }}
                    onWheel={this.onWheel}
                    onClickCapture={(event) => {
                        if (dragged) {
                            event.stopPropagation();
                            this.setState({ dragged: false });
                        }
                    }}>

                    {children && children({ translation, scale })}

                    {showControls &&
                        <Controls
                            componentName={COMPONENT_NAME}
                            isFullscreen={isFullscreen}
                            handleReset={() => this.handleReset()}
                            handleToggleFullscreen={() => this.handleToggleFullscreen()}
                            handleScalePlus={() => this.changeScale(step)}
                            handleScaleMinus={() => this.changeScale(-step)}
                            minScale={minScale}
                            maxScale={minScale * MAX_ZOOM}
                            scale={scale} />
                    }
                </div>
                {showHowToScroll &&
                    <div className={`${COMPONENT_NAME}-meta`}>
                        <p>
                            {isTouchDevice &&
                                <span>Pinch and zoom to scale the map</span>
                                ||
                                <span>Use <span className="keyboard-hint">Ctrl</span> + scroll to zoom the map</span>
                            }
                        </p>
                    </div>
                }
            </section>
        );
    }
}

MapInteraction.defaultProps = {
    showControls: true
};

MapInteraction.propTypes = {
    children: PropTypes.func,
    showControls: PropTypes.bool
};

export default MapInteraction;
