/* eslint-disable camelcase */
import classnames from 'classnames';
import { convertToSentenceCasing } from 'app/utilities/format-text';
import { generatePassholdersDisplayInfo } from 'app/utilities/buy-memberships';
import { Link } from 'react-router-dom';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const MembershipDetailsCard = ({ data }) => {
    const { pass_number: passNumber, expires_at: expiresAt, passholder_counts: passholderCounts, status, has_guest_pass: hasGuestPass, can_renew: canRenew } = data;

    const statusLowerCase = status.toLowerCase();

    const isActive = statusLowerCase === 'active';
    const isPending = statusLowerCase === 'pending';
    const isExpired = statusLowerCase === 'expired';
    const isExpiring = statusLowerCase === 'expiring';
    const isExpiringOrExpired = isExpired || isExpiring;

    const membershipDetailsCardClass = classnames('inner || highlighter || membership-details-card', {
        'is-expired': isExpired
    });

    const statusCircleClass = classnames('status-circle', {
        'is-pending': isPending,
        'is-active': isActive,
        'is-expiring': isExpiring,
        'is-expired': isExpired
    });

    const displayStatus = isExpiring ? 'Expiring soon' : convertToSentenceCasing(status);

    const formattedPassholderDetail = [];
    for (const [key, value] of Object.entries(passholderCounts)) {
        if (value > 0) formattedPassholderDetail.push({ product: key, quantities: value });
    }

    if (hasGuestPass) formattedPassholderDetail.push({ product: 'adult guest', quantities: 1 });

    const displayPassDetails = generatePassholdersDisplayInfo(formattedPassholderDetail);

    return (
        <section className="block">
            <div className="content">
                <div className={membershipDetailsCardClass}>
                    <div className="membership-details-row">
                        <div className="membership-details-colomn">
                            <h2>Membership</h2>
                            {passNumber && <p className="text-light">Annual Pass Number: <span className="text-semibold text-dark">{passNumber}</span></p>}
                        </div>
                        <div className="membership-details-colomn membership-status-expires">
                            <p className="membership-status">
                                <span className={statusCircleClass}></span>
                                <span className="text-semibold">{displayStatus}</span>
                            </p>
                            {expiresAt && <p className="membership-expires text-light">{isExpired ? 'Expired' : 'Expires'}: <span className="text-dark text-semibold">{moment(expiresAt).format('DD/MM/YYYY')}</span></p>}
                        </div>
                    </div>
                    <div className="membership-details-row">
                        <div className="membership-details-colomn">
                            <p className="text-small text-light">Annual Pass</p>
                            <p className="text-semibold">{displayPassDetails}</p>
                        </div>
                        <div className="button-group membership-details-colomn">
                            {!isExpired && <Link className="button primary" to={MEMBERS_PORTAL_ROUTES.MEMBER_DETAILS}>Scan Pass</Link>}
                            {canRenew && <Link className="button" to={MEMBERS_PORTAL_ROUTES.RENEW}>Renew Pass</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

MembershipDetailsCard.propTypes = {
    data: PropTypes.shape({
        pass_number: PropTypes.string,
        expires_at: PropTypes.string,
        passholder_counts: PropTypes.object.isRequired,
        status: PropTypes.string.isRequired,
        has_guest_pass: PropTypes.bool.isRequired,
        can_renew: PropTypes.bool.isRequired,
    })
};

export default MembershipDetailsCard;
