import AuthCredentials from 'app/components/partials/portal/auth/credentials';
import BgImage from 'static/images/login-bg.jpg';
import LoginForm from 'app/components/partials/portal/auth/login-form';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import { useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';

const LoginPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isAuth = useSelector((state) => state.auth.isAuth);
    const [searchParams] = useSearchParams();
    const redirectQuery = searchParams.get('redirect');

    const pathname =
        location.state &&
        location.state.from &&
        location.state.from.pathname ||
        MEMBERS_PORTAL_ROUTES.DASHBOARD;
    const search =
        location.state &&
        location.state.from &&
        location.state.from.search || '';

    useEffect(() => {
        if (isAuth) {
            // Redirect query might have query param (?) so we need to pass in the whole path as a To string, otherwise it will throw error
            if (redirectQuery) {
                navigate(redirectQuery);
            } else {
                navigate({ pathname, search: createSearchParams(search).toString() });
            }
        }
    }, [isAuth]);

    return (
        <AuthCredentials title="Login" imgSrc={BgImage}>
            <h2 className="title || heading-2">Membership Login</h2>
            <LoginForm />
        </AuthCredentials>
    );
};

export default LoginPage;
