// The function pushes data to the GTM data layer.
// The dataLayer object is defined in the global scope by the GTM script.
import ACTrackingCode from 'app/utilities/ac-tracking';
import canUseDom from './dom';

export const dataLayerPush = (data) => {
    try {
        dataLayer.push(data); // eslint-disable-line no-undef
    } catch (e) {} // eslint-disable-line no-empty
};

const useWisepops = () => {
    try {
        wisepops('pageview'); // eslint-disable-line no-undef
    } catch (e) {} // eslint-disable-line no-empty
};

/**
 * This fires the GTM event 'delayedHistoryChange' and Wisepops and ActiveCampaign tracking.
 * This event is used to update the data layer with the most recent page data.
 * @return {undefined}
 */
export const fireDelayedHistoryChange = () =>
    setTimeout(() => {
        dataLayerPush({
            event: 'delayedHistoryChange'
        });

        // Only fire on client side
        if (canUseDom()) {
            useWisepops();
            ACTrackingCode();
        }
    }, 100); // eslint-disable-line no-magic-numbers


/**
 * This fires the GTM event 'newsStorySelector'.
 * This event is used to update the data layer with the selected news category
 * @param  {string} category
 * @return {undefined}
 */
export const fireNewsStorySelector = (category) =>
    setTimeout(() =>
        dataLayerPush({
            event: 'newsStorySelector',
            action: 'news category selected',
            text: category
        }), 100); // eslint-disable-line no-magic-numbers

// Zoo membership portal ecommerce events
export const fireEcommerceEvent = (event, payload) => {
    // MBM's instructions - clear ecommerce object before every push
    dataLayerPush({ ecommerce: null });
    // Push the actual ecommerce event
    dataLayerPush({
        event,
        ecommerce: payload
    });
};
