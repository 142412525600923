/* eslint-disable require-jsdoc */
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { stoppedRoutingAction } from './status';
import { combineEpics, ofType } from 'redux-observable';
import { fromJS, Map } from 'immutable';
import { getApiUrl, getRouteArray } from 'app/utilities/url';
import { push, replace } from 'redux-first-history';

export const INITIAL_STATE = new Map({});

// Actions
export const GET_PAGE_DATA = 'rfa-zoo-website/page/GET_PAGE_DATA';
export const GET_PAGE_DATA_FAILURE = 'rfa-zoo-website/page/GET_PAGE_DATA_FAILURE';
export const GET_PAGE_DATA_SUCCESS = 'rfa-zoo-website/page/GET_PAGE_DATA_SUCCESS';

// Action Creators
export const getPageDataAction = (location) => ({
    type: GET_PAGE_DATA,
    payload: {
        location
    }
});

export const getPageDataSuccessAction = (pathname, response) => ({
    type: GET_PAGE_DATA_SUCCESS,
    payload: {
        routeArray: getRouteArray(pathname),
        pageObject: response
    }
});

export const getPageDataFailureAction = (pathname, error) => ({
    type: GET_PAGE_DATA_FAILURE,
    payload: {
        routeArray: getRouteArray(pathname),
        error
    }
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PAGE_DATA_SUCCESS:
            return getPageDataSuccess(state, action);
        case GET_PAGE_DATA_FAILURE:
            return getPageDataFailure(state, action);
        default:
            return state;
    }
};

function getPageDataSuccess(state, action) {
    const { routeArray, pageObject } = action.payload;

    return state.setIn(routeArray, fromJS(pageObject));
}

function getPageDataFailure(state, action) {
    const { routeArray, error } = action.payload;

    return state.setIn(routeArray, fromJS({ data: [{ error: error }] }));
}

// Action/Action sequence creators
const getPageDataSuccessActionSequence = (location, response) => {
    if (response.errors) {
        return [push('/error')];
    }

    return [
        getPageDataSuccessAction(location.pathname, response),
        replace(location),
        stoppedRoutingAction()
    ];
};

const getPageDataFailureActionSequence = (location, error) => [
    getPageDataFailureAction(location.pathname, error),
    replace(location),
    stoppedRoutingAction()
];

// Epic creator

/**
 * @callback getApiUrl
 * @param {string} url - requested URL
 * @returns {string} endpoint - API endpoint
 */

/**
 * @callback getPageDataSuccessActionSequence
 * @param {string} url - requested URL
 * @param {object} response - response object
 * @returns {array} actions - action sequence
 */

/**
 * @callback getPageDataFailureActionSequence
 * @param {string} url - requested URL
 * @param {object} error - error object
 * @returns {array} actions - action sequence
 */

/**
 * Creates Page epic
 * @param {getApiUrl} getApiUrl - generates endpoint from requested URL
 * @param {getPageDataSuccessActionSequence} getPageDataSuccessActionSequence - action sequence creator
 * @param {getPageDataFailureActionSequence} getPageDataFailureActionSequence - action sequence creator
 */
export const createPageEpic = (getApiUrl, getPageDataSuccessActionSequence, getPageDataFailureActionSequence) => {
    return (action$) => action$.pipe(
        ofType(GET_PAGE_DATA),
        mergeMap(({ payload: { location } }) => fetch(getApiUrl(location.pathname))
            .then((response) => getPageDataSuccessActionSequence(location, response))
            .catch((error) => getPageDataFailureActionSequence(location, error))
        )
    );
};

// Epics
const pageEpic = createPageEpic(
    getApiUrl,
    getPageDataSuccessActionSequence,
    getPageDataFailureActionSequence
);

export const epics = combineEpics(pageEpic);
