import Banner from 'app/components/partials/banner';
import Helmet from 'react-helmet';
import image404 from 'static/images/404.png';
import React, { Component } from 'react';

class ErrorPage extends Component {
    render() {
        return (
            <main className="page" role="main">
                <Helmet>
                    <title>404 Page not found</title>
                </Helmet>
                <Banner
                    title="Whoops!"
                    introduction="Sorry, we have recently re-organised the site and the page you were looking for appears to have been moved, deleted or does not exist."
                    images={[image404]}
                    contain={true}
                />
            </main>
        );
    }
}

export default ErrorPage;
