import { CUSTOMISE_STEP_INDEX } from 'config/buy-memberships-steps';
import { generatePassholdersDisplayInfo } from 'app/utilities/buy-memberships';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';


const ProductsInfoBanner = ({ costsAfterDiscount, stepClickHandler }) => {
    const productsSelected = useSelector((state) => state.buyMemberships.productsSelected);
    const displayInfo = generatePassholdersDisplayInfo(productsSelected);

    return (
        <div className="info-banner">
            <p className="tint-text">Annual Pass</p>
            <p><strong>${costsAfterDiscount}</strong></p>
            <p>
                {displayInfo}
                <button
                    className="edit-btn"
                    onClick={() => stepClickHandler(CUSTOMISE_STEP_INDEX)}
                >
                    Edit
                </button>
            </p>
        </div>
    );
};

ProductsInfoBanner.propTypes = {
    costsAfterDiscount: PropTypes.string.isRequired,
    stepClickHandler: PropTypes.func.isRequired
};

export default ProductsInfoBanner;
