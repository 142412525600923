import Article from './article';
import DayPass from './day-pass';
import GoConnect from './go-connect';
import Home from './home';
import News from './news';
import Page from './page';
import PropTypes from 'prop-types';
import Tickets from './tickets';
import ZooMap from './zoo-map';
import { zooSnaps } from 'config/go-connect';
import React, { Component } from 'react';

class Template extends Component {
    render() {
        const { data, globalFields, type } = this.props;

        // 'type' is defined by the templateType() utility function
        switch (type) {
            case 'home':
                return <Home data={data} globalFields={globalFields}/>;
            case 'news':
                return <News data={data} />;
            case 'article':
                return <Article data={data} />;
            case 'tickets':
                return <Tickets data={data} />;
            case 'zoosnaps':
                return <GoConnect data={data} {...zooSnaps} />;
            case 'zoo-map':
                return <ZooMap data={data} globalFields={globalFields} />;
            case 'day-pass':
                return <DayPass data={data} />;
            case 'page':
            default:
                return <Page data={data} />;
        }
    }
}

Template.propTypes = {
    data: PropTypes.object,
    globalFields: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};

export default Template;
