import * as format from 'app/utilities/format';
import Encounter from 'app/components/partials/encounter';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import React from 'react';
import { createCurrentNzDateObject, dateObjectToTimeAsANumber } from 'app/utilities/date-time';

const WEEK_DAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
];

class Encounters extends React.Component {
    constructor(props) {
        super(props);
        this.props.loadInitialData(this.props.initialData);
    }


    // Helper
    isUpNext(currentEncounterTimeNzAsANumber, currentEncounterIndex) {
        const { data: encounters } = this.props;

        const currentTimeNz = createCurrentNzDateObject();
        const currentTimeNzAsANumber = dateObjectToTimeAsANumber(currentTimeNz);

        if (currentEncounterIndex) {
            const previousEncounter = encounters[currentEncounterIndex - 1];
            const previousEncounterTimeNzAsANumber = previousEncounter.time;

            return previousEncounterTimeNzAsANumber <= currentTimeNzAsANumber && currentTimeNzAsANumber < currentEncounterTimeNzAsANumber;
        }

        // else the current encounter is the first encounter
        return currentTimeNzAsANumber < currentEncounterTimeNzAsANumber;
    }


    // Handler
    submitWeekDaySelectorForm() {
        this.props.changeWeekDay(this.weekDaySelector.value);
    }


    // Render
    renderWeekDaySelector() {
        const options = [
            'today',
            'tomorrow',
            ...[
                ...WEEK_DAYS,
                ...WEEK_DAYS
            ].slice(createCurrentNzDateObject().getDay()).slice(2, 7) // eslint-disable-line no-magic-numbers
        ];

        return (
            <form
                className="week-day-selector"
                onSubmit={(event) => {
                    event.preventDefault();
                    this.submitWeekDaySelectorForm();
                }}
            >
                <label className="wrapper" aria-hidden="true" htmlFor="week-day-selector">
                    <span className="week-day">{this.props.weekDay}</span>
                    <Icon name="chevron" title="Select day" />
                    <select
                        id="week-day-selector"
                        className="select"
                        ref={(weekDaySelector) => { this.weekDaySelector = weekDaySelector; } }
                        value={this.props.weekDay}
                        onChange={(event) => {
                            event.preventDefault();
                            this.submitWeekDaySelectorForm();
                        }}
                    >
                        {options.map((weekDay, weekDayIndex) => (
                            <option key={weekDayIndex} value={weekDay}>{weekDay}</option>
                        ))}
                    </select>
                </label>
            </form>
        );
    }

    renderEncounters() {
        const { data: encounters, weekDay } = this.props;

        return (
            <div className={`encounters${encounters ? '' : ' is-loading'}`}>
                {!encounters && <Loader />}
                {encounters && encounters.length !== 0 && encounters
                    .sort((a, b) => a.time - b.time)
                    .map((item, index) => {
                        const itemData = format.encounter(item);
                        const upNext = this.props.weekDay === 'today' && this.isUpNext(item.time, index);

                        return <Encounter key={index} {...itemData} upNext={upNext}/>;
                    })}
                {encounters && encounters.length === 0 && <p className="error-message">No encounters found for {weekDay}.</p>}
            </div>
        );
    }

    render() {
        return (
            <section className="encounters-block">
                <header className="section-header">
                    <div className="constrain-width large">
                        <div className="inner">
                            <div className="title-wrapper">
                                <h2 className="title || heading-1">Keeper talks on {this.renderWeekDaySelector()}</h2>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="constrain-width large">
                    {this.renderEncounters()}
                </div>
            </section>
        );
    }
}

Encounters.propTypes = {
    changeWeekDay: PropTypes.func.isRequired,
    data: PropTypes.array,
    initialData: PropTypes.array.isRequired,
    loadInitialData: PropTypes.func.isRequired,
    weekDay: PropTypes.string.isRequired
};

export default Encounters;
