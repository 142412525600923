/* eslint-disable require-jsdoc */

export function selectFieldTouched(state, fieldName) {
    return state.donationPayment.fields[fieldName].touched;
}

export function selectFieldValue(state, fieldName) {
    return state.donationPayment.fields[fieldName].value;
}

export function selectFormError(state) {
    return state.donationPayment.error;
}

export function selectSubmitted(state) {
    return state.donationPayment.submitted;
}

export function selectSubmittedSuccessfully(state) {
    return state.donationPayment.submittedSuccessfully;
}
