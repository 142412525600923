import * as Yup from 'yup';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import Icon from 'app/components/partials/icon';
import Input from 'app/components/partials/input';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import React from 'react';
import RecaptchaPolicy from 'app/components/partials/recaptcha-policy';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { userLoginAction } from 'app/ducks/auth';

const LoginForm = () => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Not a valid email address').required('This is a required field'),
            password: Yup.string()
                .required('This is a required field')
                .min(8, 'Password must contain at least 8 characters'),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setStatus();
            dispatch(userLoginAction(values.email, values.password, setStatus, setSubmitting));
        }
    });

    return (
        <form className="form || login-form" onSubmit={formik.handleSubmit}>
            <div className="section">
                <Input
                    label="Email address"
                    type="email"
                    className="input"
                    name="email"
                    id="email"
                    placeholder=""
                    value={formik.values.email}
                    onChangeHandler={formik.handleChange}
                    onBlurHandler={formik.handleBlur}
                    isRequired
                    error={formik.touched.email && formik.errors.email}
                />
                <div>
                    <Link
                        className="link || forgot-password-link || float-right"
                        to={FRONTEND_ROUTES.PORTAL_FORGOT_PASSWORD}
                    >
                            Forgot password?
                    </Link>
                    <Input
                        label="Password"
                        type="password"
                        className="input"
                        name="password"
                        id="password"
                        placeholder=""
                        value={formik.values.password}
                        onChangeHandler={formik.handleChange}
                        onBlurHandler={formik.handleBlur}
                        isRequired
                        error={formik.touched.password && formik.errors.password}
                    />
                </div>
                <RecaptchaPolicy />
            </div>
            {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}
            {/* TODO: remember me */}
            <div className="submit">
                <span className="remeber-me-checkbox">
                    <label className="checkbox-wrapper || label">
                        <input type="checkbox" />
                        <span className="checkbox-button">
                            <Icon className="icon" name="check" />
                        </span>
                        Remember me
                    </label>
                </span>
                <button className="button primary || submit-btn" type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Loader type="small" /> : 'Login'}
                </button>
            </div>
        </form>
    );
};

export default LoginForm;
