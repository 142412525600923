import { getYtVideoId } from 'app/utilities/yt';
import PropTypes from 'prop-types';
import React from 'react';

class Video extends React.Component {
    get credit() {
        if (this.props.data.credit) return (<h6 className="credit">{this.props.data.credit}</h6>);
    }

    get title() {
        if (this.props.data.title) return (<h5 className="title">{this.props.data.title}</h5>);
    }

    render() {
        const { credit, title, video_url: videoUrl } = this.props.data;

        return (
            <section className="content-media-block">
                <div className="constrain-width large">
                    <div className="inner">
                        <div className="media">
                            <div className="media-inner || preserve-image-ratio">
                                <iframe
                                    title={`YouTube Video: ${title}`}
                                    className="video"
                                    src={`https://www.youtube.com/embed/${getYtVideoId(videoUrl)}?rel=0&controls=1&showinfo=0`}
                                    allowFullScreen={true}
                                ></iframe>
                            </div>
                        </div>
                        <div className="content">
                            <header className="title-group">
                                <h5>Video</h5>
                                {title && <h2>{title}</h2>}
                                {credit && <p>{credit}</p>}
                            </header>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Video.propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};

export default Video;
