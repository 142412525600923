import Banner from 'app/components/partials/banner';
import Helmet from 'react-helmet';
import image500 from 'static/images/500.png';
import React, { Component } from 'react';

class ErrorPage extends Component {
    render() {
        return (
            <main className="page" role="main">
                <Helmet>
                    <title>Error 500</title>
                </Helmet>
                <Banner
                    title="Oh noes!"
                    introduction="Sorry, we have recently re-organised the site and the page you were looking for appears to have been moved, deleted or does not exist."
                    images={[image500]}
                    contain={true}
                />
            </main>
        );
    }
}

export default ErrorPage;
