import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import NewsByCategory from 'app/components/partials/news-by-category.container';
import NewsList from 'app/components/partials/news-list.container';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class News extends Component {
    render() {
        const {
            blocks,
            name: title,
            highlighted_information: highlight,
            main_images: images,
            latin_name: latinName,
            short_description: description,
        } = this.props.data;

        return (
            <div className="page || news-view">
                <Banner
                    title={title}
                    subtitle={latinName}
                    introduction={description}
                    highlight={highlight}
                    images={images} />
                <NewsList />
                <ContentBlocks
                    data={blocks}
                    renderBefore={<NewsByCategory />}
                />
            </div>
        );
    }
}

News.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ]),
    data: PropTypes.object.isRequired
};

export default News;
