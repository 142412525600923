import { Map } from 'immutable';

export const INITIAL_STATE = new Map({
    isRouting: false,
    internalError: undefined // Handle global server error
});

// Actions
export const STARTED_ROUTING = 'rfa-zoo-website/status/STARTED_ROUTING';
export const STOPPED_ROUTING = 'rfa-zoo-website/status/STOPPED_ROUTING';
export const SET_INTERNAL_ERROR = 'rfa-zoo-website/status/SET_INTERNAL_ERROR';

// Action Creators
export const startedRoutingAction = () => ({ type: STARTED_ROUTING });
export const stoppedRoutingAction = () => ({ type: STOPPED_ROUTING });
export const setInternalErrorAction = (error) => ({ type: SET_INTERNAL_ERROR, payload: error });

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STARTED_ROUTING:
            return state.set('isRouting', true);
        case STOPPED_ROUTING:
            return state.set('isRouting', false);
        case SET_INTERNAL_ERROR:
            return state.set('internalError', action.payload);
        default:
            return state;
    }
};
