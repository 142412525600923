import { EntityLink } from 'rfa-react-core';
import PropTypes from 'prop-types';
import React from 'react';

class Encounter extends React.Component {
    render() {
        const { image, linkedItem, time, title, upNext } = this.props;

        return (
            <article className={`encounter${upNext ? ' up-next' : ''}`}>
                <EntityLink
                    item={linkedItem}
                    promoterBuName="Auckland Zoo"
                    className="encounter-inner"
                >
                    <div className="image || preserve-image-ratio standard" style={{ backgroundImage: `url(${image})` }}>
                        <img className="fixed-image" src={image} title={`Image of a ${title}`} alt={title} />
                    </div>
                    <div className="tile-description">
                        <h4 className="tile-description-title">{title}</h4>
                        <p className="tile-description-time">
                            <time dateTime={time}>{time}</time>
                        </p>
                    </div>
                    {upNext && (
                        <p className="heading-5 || tag">Up next</p>
                    )}
                </EntityLink>
            </article>
        );
    }
}

Encounter.propTypes = {
    image: PropTypes.string,
    linkedItem: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    time: PropTypes.string,
    upNext: PropTypes.bool.isRequired
};

export default Encounter;
