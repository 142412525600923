import AnnualPassOption from 'app/components/partials/portal/annual-pass-option';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import PropTypes from 'prop-types';
import React from 'react';

class TicketCombine extends React.Component {
    render() {
        const { items } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <section className="tile-block">
                <div className="constrain-width large">
                    <div className="ticket-combo">
                        {items.map((item, index) => {
                            return (
                                <AnnualPassOption
                                    key={index}
                                    from="ticket-combo"
                                    title={item.title}
                                    description={item.description}
                                    image={item.image}
                                    price={item.price}
                                    link={`${FRONTEND_ROUTES.BUY_MEMBERSHIP}${item.query}`}
                                />
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

TicketCombine.propTypes = {
    items: PropTypes.array.isRequired,
};

export default TicketCombine;
