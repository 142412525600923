/* eslint-disable camelcase */
import { clientEnv } from 'config/env';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ENDPOINTS, ERROR_MESSAGE, RESPONSE } from 'config/api';
import fetch, { clientFetch } from 'app/utilities/fetch';

export const INITIAL_STATE = {
    isAuth: false,
    user: undefined,
    isLoading: true
};


// Actions
export const USER_LOGIN = 'rfa-zoo-website/portal/LOGIN';
export const USER_LOGIN_SUCCESS = 'rfa-zoo-website/portal/LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'rfa-zoo-website/portal/LOGIN_FAIL';
export const USER_LOGOUT_SUCCESS = 'rfa-zoo-website/portal/LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'rfa-zoo-website/portal/LOGOUT_FAIL';
export const RESET_FORM_ERROR = 'rfa-zoo-website/portal/RESET_FORM_ERROR';
export const INITIAL_USER_VERIFY = 'rfa-zoo-website/portal/INITIAL_USER_VERIFY';
export const SET_USER = 'rfa-zoo-website/portal/SET_USER';
export const SET_USER_ANNUAL_PASSES = 'rfa-zoo-website/portal/SET_USER_ANNUAL_PASSES';
export const USER_LOGOUT = 'rfa-zoo-website/portal/LOGOUT';
export const USER_REGISTER = 'rfa-zoo-website/portal/REGISTER';

// Action Creators
export const userLoginAction = (email, password, setStatus, setSubmitting) => ({
    type: USER_LOGIN,
    payload: {
        email,
        password,
        setStatus,
        setSubmitting
    }
});

export const userLoginSuccessAction = (user) => {
    return {
        type: USER_LOGIN_SUCCESS,
        payload: {
            isAuth: true,
            isLoading: false,
            user
        }
    };
};

export const userLogoutSuccessAction = () => {
    return {
        type: USER_LOGOUT_SUCCESS
    };
};

export const userLoginFailAction = () => ({
    type: USER_LOGIN_FAIL
});

export const initialUserVerifyAction = (setSubmitting = null) => ({
    type: INITIAL_USER_VERIFY,
    payload: {
        setSubmitting
    }
});

export const userLogoutAction = () => ({
    type: USER_LOGOUT
});

export const setUserAction = (updateUserInfo) => ({
    type: SET_USER,
    payload: updateUserInfo
});
export const setUserAnnualPassesAction = (annualPasses) => ({
    type: SET_USER_ANNUAL_PASSES,
    payload: annualPasses
});

export const userRegisterAction = (user, setIsSubmitting, setStatus) => ({
    type: USER_REGISTER,
    payload: {
        user,
        setIsSubmitting,
        setStatus
    }
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    const { payload, type } = action;

    switch (type) {
        case USER_LOGIN_SUCCESS:
            return { ...state, ...payload };
        case USER_LOGIN_FAIL:
        case USER_LOGOUT_SUCCESS:
            return { ...state, isAuth: false, user: null, isLoading: false };
        case SET_USER:
            return { ...state, user: { ...state.user, ...payload } };
        case SET_USER_ANNUAL_PASSES:
            return { ...state, user: { ...state.user, annual_passes: payload } };
        default:
            return state;
    }
};

// Action/Action sequence creators
const userLoginFailActionSequence = ({ status, error }, setStatus) => {
    if (setStatus && error && error.message) {
        const errorMessage =
            status === RESPONSE.BAD_REQUEST &&
            error &&
            error.message ||
            ERROR_MESSAGE.DEFAULT;

        setStatus({ error:  errorMessage });
    }

    return userLoginFailAction();
};

const userRegisterSuccessActionSequence = (setStatus) => {
    setStatus({ submitSuccess: true });

    return initialUserVerifyAction();
};

const userRegisterFailActionSequence = ({ status, error }, setStatus) => {
    if (setStatus && error && error.message) {
        const errorMessage =
            status === RESPONSE.BAD_REQUEST &&
            error &&
            error.message ||
            ERROR_MESSAGE.DEFAULT;

        setStatus({ error: { message:  errorMessage, code: error.code } });
    }

    return userLoginFailAction();
};

// Epic creator
/**
 * News Epic
 * @param  {func} endpoint               - User login endpoint
 * @param  {func} userLoginFailActionSequence        - Fail action creator
 * @return {func}
 */
export const createUserLoginEpic = (endpoint, userLoginFailActionSequence) => {
    return (action$) => action$.pipe(
        ofType(USER_LOGIN),
        mergeMap(async({ payload: { email, password, setStatus, setSubmitting } }) => {
            // eslint-disable-next-line no-undef
            const captchaToken = await grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'login' });

            return (
                clientFetch(endpoint, {
                    method: 'POST',
                    body: JSON.stringify({
                        email,
                        password,
                        captchaToken
                    })
                })
                    .then(() => [initialUserVerifyAction(setSubmitting)])
                    .catch((err) => {
                        setSubmitting(false);

                        return userLoginFailActionSequence(err.error, setStatus);
                    })
            );
        })
    );
};

/**
 * News Epic
 * @param  {func} endpoint               - User verify endpoint
 * @param  {func} userLoginSuccessActionSequence     - Success action creator
 * @param  {func} userLoginFailActionSequence        - Fail action creator
 * @return {func}
 */
export const createUserLogoutEpic = (endpoint) => {
    return (action$) => action$.pipe(
        ofType(USER_LOGOUT),
        mergeMap(() => {
            return (
                fetch(endpoint, null, {
                    method: 'POST',
                    credentials: 'same-origin'
                })
                    .then(() => userLogoutSuccessAction())
                    // TODO: proper error handler for logout fail
                    .catch(() => userLogoutSuccessAction())
            );
        })
    );
};

/**
 * News Epic
 * @param  {func} endpoint               - User verify endpoint
 * @param  {func} userLoginSuccessActionSequence     - Success action creator
 * @param  {func} userLoginFailActionSequence        - Fail action creator
 * @return {func}
 */
export const createInitialUserVerifyEpic = (endpoint) => {
    return (action$) => action$.pipe(
        ofType(INITIAL_USER_VERIFY),
        mergeMap(({ payload: { setSubmitting } }) => {
            return (
                clientFetch(endpoint, { method: 'GET' }, true)
                    .then((response) => userLoginSuccessAction(response))
                    .catch(() => userLoginFailAction())
                    .finally(() => {
                        if (setSubmitting) setSubmitting(null);
                    })
            );
        })
    );
};

/**
 * News Epic
 * @param  {func} endpoint               - User register endpoint
 * @param  {func} userRegisterSuccessActionSequence     - Success action creator
 * @param  {func} userRegisterFailActionSequence        - Fail action creator
 * @return {func}
 */
export const createUserRegisterEpic = (endpoint, userRegisterSuccessActionSequence, userRegisterFailActionSequence) => {
    return (action$) => action$.pipe(
        ofType(USER_REGISTER),
        mergeMap(({ payload: { user, setIsSubmitting, setStatus } }) => {
            return (
                clientFetch(endpoint, {
                    method: 'POST',
                    body: JSON.stringify(user)
                })
                    .then(() => userRegisterSuccessActionSequence(setStatus))
                    .catch((err) => userRegisterFailActionSequence(err.error, setStatus))
                    .finally(() => setIsSubmitting(false))
            );
        })
    );
};


// Epics
const userLoginEpic = createUserLoginEpic(
    ENDPOINTS.PORTAL_LOGIN,
    userLoginFailActionSequence
);

const userRegisterEpic = createUserRegisterEpic(
    ENDPOINTS.PORTAL_USER_REGISTER,
    userRegisterSuccessActionSequence,
    userRegisterFailActionSequence
);

const userLogoutEpic = createUserLogoutEpic(ENDPOINTS.PORTAL_LOGOUT);

const initialUserVerifyEpic = createInitialUserVerifyEpic(ENDPOINTS.PORTAL_USER);

export const epics = combineEpics(userLoginEpic, userLogoutEpic, initialUserVerifyEpic, userRegisterEpic);
