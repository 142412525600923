import PropTypes from 'prop-types';
import React from 'react';

class Loader extends React.Component {
    render() {
        return (
            <div className={`loader ${this.props.type ? this.props.type : ''}`}>
                <span className="loader-spinner || animate-spin"></span>
                <p className="loader-text">Loading..</p>
            </div>
        );
    }
}

Loader.propTypes = {
    type: PropTypes.string
};

export default Loader;
