/* eslint-disable camelcase, quotes */
export default {
    CONTENT_SET_ITEMS: [
        {
            data: {
                author: `Author's Name`,
                name: 'Ut wisi enim ad minim veniam quis nostrud exerci',
                parent: null,
                publish_at: '2017-10-01T00:00:00+13:00',
                short_description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. ',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/401/?random'
            },
            type: 'news'
        },
        {
            data: {
                author: `Author's Name`,
                name: 'Test Article Two',
                parent: null,
                publish_at: '2017-10-02T00:00:00+13:00',
                short_description: 'Short description',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/402/?random'
            },
            type: 'news'
        },
        {
            data: {
                author: null,
                name: 'Test Article Three',
                parent: null,
                publish_at: '2017-10-03T00:00:00+13:00',
                short_description: 'Short description',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/403/?random'
            },
            type: 'news'
        },
        // Pages
        {
            data: {
                name: 'Test Page One',
                parent: null,
                short_description: 'Short description',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/301/?random'
            },
            type: 'page'
        },
        {
            data: {
                name: 'Test Page Two',
                parent: null,
                short_description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/302/?random'
            },
            type: 'page'
        },
        {
            data: {
                name: 'Test Page Three',
                parent: null,
                short_description: null,
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/303/?random'
            },
            type: 'page'
        }
    ],
    NEWS_ITEMS: [
        // Featured
        {
            data: {
                author: `Author's Name`,
                name: 'Featured Article Title',
                parent: null,
                publish_at: '2017-10-01T00:00:00+13:00',
                short_description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/1200/601/?random'
            },
            displayType: 'featured-article',
            type: 'news'
        },
        // Articles
        {
            data: {
                author: `Author's Name`,
                name: 'Ut wisi enim ad minim veniam quis nostrud exerci',
                parent: null,
                publish_at: '2017-10-01T00:00:00+13:00',
                short_description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. ',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/401/?random'
            },
            displayType: 'extended-article',
            type: 'news'
        },
        {
            data: {
                author: `Author's Name`,
                name: 'Test Article Two',
                parent: null,
                publish_at: '2017-10-02T00:00:00+13:00',
                short_description: 'Short description',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/402/?random'
            },
            displayType: 'extended-article',
            type: 'news'
        },
        {
            data: {
                author: null,
                name: 'Test Article Three',
                parent: null,
                publish_at: '2017-10-03T00:00:00+13:00',
                short_description: 'Short description',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/403/?random'
            },
            displayType: 'extended-article',
            type: 'news'
        },
        {
            data: {
                author: `Author's Name`,
                name: 'Test Article Four',
                parent: null,
                publish_at: '2017-10-02T00:00:00+13:00',
                short_description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. ',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/402/?random'
            },
            displayType: 'extended-article',
            type: 'news'
        },
        {
            data: {
                author: null,
                name: 'Test Article Five',
                parent: null,
                publish_at: '2017-10-03T00:00:00+13:00',
                short_description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. ',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/403/?random'
            },
            displayType: 'extended-article',
            type: 'news'
        },
        // No Author
        {
            data: {
                name: 'Test Article Six',
                parent: null,
                publish_at: '2017-10-02T00:00:00+13:00',
                short_description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. ',
                slug: '#example-slug',
                thumbnail: 'https://picsum.photos/600/402/?random'
            },
            displayType: 'extended-article',
            type: 'news'
        }
    ],
    CONTENT_SET_PROPS: {
        items: [
            {
                id: 'animal-18',
                name: 'Capybara',
                type: 'animal',
                data: {
                    id: 18,
                    name: 'Capybara',
                    slug: 'capybara',
                    disabled: false,
                    latin_name: 'Hydrochoerus hydrochaeris',
                    classification: 'mammals',
                    short_description: '',
                    highlighted_information: '',
                    seo_title: '',
                    seo_keywords: 'Capybara',
                    seo_description: 'Capybara',
                    thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/capybara-closeup-rectangle.jpg',
                    main_images: [
                        'https://cdn.aucklandunlimited.com/zoo/assets/media/capybara-closeup-hero.jpg'
                    ],
                    is_published: true
                }
            },
            {
                id: 'page-19',
                name: 'Get in touch',
                type: 'page',
                data: {
                    id: 19,
                    name: 'Get in touch',
                    slug: 'get-in-touch',
                    parent: '',
                    disabled: false,
                    highlighted_information: 'Contact us',
                    short_description: '',
                    is_published: true,
                    seo_title: '',
                    seo_keywords: 'Get in touch, contact us',
                    seo_description: 'Get in touch',
                    thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/nz-dotterel-1-rectangle.jpg',
                    main_images: [
                        'https://cdn.aucklandunlimited.com/zoo/assets/media/nz-dotterel-1-hero.jpg'
                    ],
                    button_label: '',
                    button_link: ''
                }
            },
            {
                id: 'animal-18',
                name: 'Capybara',
                type: 'animal',
                data: {
                    id: 18,
                    name: 'Capybara',
                    slug: 'capybara',
                    disabled: false,
                    latin_name: 'Hydrochoerus hydrochaeris',
                    classification: 'mammals',
                    short_description: '',
                    highlighted_information: '',
                    seo_title: '',
                    seo_keywords: 'Capybara',
                    seo_description: 'Capybara',
                    thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/capybara-closeup-rectangle.jpg',
                    main_images: [
                        'https://cdn.aucklandunlimited.com/zoo/assets/media/capybara-closeup-hero.jpg'
                    ],
                    is_published: true
                }
            }
        ],
        title: 'Content Set title'
    },
    CAROUSEL_ITEMS: [
        {
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/zoofari-cheque-hero.jpg',
            title: 'Image Carousel Slide 1 Title',
            subtitle: 'Image Carousel Slide 1 Subtitle',
            link: '',
            'text-color': 'black',
            'text-bg': 'white'
        },
        {
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/zoofari-kids-learning-rectangle.jpg',
            title: 'Image Carousel Slide 2 Title',
            subtitle: 'Image Carousel Slide 2 Subtitle',
            link: '',
            'text-color': 'black',
            'text-bg': 'white'
        }
    ],
    GALLERY_ITEMS: [
        {
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/zoofari-kids-rectangle.jpg',
            creditline: '<p>Image Gallery Slide 1 Credit Line</p>\r\n'
        },
        {
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/otter-river-rectangle.jpg',
            creditline: '<p>Image Gallery Slide 2 Credit Line</p>\r\n'
        }
    ],
    PAGE_BREAK_QUOTE_PROPS: {
        file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/galap-touching-rectangle.jpg',
        theme: 'blue',
        quote: 'Quote text',
        cite: 'Cite text'
    },
    CALL_TO_ACTION_PROPS: {
        data: {
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/morepork-closeup-rectangle.jpg',
            content: '<h2>Call to action</h2><p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>\r\n'
        }
    },
    INTRODUCTION_PROPS: {
        data: {
            file: 'https://assets.uat.tauweb.co.nz/zoo/assets/media/elephant-ctabg-493x450.png',
            content: '<h1>A real-life <em>big foot.</em></h1>\r\n\r\n<p>Wallabies are macropods, meaning &lsquo;large foot.&rsquo; These large feet and huge hind limbs come in handy, enabling them to hop super-fast while expending little energy.</p>\r\n\r\n<p>Wallabies may seem elusive during the day, as they are crepuscular animals &ndash; animals that are most active during the morning and evening but rest during the day. In their natural habitat of Australia, it can get very hot in the middle of the day which is why wallabies will often be resting. If they get too hot, wallabies will lick their paws and forearms to cool off.</p>\r\n'
        }
    },
    ENCOUNTERS_INITIAL_DATA: [
        {
            id: 14,
            name: 'Asian elephant',
            week_day: 'monday',
            time: 1115,
            link: '{"id":"8","type":"animal","slug":"asian-elephant"}',
            disabled: false,
            published: true,
            highlighted: false,
            thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/elephant-rectangle.jpg',
            is_published: true
        },
        {
            id: 3,
            name: 'Galápagos tortoise',
            week_day: 'monday',
            time: 1145,
            link: '{"id":"42","type":"animal","slug":"galapagos-tortoise"}',
            disabled: false,
            published: true,
            highlighted: false,
            thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/galap-closeup-rectangle.jpg',
            is_published: true
        },
        {
            id: 4,
            name: 'Capybara and squirrel monkey',
            week_day: 'monday',
            time: 1200,
            link: '{"id":"18","type":"animal","slug":"capybara"}',
            disabled: false,
            published: true,
            highlighted: false,
            thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/capybara-1-rectangle.jpg',
            is_published: true
        },
        {
            id: 5,
            name: 'Sumatran tiger',
            week_day: 'monday',
            time: 1230,
            link: '{"id":"12","type":"animal","slug":"sumatran-tiger"}',
            disabled: false,
            published: true,
            highlighted: false,
            thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/tiger-side-profile-rectangle.jpg',
            is_published: true
        },
        {
            id: 6,
            name: 'Australian birds',
            week_day: 'monday',
            time: 1400,
            link: '{"id":"60","type":"animal","slug":"birds"}',
            disabled: false,
            published: true,
            highlighted: false,
            thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/editted-rtb-cockatoo-1-rectangle.jpg',
            is_published: true
        },
        {
            id: 7,
            name: 'Eel',
            week_day: 'monday',
            time: 1430,
            link: '{"id":"61","type":"animal","slug":"new-zealand-longfin-eel"}',
            disabled: false,
            published: true,
            highlighted: false,
            thumbnail: 'https://cdn.aucklandunlimited.com/zoo/assets/media/eel-closeup-rectangle.jpg',
            is_published: true
        }
    ],
    FLEXI_ITEMS: [
        {
            title: 'Bug Lab',
            content: '<p>We bugs are some of evolution’s greatest geniuses – the true leaders of this planet. Your most advanced science has been ours for millions of years.</p>\r\n',
            button: {
                label: 'Bug lab tickets',
                href: '//google.com'
            },
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/capybara-1-rectangle.jpg'
        },
        {
            title: 'Friends of the Zoo Annual Pass',
            content: '<p>Become a Friend of the Zoo today and enjoy unlimited daytime entry to Auckland Zoo, 364 days a year. The perfect option for everyone who loves wildlife and wild places.</p>\r\n',
            button: {
                label: 'Buy annual pass',
                href: '//google.com'
            },
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/eel-closeup-rectangle.jpg'
        },
        {
            title: 'Third item title',
            content: '<p>Third item content</p>\r\n',
            button: {
                label: 'Button',
                href: '//google.com'
            },
            file: 'https://cdn.aucklandunlimited.com/zoo/assets/media/capybara-1-rectangle.jpg'
        }
    ],
    STACKLA_WIDGET: {
        hash: '5a2f83db3bf62',
        id: '33150',
        subtitle: 'Tag your photos and posts with #BugLab to display your photos here.',
        title: '#BugLab',
        type: 'masonry'
    },
    STACKLA_TABS: {
        subtitle: 'Tag your photos and posts with #AucklandZoo to display your photos here.',
        tabs: [
            {
                label: 'All',
                stackla: {
                    hash: '5a2f83db3bf62',
                    id: '33150',
                    type: 'masonry'
                }
            },
            {
                label: 'Instagram',
                stackla: {
                    hash: '5a3319dedf082',
                    id: '33302',
                    type: 'masonry'
                }
            },
            {
                label: 'Twitter',
                stackla: {
                    hash: '5b4c07305d112',
                    id: '44195',
                    type: 'masonry'
                }
            },
            {
                label: 'YouTube',
                stackla: {
                    hash: '5a5e9bfef1852',
                    id: '34888',
                    type: 'masonry'
                }
            },
            {
                label: 'Facebook',
                stackla: {
                    hash: '5a8e2a41f39af',
                    id: '36972',
                    type: 'masonry'
                }
            }
        ],
        title: '#AucklandZoo'
    },
    COLUMN_CALL_TO_ACTION: {
        title: 'Column Call To Action',
        items: [
            {
                title: 'Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,',
                link: 'https://www.aucklandzoo.com',
                file: 'https://picsum.photos/601/403/?random',
                short_detail: '03.10.2019'
            },
            {
                title: 'Short Title',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,',
                link: 'https://www.aucklandzoo.com',
                file: 'https://picsum.photos/609/403/?random',
                short_detail: 'Some detail here'
            },
            {
                title: 'Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,',
                link: 'https://www.aucklandzoo.com',
                file: 'https://picsum.photos/601/403/?random',
                short_detail: '03.10.2019'
            },
        ]
    },
    DUAL_TAB_PROPS: {
        data: [
            {
                heading: 'At the zoo',
                content: '<h3>Meet the mob:</h3> <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium.</p> <h3>Pouch life:</h3> <p>Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.&nbsp;</p>',
                file: 'https://assets.uat.tauweb.co.nz/zoo/assets/media/test.png'
            },
            {
                heading: 'In the wild',
                content: '<h3>Conservation status:</h3> <p>Critically in danger</p> <h3>Origin:</h3> <p>South and east Austrilia and Tasmania</p> <h3>Habitat:</h3> <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit et magnis dis parturient montes, nascetur ridiculus mus</p>',
                file: 'https://assets.uat.tauweb.co.nz/zoo/assets/media/test1.png',
            }
        ]
    },
    ROLLING_TEXT_ITEMS: {
        rolling_group: [
            {
                text: '<h2>Lorem ipsum dolor sit amet, <em>consectetur adipisicing elit</em></h2>\r\n\r\n<ul><li>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li><li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li><li>Viverra diam pretium. Adipiscing ante senectus fringilla sociis litora penatibus natoque praesent maecenas etiam bibendum Ornare. Primis velit velit quisque, nam laoreet nascetur tincidunt lorem magna arcu penatibus, metus duis.</li></ul>'
            },
            {
                text: '<h2>Page 2 of <em>Rolling text</em></h2>\r\n\r\n<p>Morbi eros magna tempor placerat ornare. Aptent dolor Sodales.Molestie aliquam. Est. Metus habitasse. Porttitor montes sodales elit auctor placerat. Odio rutrum.</p><p>Potenti eleifend senectus lobortis augue per ipsum eget.</p><p>Morbi eros magna tempor placerat ornare. Aptent dolor Sodales.Molestie aliquam. Est. Metus habitasse.</p>\r\n'
            },
            {
                text: '<h2>Page 3 of <em>Rolling text</em></h2>\r\n\r\n<p>Pharetra lorem. Phasellus sed ligula enim neque Nisi etiam blandit, tempor ipsum non vulputate. Nec feugiat sollicitudin at mus curabitur morbi inceptos ut primis euismod dapibus ridiculus eleifend.</p><p>Ac leo per ut est Massa mus taciti. Dictumst at. Mus molestie ac bibendum risus nam, dictum suscipit morbi nulla libero, nisi arcu. Cum justo senectus nam eleifend diam nec vehicula nunc.</p>\r\n'
            }
        ]
    }
};
