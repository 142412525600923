import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const BREAKPOINTS = {
    TINY: 425
};

class Gallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            currentItem: 0,
            variableWidth: false
        };
    }


    // Lifecycle
    componentDidMount() {
        // Binds the ondeviceorientation event listener
        window.addEventListener('deviceorientation', this.updateOrientation.bind(this));
        this.updateOrientation();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.imageCarousel && this.captionCarousel && nextProps.items !== this.props.items) {
            this.imageCarousel.slickGoTo(0);
            this.captionCarousel.slickGoTo(0);
            this.setState(this.getInitialState);
        }
    }


    // Helper
    updateOrientation() {
        this.setState({
            variableWidth: window.innerWidth > BREAKPOINTS.TINY
        });
    }

    isNextDisabled() {
        if (this.state.currentItem >= this.props.items.length - 1) {
            return true;
        }

        return false;
    }

    isPreviousDisabled() {
        if (this.state.currentItem <= 0) {
            return true;
        }

        return false;
    }


    // Handler
    handleNext() {
        if (this.isNextDisabled()) {
            return false;
        }

        this.captionCarousel.slickNext();
        this.imageCarousel.slickNext();
    }

    handlePrevious() {
        if (this.isPreviousDisabled()) {
            return false;
        }

        this.captionCarousel.slickPrev();
        this.imageCarousel.slickPrev();
    }


    // Render
    render() {
        const { items } = this.props;
        const { variableWidth } = this.state;

        return (
            <section className="carousel" ref={(carousel) => { this.carousel = carousel; }}>
                <div className="constrain-width large">
                    <Slider
                        className={`image-carousel || ${variableWidth ? 'variable-width' : 'fixed-width'}`}
                        ref={(imageCarousel) => { this.imageCarousel = imageCarousel; }}
                        variableWidth={this.state.variableWidth}
                        {...IMAGE_CAROUSEL_CONFIG}
                        beforeChange={(...args) => this.setState({ currentItem: args[1] })}
                    >
                        {items.map(({ file }, index) => {
                            return (
                                <div key={index}>
                                    <div className="carousel-item">
                                        {/* TODO: We need to return image attrs for SEO */}
                                        <img className="image" src={file} alt={file} />
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                    <div className="caption-carousel">
                        <div className="constrain-width">
                            <div className="inner">
                                {items.length > 1 &&
                                    <button className={`change-slide previous ${this.isPreviousDisabled() ? 'is-disabled' : ''}`} onClick={this.handlePrevious.bind(this)}>
                                        <Icon name="chevron" title="Previous" />
                                    </button>
                                }
                                {items.length > 1 &&
                                    <button className={`change-slide next ${this.isNextDisabled() ? 'is-disabled' : ''}`} onClick={this.handleNext.bind(this)}>
                                        <Icon name="chevron" title="Next" />
                                    </button>
                                }
                                <div className="wrapper">
                                    <Slider
                                        ref={(captionCarousel) => { this.captionCarousel = captionCarousel; }}
                                        {...CAPTION_CAROUSEL_CONFIG}
                                        beforeChange={(...args) => this.setState({ currentItem: args[1] })}
                                    >
                                        {items.map(({ creditline }, index) => {
                                            return (
                                                <div key={index} className="carousel-item || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(creditline) }}></div>
                                            );
                                        })}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Gallery.propTypes = {
    items: PropTypes.array.isRequired
};

const DEFAULT_CAROUSEL_CONFIG = {
    accessibility: false, // We are using native keyboard navigation instead of arrows
    arrows: false,
    dots: false,
    infinite: true,
    pauseOnHover: true,
    speed: 600,
    swipe: false // TODO: Add swipe and sync sliders
};

const IMAGE_CAROUSEL_CONFIG = {
    ...DEFAULT_CAROUSEL_CONFIG,
    adaptiveHeight: true,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1
};

const CAPTION_CAROUSEL_CONFIG = {
    ...DEFAULT_CAROUSEL_CONFIG
};

export default Gallery;
