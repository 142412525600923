/* eslint-disable require-jsdoc */

export function selectFieldTouched(state, fieldName) {
    return state.newsletter.fields[fieldName].touched;
}

export function selectFieldValue(state, fieldName) {
    return state.newsletter.fields[fieldName].value;
}

export function selectIsBusy(state) {
    const { newsletter } = state;

    return newsletter.submitted && newsletter.submittedSuccessfully === undefined;
}

export function selectSubmittedSuccessfully(state) {
    return state.newsletter.submittedSuccessfully;
}

export function selectError(state) {
    return state.newsletter.error;
}
