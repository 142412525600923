import { connect } from 'react-redux';
import { fetchNewsAction } from 'app/ducks/news';
import NewsList from 'app/components/partials/news-list';
import {
    selectNews,
    selectNewsFeaturedItem,
    selectNewsIsBusy,
    selectNewsResultCount
} from 'app/selectors/news';

const mapStateToProps = (state) => ({
    items: selectNews(state),
    featuredItem: selectNewsFeaturedItem(state),
    isBusy: selectNewsIsBusy(state),
    resultCount: selectNewsResultCount(state)
});

const mapDispatchToProps = (dispatch) => ({
    loadData: (page) => dispatch(fetchNewsAction(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsList);
