import classnames from 'classnames';
import defaultBannerImage from 'static/images/default-page-banner.jpg';
import PropTypes from 'prop-types';
import React, { Component, forwardRef } from 'react';

const SLIDE_ANIMATION_DURATION = 600;
const SLIDE_ROTATION_DURATION = 5000;

class HeroCarousel extends Component {
    constructor() {
        super();

        this.state = HeroCarousel.createInitialState();

        this.autoRotate = null;
    }

    static createInitialState() {
        return {
            activeSlide: 0,
            autoRotate: true,
            nextReady: true
        };
    }

    // Lifecycle
    componentDidMount() {
        if (this.props.images.length > 1) {
            this.startAutoRotate();
        }
    }

    componentWillUnmount() {
        this.stopAutoRotate();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.images === nextProps.images) {
            this.stopAutoRotate();
            this.setState(HeroCarousel.createInitialState());
        }
    }


    // Helper
    isNext(index) {
        const { activeSlide, nextReady } = this.state;

        // If last image, first one becomes 'next' as well
        if (nextReady && index === 0 && activeSlide !== index) {
            return true;
        }

        return nextReady && ((activeSlide + 1 === index) || (activeSlide - 1 === index));
    }

    nextReadyAfterTime() {
        setTimeout(() => {
            this.setState({
                nextReady: true
            });
        }, SLIDE_ANIMATION_DURATION);
    }

    startAutoRotate() {
        this.autoRotate = setInterval(() => {
            const shouldRotate = this.state.autoRotate;
            const isLastSlide = (this.state.activeSlide + 1 === this.props.images.length);

            if (shouldRotate && isLastSlide) {
                // Restart carousel
                this.updateSlide(0);
            } else if (shouldRotate) {
                this.nextSlide();
            } else {
                // Stop the rotation
                this.stopAutoRotate();
            }
        }, SLIDE_ROTATION_DURATION);
    }

    stopAutoRotate() {
        clearInterval(this.autoRotate);
    }

    previousSlide() {
        const nextActiveSlide = Math.max(this.state.activeSlide - 1, 0);

        this.updateSlide(nextActiveSlide);
    }

    nextSlide() {
        const nextActiveSlide = Math.min(this.state.activeSlide + 1, this.props.images.length - 1);

        this.updateSlide(nextActiveSlide);
    }

    updateSlide(slide = 0) {
        this.setState({
            activeSlide: slide,
            nextReady: false
        }, this.nextReadyAfterTime);
    }


    // Handler
    handlePrevious() {
        this.setState({
            autoRotate: false
        }, this.previousSlide());
    }

    handleNext() {
        this.setState({
            autoRotate: false
        }, this.nextSlide());
    }

    render() {
        const { buttonLabel, buttonLink, title, images, styles = '', children, type = '' } = this.props;
        const { activeSlide } = this.state;

        if (images.length === 0) images.push(defaultBannerImage);

        return (
            <header ref={this.props.innerRef}>
                <div className={`hero-carousel ${type} ${styles}`}>
                    {title &&
                        <div className="hero-carousel-intro" role="group">
                            <h1 className="large">{title}</h1>
                            {buttonLabel && <a className="button large outline" href={buttonLink}>{buttonLabel}</a>}
                        </div>
                    }

                    <div className="carousel">
                        <div className="carousel-inner || preserve-aspect-ratio">
                            {images.map((image, index) => {
                                const itemClass = classnames('carousel-media', {
                                    'is-active': (activeSlide === index),
                                    'is-next': this.isNext(index)
                                });

                                return (
                                    <div className={itemClass} key={`carousel-media-${index}`}>
                                        <div className={`carousel-image ${type} || preserve-image-ratio`} style={{ backgroundImage: `url(${image})` }}>
                                            <img className="fixed-image" src={image} alt={title} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {children}
                </div>
            </header>
        );
    }
}

HeroCarousel.propTypes = {
    buttonLabel: PropTypes.string,
    buttonLink: PropTypes.string,
    children: PropTypes.node,
    images: PropTypes.array.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    styles: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ])
};

const HeroCarouselForwardingRef = forwardRef((props, ref) => <HeroCarousel innerRef={ref} {...props} />);

HeroCarouselForwardingRef.displayName = 'HeroCarousel';

export default HeroCarouselForwardingRef;
