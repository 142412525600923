import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { truncateOnWordBoundary } from 'app/utilities/truncate';

const CHARACTER_LIMIT = {
    DESCRIPTION: 135,
    TITLE: 90,
    DETAIL: 40
};

class ColumnCallToAction extends React.Component {
    renderTiles(item, index) {
        let style = {};

        const {
            title,
            description,
            file,
            short_detail: shortDetail,
            link
        } = item;

        if (file) {
            style = { backgroundImage: `url(${file})` };
        }

        return (
            <article className="extended-article-tile" key={index}>
                <div className="extended-article-tile-inner">
                    <a
                        href={link}
                        className="tile-image-header || image-overlay"
                        style={style}
                    >
                        <div className="heading-group">
                            <span className="flag"><Icon name="chevron" /></span>
                            <h3 className="title">{truncateOnWordBoundary(title, CHARACTER_LIMIT.TITLE)}</h3>
                        </div>
                    </a>
                    <div className="tile-content">
                        {description && <p className="subtitle">{truncateOnWordBoundary(description, CHARACTER_LIMIT.DESCRIPTION)}</p>}
                        <p className="meta">
                            {shortDetail && <span className="detail">{truncateOnWordBoundary(shortDetail, CHARACTER_LIMIT.DETAIL)}</span>}
                        </p>
                    </div>
                </div>
            </article>
        );
    }

    render() {
        const { items, title } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <section className="tile-block">
                {title &&
                    <header className="section-header">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="title-wrapper">
                                    <h2 className="title || heading-1">{title}</h2>
                                </div>
                            </div>
                        </div>
                    </header>
                }
                <div className="constrain-width large">
                    <div className="tile-grid">
                        {items.map(this.renderTiles)}
                    </div>
                </div>
            </section>
        );
    }
}

ColumnCallToAction.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string
};

export default ColumnCallToAction;
