/* eslint-disable dot-notation, no-multi-spaces */
import classnames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

class Notification extends React.Component {
    render() {
        const {
            description,
            handleToggle,
            id,
            isVisible,
            title,
            type,
            url,
            content
        } = this.props;

        const notificationClass = classnames(`notification ${type}`, {
            'is-visible': isVisible,
        });

        return (
            <div className={notificationClass}>
                <div className="notification-inner || constrain-width large">
                    {type !== 'wait-time' && (
                        <Icon
                            className="notification-icon"
                            name={type}
                        />
                    )}
                    {content ? (
                        <div className="notification-content || rich-editor-content" dangerouslySetInnerHTML={{ __html: content }} />
                    ) : (
                        <div className="notification-content">
                            <p>{title}</p>
                            {(url !== '' && description !== '') && <p><a href={url}>{description}</a></p> ||
                            (description !== '') && <p>{description}</p>}
                        </div>
                    )}
                    <button className="notification-toggle" onClick={() => handleToggle(id)}>
                        <Icon
                            className="notification-icon-close"
                            name="close"
                        />
                    </button>
                </div>
            </div>
        );
    }
}

Notification.propTypes = {
    description: PropTypes.string,
    handleToggle: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    title: PropTypes.string,
    type: PropTypes.string.isRequired, // one of NOTIFICATION_TYPES[x].className
    url: PropTypes.string,
    content: PropTypes.string
};

export default Notification;
