export const NEWS = {
    INTRO_LIMIT: 3, // Introduction of the news landing page
    BY_CATEGORY_LIMIT: 12, // The search by category section
    CATEGORY_ALL: {
        name: 'Auckland Zoo',
        id: 'all'
    },
    DISPLAY_TYPES: {
        FEATURED: 'featured-article',
        EXTENDED: 'extended-article'
    }
};
