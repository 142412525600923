import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import React from 'react';
import TicketsSection from 'app/components/partials/tickets-section';

const Tickets = (props) => {
    const {
        blocks,
        name: title,
        highlighted_information: highlight,
        main_images: images,
        latin_name: latinName,
        short_description: description,
        button_label: buttonLabel,
        button_link: buttonLink,
    } = props.data;

    return (
        <div className="page || page-view">
            <Banner
                buttonLabel={buttonLabel}
                buttonLink={buttonLink}
                title={title}
                subtitle={latinName}
                introduction={description}
                highlight={highlight}
                images={images} />
            {props.children}
            <TicketsSection />
            <ContentBlocks data={blocks} />
        </div>
    );
};

Tickets.propTypes = {
    data: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ])
};

export default Tickets;
