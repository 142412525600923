import AQUARIUM_ASSOCIATION_LOGO from 'static/images/logo-aquarium.svg';
import AUCKLAND_UNLIMITED_LOGO from 'static/images/auckland-unlimited-logo-black.svg';
import EAZA_LOGO from 'static/images/eaza.svg';
import FooterNavigation from 'app/components/partials/footer-navigation';
import Icon from 'app/components/partials/icon';
import IMAGE_FOOTER_HERO from 'static/images/footer-hero.jpg';
import Institutional from 'app/components/partials/institutional';
import { Link } from 'react-router-dom';
import NewsletterSignup from 'app/components/partials/newsletter-signup.container';
import PropTypes from 'prop-types';
import QUAL_MARK_LOGO from 'static/images/qual-mark.svg';
import React from 'react';
import socialLinks from 'config/social';
import Sponsors from 'app/components/partials/sponsors';
import WAZA_LOGO from 'static/images/waza.svg';
import WomenRefuge from './women-refuge';
import ZERO_CARBON_LOGO from 'static/images/carbonzero.png';

class Footer extends React.Component {
    render() {
        const { globalFields } = this.props;

        return (
            <footer className="footer">
                <section className="footer-information">
                    <div className="constrain-width">
                        <div className="inner">
                            <Institutional globalFields={globalFields}/>
                            <FooterNavigation />
                        </div>
                    </div>
                </section>
                <section
                    id="sign-up"
                    className="footer-hero"
                    style={{ backgroundImage: `url(${IMAGE_FOOTER_HERO})` }}
                >
                    <div className="constrain-width">
                        <div className="inner">
                            <div className="heading-group">
                                <p className="subtitle || heading-3">
                                    Sign up to our newsletter
                                </p>
                                <NewsletterSignup />
                            </div>
                            <div className="social-block">
                                <a className="link" href={socialLinks.facebook}>
                                    <Icon type="social" name="facebook" />
                                </a>
                                <a
                                    className="link"
                                    href={socialLinks.instagram}
                                >
                                    <Icon type="social" name="instagram" />
                                </a>
                                <a className="link" href={socialLinks.twitter}>
                                    <Icon type="social" name="twitter" />
                                </a>
                                <a className="link" href={socialLinks.youtube}>
                                    <Icon type="social" name="youtube" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                {/* The last bomb I've deployed. */}
                <section className="footer-associations">
                    <div className="constrain-width">
                        <div className="footer-associations-inner">
                            <div className="footer-associations-content">
                                <p>Auckland Zoo is part of a charitable trust and Tātaki Auckland Unlimited, a council-controlled organisation. We are a member of the Zoo and Aquarium Association Australasia (ZAA), the World Association of Zoos and Aquariums (WAZA) and an associate member of the European Association of Zoos and Aquaria (EAZA).</p>
                                <p>Auckland Zoo is certified net carbonzero and Qualmark accredited EnviroGold</p>
                            </div>
                            <div className="footer-associations-logos">
                                <div className="logo">
                                    <img
                                        width="130"
                                        height="50"
                                        src={AQUARIUM_ASSOCIATION_LOGO}
                                        alt="Zoo Aquarium Association"
                                    />
                                </div>
                                <div className="logo">
                                    <img
                                        width="66"
                                        height="60"
                                        src={EAZA_LOGO}
                                        alt="European Association of Zoos and Aquaria"
                                    />
                                </div>
                                <div className="logo">
                                    <img
                                        width="146"
                                        height="60"
                                        src={WAZA_LOGO}
                                        alt="World Association of Zoos and Aquariums"
                                    />
                                </div>
                                <div className="logo">
                                    <img
                                        width="60"
                                        src={ZERO_CARBON_LOGO}
                                        alt="Net Carbon Zero"
                                    />
                                </div>
                                <div className="logo">
                                    <img
                                        width="72"
                                        height="70"
                                        src={QUAL_MARK_LOGO}
                                        alt="Qual Mark Gold"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Sponsors />

                <section className="copyrights">
                    <div className="constrain-width">
                        <div className="inner">
                            <p>
                                <small className="fine-text">
                                    &copy;{new Date().getFullYear()} Auckland Zoo
                                </small>
                                <small className="fine-text">
                                    <Link className="link" to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </small>
                                <small className="fine-text">
                                    <Link className="link" to="/terms-and-conditions">
                                        Terms and Conditions
                                    </Link>
                                </small>
                            </p>
                            <a className="rfa-logo" href="https://www.aucklandunlimited.com/" title="View the Tātaki Auckland Unlimited website">
                                <img
                                    className="logo"
                                    width="180"
                                    src={AUCKLAND_UNLIMITED_LOGO}
                                    alt="Tātaki Auckland Unlimited"
                                />
                            </a>
                        </div>
                        <WomenRefuge />
                    </div>
                </section>
            </footer>
        );
    }
}

Footer.propTypes = {
    globalFields: PropTypes.object.isRequired
};

export default Footer;
