/* eslint-disable require-jsdoc */
import donationPaymentFormFields from 'config/donation-payment-form-fields';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import getStripToken from 'app/utilities/stripe-token';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    fields: donationPaymentFormFields.reduce((acc, { name }) => {
        acc[name] = {
            value: '',
            touched: false
        };

        return acc;
    }, {}),
    productsList: [],
    error: null,
    isBusy: false,
    submitted: false,
    submittedSuccessfully: false
};


// Actions
export const FETCH_PRODUCTS_LIST_FAIL = 'rfa-zoo-website/donation/FETCH_PRODUCTS_LIST_FAIL';
export const FETCH_PRODUCTS_LIST_SUCCESS = 'rfa-zoo-website/donation/FETCH_PRODUCTS_LIST_SUCCESS';
export const FETCH_PRODUCTS_LIST = 'rfa-zoo-website/donation/FETCH_PRODUCTS_LIST';
export const SET_FORM_FIELD_VALUE = 'rfa-zoo-website/donation/SET_FORM_FIELD_VALUE';
export const TOUCHED_FORM_FIELD = 'rfa-zoo-website/donation/TOUCHED_FORM_FIELD';
export const SUBMIT_FORM = 'rfa-zoo-website/donation/SUBMIT_FORM';
export const SUCCESSFULLY_SUBMITTED = 'rfa-zoo-website/donation/SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT = 'rfa-zoo-website/donation/FAILED_TO_SUBMIT';
export const GET_FORM_ERROR = 'rfa-zoo-website/donation/GET_FORM_ERROR';

// Action Creators
export const fetchProductsListAction = () => ({
    type: FETCH_PRODUCTS_LIST
});

export const fetchProductsListFailAction = () => ({
    type: FETCH_PRODUCTS_LIST_FAIL
});

export const fetchProductsListSuccessAction = (productsList) => ({
    type: FETCH_PRODUCTS_LIST_SUCCESS,
    productsList
});

export const setFormFieldValueAction = (fieldName, value) => ({
    type: SET_FORM_FIELD_VALUE,
    fieldName,
    value
});

export const touchedFormFieldAction = (fieldName) => ({
    type: TOUCHED_FORM_FIELD,
    fieldName
});

export const submitFormAction = (formData, navigate) => ({
    type: SUBMIT_FORM,
    formData,
    navigate
});

export const formSuccessfullySubmittedAction = () => ({
    type: SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitFormAction = (error) => ({
    type: FAILED_TO_SUBMIT,
    error
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS_LIST:
            return fetchProductsList(state);
        case FETCH_PRODUCTS_LIST_FAIL:
            return fetchProductsListFail(state);
        case FETCH_PRODUCTS_LIST_SUCCESS:
            return fetchProductsListSuccess(state, action.productsList);
        case SET_FORM_FIELD_VALUE:
            return setFormFieldValue(state, action.fieldName, action.value);
        case TOUCHED_FORM_FIELD:
            return touchedFormField(state, action.fieldName);
        case SUBMIT_FORM:
            return submitForm(state);
        case SUCCESSFULLY_SUBMITTED:
            return successfullySubmitted(state);
        case FAILED_TO_SUBMIT:
            return failedToSubmit(state, action.error);
        default:
            return state;
    }
};

// Reducer
function fetchProductsList(state) {
    return {
        ...state,
        isBusy: true
    };
}

function fetchProductsListFail(state) {
    return {
        ...state,
        isBusy: false
    };
}

function fetchProductsListSuccess(state, productsList) {
    return {
        ...state,
        isBusy: false,
        productsList
    };
}

function setFormFieldValue(state, fieldName, value) {
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                value
            }
        }
    };
}

function touchedFormField(state, fieldName) {
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                touched: true
            }
        }
    };
}

function submitForm(state) {
    return {
        ...state,
        error: null,
        isBusy: true,
        submitted: true,
        submittedSuccessfully: false
    };
}

function successfullySubmitted(state) {
    return {
        ...state,
        isBusy: false,
        submitted: true,
        submittedSuccessfully: true
    };
}

function failedToSubmit(state, error) {
    return {
        ...state,
        isBusy: false,
        submitted: true,
        submittedSuccessfully: false,
        error
    };
}

async function handleSubmit(endpoint, formData, navigate, successAction, failAction) {
    try {
        const token = await getStripToken({ ...formData.paymentData });

        await fetch(endpoint, null, {
            method: 'POST',
            body: JSON.stringify({
                name: formData.name,
                email: formData.email,
                'billing_address': formData.billing_address,
                'product_id': formData.product_id,
                amount: formData.amount,
                token
            })
        });

        navigate('/get-involved/donate/thank-you');

        return successAction();
    } catch (err) {
        if (err.type && err.type === 'card_error') {
            return failAction(err.message);
        }

        return failAction('Sorry, please try again later.');
    }
}

// Epic creator
/**
 * Donation Epic
 * @param  {func} endpoint               - Donation endpoint
 * @param  {func} fetchProductsListSuccessAction - Success action creator
 * @param  {func} fetchProductsListFailAction    - Fail action creator
 * @return {func}
 */
export const createProductListEpic = (endpoint, fetchProductsListSuccessAction, fetchProductsListFailAction) => {
    return (action$) => action$.pipe(
        ofType(FETCH_PRODUCTS_LIST),
        mergeMap(() => {
            return (
                fetch(endpoint)
                    .then((response) => {
                        const defaultProductId = new URLSearchParams(window.location.search).get('cause');
                        const firstProductId = response && response[0] && response[0].id || '';
                        let productId = firstProductId;

                        if (defaultProductId && response.find((item) => item.id === defaultProductId)) {
                            productId = defaultProductId;
                        }

                        return [fetchProductsListSuccessAction(response), setFormFieldValueAction('product_id', productId)];
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return fetchProductsListFailAction();
                    })
            );
        })
    );
};

/**
 * Form Submit Epic
 * @param  {func} endpoint               - Donation endpoint
 * @param  {func} successfullySubmittedAction - Success action creator
 * @param  {func} failedToSubmitAction    - Fail action creator
 * @return {func}
 */
export const createFormSubmissionEpic = (endpoint, successfullySubmittedAction, failedToSubmitAction) => {
    return (action$) => action$.pipe(
        ofType(SUBMIT_FORM),
        mergeMap(({ formData, navigate }) => {
            return handleSubmit(endpoint, formData, navigate, successfullySubmittedAction, failedToSubmitAction);
        })
    );
};

// Epics
const productListEpic = createProductListEpic(
    ENDPOINTS.DONATION,
    fetchProductsListSuccessAction,
    fetchProductsListFailAction
);

const formSubmissionEpic = createFormSubmissionEpic(
    ENDPOINTS.DONATION,
    formSuccessfullySubmittedAction,
    failedToSubmitFormAction
);

export const epics = combineEpics(productListEpic, formSubmissionEpic);
