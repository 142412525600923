/* eslint-disable camelcase */
import { generateOrderPricing } from 'app/utilities/buy-memberships';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import OrderPricing from 'app/components/partials/buy-memberships/order-pricing';
import { resetBuyMembershipsFlowAction } from 'app/ducks/buy-memberships';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Complete = () => {
    const { paymentStatus } = useSelector((state) => state.buyMemberships);
    const { receiptInfo } = paymentStatus || {};

    const dispatch = useDispatch();

    const renderSuccessMessage = () => (
        <>
            <Icon name="check" title="Success" />
            <h4 className="title center">Your order has been confirmed</h4>
            <div className="message-body">
            An email confirmation and receipt will be sent to you shortly and your gift recipient will receive an email with instructions to redeem their gifted membership.
            </div>
            <p>
                <strong>If you have any questions, please feel free to contact us at <a href="mailto:annual.passes@aucklandzoo.co.nz">annual.passes@aucklandzoo.co.nz</a></strong>
            </p>
        </>
    );

    const renderMessage = () => {
        return (
            <div className="message || block || section">
                <div className="constrain-width large no-pad">
                    <div className="inner">
                        <div className="constrain-width medium no-pad">
                            {renderSuccessMessage()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        // Clear buy memberships state when user complete one order
        return () => dispatch(resetBuyMembershipsFlowAction());
    }, []);

    return (
        <div className="thank-you">
            {renderMessage()}
            <div className="block || section">
                <div className="constrain-width large no-pad || review-order">
                    <div className="inner">
                        <div className="constrain-width medium no-pad">
                            <h4 className="title">Order details</h4>
                            <OrderPricing
                                {...generateOrderPricing(receiptInfo)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="to-homepage-wrapper">
                <Link className="button primary" to={MEMBERS_PORTAL_ROUTES.DASHBOARD}>
                    To Dashboard
                </Link>
            </div>
        </div>
    );
};

export default Complete;
