import Banner from 'app/components/partials/banner';
import { createValidationDataObject } from 'app/utilities/form-validation';
import Helmet from 'react-helmet';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import Select from 'app/components/partials/select';
import DONATION_PAYMENT_FORM_FIELDS, { getFieldData } from 'config/donation-payment-form-fields';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const DonationPaymentPage = (props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const formatFormData = () => {
        const { getFieldValue } = props;

        return {
            'product_id': getFieldValue('product_id'),
            amount: getFieldValue('amount'),
            name: getFieldValue('name'),
            email: getFieldValue('email'),
            paymentData: {
                number: getFieldValue('card_number'),
                'exp_month': getFieldValue('expire_month'),
                'exp_year': getFieldValue('expire_year'),
                cvc: getFieldValue('cvc')
            },
            'billing_address': {
                'line1': getFieldValue('line1'),
                'line2': getFieldValue('line2'),
                'city': getFieldValue('city'),
                'postal_code': getFieldValue('post_code'),
                'country': getFieldValue('country')
            }
        };
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const { submitForm } = props;

        submitForm(formatFormData(), navigate);
    }

    // Render
    const renderSubmitButton = () => {
        const invalid = DONATION_PAYMENT_FORM_FIELDS.some(({ name, validator }) => {
            return !validator(props.getFieldValue(name)).valid;
        });

        return (
            <button
                type="submit"
                className="button primary"
                disabled={invalid}
            >Submit</button>
        );
    };

    const renderSubmitSection = () => {
        const { formSubmitted, isBusy, submittedSuccessfully } = props;

        if (isBusy) {
            return (
                <button type="button" className="button primary">
                    <Loader type="small" />
                </button>
            );
        }

        if (formSubmitted && submittedSuccessfully) {
            return (
                <button type="submit" className="button primary">
                    Sent
                </button>
            );
        }

        return renderSubmitButton();
    };

    const renderField = ({ inputType, name, label, type, isRequired, placeholder, inputSize, validator, options }) => {
        const { getFieldTouched, getFieldValue, setFieldValue, touchedField } = props;

        const touched = getFieldTouched(name);
        const value = getFieldValue(name);
        const validationData = touched ? validator(value) : createValidationDataObject();

        const fieldData = {
            id: name,
            name,
            label,
            type,
            placeholder,
            value,
            onChangeHandler: (event) => {
                setFieldValue(name, event.target.value);
                touchedField(name);
            },
            onBlurHandler: () => touchedField(name),
            options,
            small: inputSize === 'small',
            medium: inputSize === 'medium',
            large: inputSize === 'large',
            isRequired,
            error: validationData.valid ? '' : validationData.message
        };

        if (inputType === 'select') {
            return (
                <Select {...fieldData} />
            );
        }

        return (
            <Input {...fieldData} />
        );
    };

    useEffect(async() => {
        await props.getProductsList();
        const defaultAmount = searchParams.get('amount');

        if (defaultAmount) {
            props.setFieldValue('amount', defaultAmount);
        }
    }, []);

    const { productsList, getFieldValue, formError } = props;

    const formattedProductsList = productsList.map(({ name, id }) => ({ label: name, value: id, name, id }));

    return (
        <main role="main" className="main">
            <Helmet>
                <title>Donation payment</title>
            </Helmet>
            <div className="page || page-view">
                <Banner
                    title="Donation"
                />
                <section className="constrain-width large || text-block">
                    <div className="inner">
                        <div className="content">
                            <h2>Payment</h2>
                            <hr />
                            <form className="donation-payment-form" onSubmit={handleSubmit}>
                                <div className="section">
                                    {renderField({ ...getFieldData('product_id'), options: formattedProductsList })}
                                    {renderField(getFieldData('amount'))}
                                </div>

                                <div className="section">
                                    <div className="form-group">
                                        {renderField(getFieldData('name'))}
                                        {renderField(getFieldData('email'))}
                                    </div>
                                </div>

                                <div className="section">
                                    <h3 className="title">Billing address</h3>
                                    {renderField(getFieldData('line1'))}
                                    {renderField(getFieldData('line2'))}
                                    <div className="form-group">
                                        {renderField(getFieldData('city'))}
                                        {renderField(getFieldData('post_code'))}
                                        {renderField(getFieldData('country'))}
                                    </div>
                                </div>

                                <div className="section">
                                    <h3 className="title">Payment information</h3>
                                    {renderField(getFieldData('card_number'))}
                                    <div className="form-group">
                                        {renderField(getFieldData('expire_month'))}
                                        {renderField(getFieldData('expire_year'))}
                                    </div>
                                    {renderField(getFieldData('cvc'))}
                                </div>

                                {getFieldValue('amount') && <h4 className="total-amount || heading-4">Total: <span>${getFieldValue('amount')}</span></h4>}

                                {formError && <div className="error-message">{formError}</div>}

                                {renderSubmitSection()}
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

DonationPaymentPage.propTypes = {
    productsList: PropTypes.array.isRequired,
    isBusy: PropTypes.bool.isRequired,
    getProductsList: PropTypes.func.isRequired,
    getFieldTouched: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    formError: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    touchedField: PropTypes.func.isRequired,
    formSubmitted: PropTypes.bool.isRequired,
    submittedSuccessfully: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired
};

export default DonationPaymentPage;
