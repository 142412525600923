import canUseDom from './dom';
import { ENDPOINTS } from 'config/api';
import { getJsonStr } from 'app/utilities/format';

import { url } from 'rfa-react-core';

export const getSlug = (reqUrl) => {
    return url.getSlug(reqUrl);
};

export const getParent = (reqUrl) => {
    return url.getParent(reqUrl);
};


/**
 * Returns page view type based off the requrest URL
 * @param  {string} requestURL
 * @return {string} requestType
 */
export const getEntityType = (requestUrl) => {
    const arr = requestUrl.split('/');
    const base = arr[1] || '';

    // For `/news`, we need `page` and `news - a list of articles`
    if (base === 'news' && arr.length === 2) {
        // HACK: This needs to be re-visited in the future
        return canUseDom() ? 'page' : 'news';
    }

    // For `/animals`, treat it as the page
    if (base === 'animals' && arr.length === 2) {
        return 'page';
    }

    // For `/animals/xxxx` and `/news/xxxx`
    if (arr.length > 2) {
        // Animals
        if (base === 'animals') {
            /**
             * `animals/xxxxx` category, not use right now, just markdown
             */
            // const category = arr[2];
            // const isAnimalCategory = category === 'ectotherms' || category === 'primates' || category === 'mammals' || category === 'birds';
            // return isAnimalCategory ? 'page' : 'animal';

            return 'animal';
        }

        // For `/news/xxxxx`, show the article
        if (base === 'news') {
            return 'single-article';
        }
    }

    return 'page';
};


/**
 * Returns the correct endpoint based on page type
 * @param  {string} requestType e.g animal, page, news
 * @return {string} one of API { ENDPOINTS.X }
 */
export const getEndpoint = (requestType) => {
    switch (requestType) {
        case 'animal':
            return ENDPOINTS.ANIMALS;
        case 'single-article':
            return ENDPOINTS.ARTICLES;
        case 'news':
            return ENDPOINTS.ARTICLES;
        default:
            return ENDPOINTS.PAGES;
    }
};

export const getApiUrl = (reqUrl) => {
    return url.getApiUrl(reqUrl, getEntityType, getEndpoint);
};

export const getRouteArray = (reqUrl) => {
    return url.getRouteArray(reqUrl);
};

/**
 * `Entity` is one of the concepts from `CMS`, it looks like a `category` under every `project` inside `CMS`.
 *
 * @param {any} entityType
 * @return {array} endpoints array
 */
const getEndpointArrByEntity = (entityType) => {
    const endpointsArr = [
        {
            dataKey: 'globalFields',
            endPoint: ENDPOINTS.GLOBAL_FIELDS,
            apiUrl: ''
        },
        {
            dataKey: 'membershipProducts',
            endPoint: ENDPOINTS.BUY_MEMBERSHIP_PRODUCTS,
            apiUrl: ''
        }
    ];

    const animalEndpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.ANIMALS,
        apiUrl: ''
    };

    const newsEnpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.PAGES,
        apiUrl: ''
    };

    const singleArticleEndpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.ARTICLES,
        apiUrl: ''
    };

    const pageEnpoints = {
        dataKey: 'page',
        endPoint: ENDPOINTS.PAGES,
        apiUrl: ''
    };

    switch (entityType) {
        case 'animal':
            endpointsArr.unshift(animalEndpoints);
            break;
        case 'news':
            endpointsArr.unshift(newsEnpoints);
            break;
        case 'single-article':
            endpointsArr.unshift(singleArticleEndpoints);
            break;
        default:
            endpointsArr.unshift(pageEnpoints);
    }

    return endpointsArr;
};

const getApiRequest = (reqUrl, entityTypeHandler, endpointHandler) => {
    const entityType = entityTypeHandler(reqUrl);
    const endpointArr = endpointHandler(entityType);
    const slug = getSlug(reqUrl);

    if (!canUseDom()) {
        console.log('getApiRequest', 'entityType', entityType);
        console.log('getApiRequest', 'endpointArr', getJsonStr(endpointArr));
    }

    // Calulate the `apiUrl` for each endpoit
    const requestEndpoints = endpointArr.map((tempEndpoint) => {
        // `enpointArr[?].enpoint` can be `string | function`
        if (typeof tempEndpoint.endPoint === 'function') {
            let apiUrl = '';

            if (tempEndpoint.endPoint === ENDPOINTS.PAGES) {
                apiUrl = tempEndpoint.endPoint(slug, getParent(reqUrl));
            } else if (tempEndpoint.endPoint === ENDPOINTS.NEWS) {
                apiUrl = tempEndpoint.endPoint();
            } else {
                apiUrl = tempEndpoint.endPoint(slug);
            }

            return Object.assign({}, tempEndpoint, { apiUrl: apiUrl });
        }

        return Object.assign({}, tempEndpoint, { apiUrl: tempEndpoint.endPoint });
    });

    return requestEndpoints;
};

export const getApiRequestArr = (reqUrl) => {
    return getApiRequest(reqUrl, getEntityType, getEndpointArrByEntity);
};

export const getOpenGraphSlug = (slug, parent, type) => {
    switch (type) {
        case 'pages':
            if (slug === 'home') {
                return '';
            }
            if (parent) {
                return `${parent}/${slug}`;
            }

            return slug;
        case 'animals':
            return `animals/${slug}`;
        case 'news':
            return `news/${slug}`;
        default:
            return '';
    }
};

// trim forward slashes and cast to lower case
export const normalizePath = (path) => {
    return path.replace(/^\/+/, '').toLowerCase();
};

export const isAbsoluteUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
};
