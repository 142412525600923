import moment from 'moment';

/**
 * Parse the time in the "17:00:00" format
 *
 * @param  {string} s  Machine time string
 *
 * @return {object}    Moment object
 */
export const parseTime = (s) => moment(s, 'HH:mm:ss');


/**
 * Render the time in the "9:30pm" format
 *
 * @param  {object} t  Moment object
 *
 * @return {string}    Human-readable time string
 */
export const renderTime12 = (t) => t.format('h:mma');

/**
 * Render the time in the "21:30" format
 *
 * @param  {object} t  Moment object
 *
 * @return {string}    Human-readable time string
 */
export const renderTime24 = (t) => t.format('HH:mm');
