/* eslint-disable camelcase */
import imgAucklandZoo from 'static/images/logo-footer.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { parseTime, renderTime12, renderTime24 } from 'app/utilities/time';

class Institutional extends React.Component {
    render() {
        const {
            open_time,
            close_time,
            last_entry
        } = this.props.globalFields;

        const openTime = parseTime(open_time);
        const closeTime = parseTime(close_time);
        const lastEntry = parseTime(last_entry);

        return (
            <div className="institutional">
                <div className="logo">
                    <img className="image" width="510" height="245" src={imgAucklandZoo} alt="Auckland Zoo" title="Auckland Zoo Logo" />
                </div>
                <div className="contact-information">

                    <div className="opening-hours">
                        <h3 className="title || heading-5"> Opening hours</h3>
                        <p className="description" itemProp="openingHours" title={`${renderTime12(openTime)} to ${renderTime12(closeTime)} every day except Christmas`}>
                            <span className="hours || heading-2">
                                <time dateTime={renderTime24(openTime)}>{renderTime12(openTime)}</time>{' '}-{' '}<time dateTime={renderTime24(closeTime)}>{renderTime12(closeTime)}</time>
                            </span>
                            <br />
                            <span>(Last entry at {renderTime12(lastEntry)})</span>
                            <br />
                            <span>Open every day except Christmas</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

Institutional.propTypes = {
    globalFields: PropTypes.object.isRequired
};

export default Institutional;
