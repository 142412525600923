import Audio from './audio';
import Button from './button';
import CallToAction from './call-to-action';
import Carousel from './carousel';
import ColumnCallToAction from './column-call-to-action';
import ContentSet from './content-set';
import DualTab from './dual-tab';
import Encounters from './encounters.container';
import Faq from './faq';
import FileDownload from './file-download';
import Flexi from './flexi';
import Gallery from './gallery';
import Introduction from './introduction';
import Jotform from './jotform';
import PagebreakQuote from './pagebreak-quote';
import Pricing from './pricing';
import PropTypes from 'prop-types';
import React from 'react';
import Rezdy from './rezdy';
import RollingText from './rolling-text';
import StacklaTabs from './stackla-tabs';
import StacklaWidget from './stackla-widget';
import TextContent from './text-content';
import TicketCombo from './ticket-combo';
import Video from './video';

class ContentBlocks extends React.Component {
    constructor(props) {
        super(props);
    }

    renderBlock(block, index) {
        switch (block.type) {
            case 'animaldetailstab':
            case 'dualtab':
                return <DualTab key={index} {...block} />;
            case 'audio':
                return <Audio key={index} {...block.data} />;
            case 'button':
                return <Button key={index} {...block.data} />;
            case 'calltoaction':
                return <CallToAction key={index} {...block} />;
            case 'carousel':
                return <Carousel key={index} items={block.data} />;
            case 'content':
            case 'contentaside':
            case 'contentquote':
            case 'stackladirectuploader':
                return <TextContent key={index} {...block} />;
            case 'contentset':
            case 'crosspromo':
                return <ContentSet key={index} {...block.data} />;
            case 'encounters':
                return <Encounters key={index} initialData={block.data} />;
            case 'faq':
                return <Faq key={index} items={block.data} />;
            case 'file':
                return <FileDownload key={index} { ...block.data } />;
            case 'flexi':
                return <Flexi key={index} items={block.data} />;
            case 'gallery':
                return <Gallery key={index} items={block.data} />;
            case 'introduction':
                return <Introduction key={index} {...block} />;
            case 'stackla':
                return <StacklaWidget key={index} {...block.data} />;
            case 'stacklatabs':
                return <StacklaTabs key={index} {...block.data} />;
            case 'ticketpricing':
                return <Pricing key={index} data={block.data} />;
            case 'video':
                return <Video key={index} {...block} />;
            case 'pagebreakquote':
                return <PagebreakQuote key={index} {...block.data} />;
            case 'columncalltoaction':
                return <ColumnCallToAction key={index} {...block.data} />;
            case 'rezdywidget':
                return <Rezdy key={index} {...block.data} />;
            case 'rollingtextzoo':
                return <RollingText key={index} { ...block } />;
            case 'jotform':
                return <Jotform key={index} {...block.data} />;
            case 'ticketcombo':
                return <TicketCombo key={index} {...block.data} />;
            default:
                return (
                    <div key={index} className="constrain-width large" style={{ textAlign: 'center' }}>
                        <p>Missing content block type: &ldquo;{block.type}&rdquo;</p>
                    </div>
                );
        }
    }

    render() {
        const { renderBefore, data } = this.props;

        if (!data.length && !renderBefore) {
            return null;
        }

        return (
            <div className="content-blocks">
                {renderBefore && renderBefore}
                {data.map((data, index) => {
                    return this.renderBlock(data, index);
                })}
            </div>
        );
    }
}

ContentBlocks.propTypes = {
    renderBefore: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ]),
    data: PropTypes.array,
    isLarge: PropTypes.bool,
    isNews: PropTypes.bool
};

ContentBlocks.defaultProps = {
    data: [],
    isLarge: false,
    isNews: false
};

export default ContentBlocks;
