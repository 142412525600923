import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import React, { useState } from 'react';

const SingleFileDropzone = (props) => {
    const { setDroppedFile } = props;

    const [errors, setErrors] = useState('');

    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        multiple: false,
        // eslint-disable-next-line no-magic-numbers
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections && fileRejections.length) {
                const error = fileRejections[0].errors[0];
                if (error.code === 'file-too-large') {
                    setErrors('Image is too large, please upload a smaller one');
                }

                if (error.code === 'file-invalid-type') {
                    setErrors('Please upload a valid image format');
                }
            } else {
                setErrors();
                setDroppedFile(Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0])
                }));
            }
        }
    });

    return (
        <div className="dropzone-container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p className="input-message">Drag and Drop your image file here or click to select an image file to upload</p>
            </div>
            <p className="error-message">{ errors }</p>
        </div>
    );
};

SingleFileDropzone.propTypes = {
    setDroppedFile: PropTypes.func.isRequired,
};

export default SingleFileDropzone;
