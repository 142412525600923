// This needs to be kept in sync with the page parent options in the CMS.
export default [
    {
        slug: 'visit',
        name: 'Visit'
    },
    {
        slug: 'experiences',
        name: 'Experiences'
    },
    {
        slug: 'get-involved',
        name: 'Get involved'
    },
    {
        slug: 'animals',
        name: 'Animals'
    },
    {
        slug: 'news',
        name: 'News'
    },
    {
        slug: 'plan-your-day',
        name: 'Plan your day'
    }
];
