/* eslint-disable */
import Notification from './notification';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const NotificationBanner = ({
    isActive,
    notifications,
    fetchNotifications,
    updateNotification
}) => {
    // Handler
    const handleToggle = (id) => {
        updateNotification(id);
    }


    useEffect(() => {
        fetchNotifications();
        const banner = document.querySelector('.notification-banner');

        banner.addEventListener('transitionend', () => {
            const main = document.querySelector('.main');

            main.style.paddingTop = `${banner.offsetHeight}px`;
        }, false);

        return () => document.querySelector('.notification-banner').removeEventListener('transitionend', null);
    }, []);

    return (
        <div className={`notification-banner ${isActive ? '' : 'is-hidden'}`}>
            {notifications && notifications.map((item, index) => {
                const {
                    description,
                    isPublished,
                    id,
                    title,
                    type,
                    url,
                    content
                } = item;

                return (
                    <Notification
                        description={description}
                        id={id}
                        title={title}
                        type={type}
                        url={url}
                        content={content}
                        handleToggle={handleToggle}
                        isVisible={isPublished}
                        key={index}
                    />
                );
            })}
        </div>
    );
}

NotificationBanner.propTypes = {
    isActive: PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    updateNotification: PropTypes.func.isRequired,
};

export default NotificationBanner;
