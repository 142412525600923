import defaultBannerImage from 'static/images/default-page-banner.jpg';
import Error from './error/error-page';
import { getOpenGraphSlug } from 'app/utilities/url';
import Helmet from 'react-helmet';
import { isAppProd } from 'config/env';
import NotFound from './error/not-found-page';
import printBreadcrumbList from 'app/utilities/print-breadcrumb-list';
import PropTypes from 'prop-types';
import React from 'react';
import { RESPONSE } from 'config/api';
import Template from 'app/components/templates/index';
import templateType from 'app/utilities/template-type';

const ContentPage = ({ globalFields, page }) => {
    const { new_ticket_flow_slug: newTicketFlowSlug } = globalFields;

    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.NOT_FOUND) return <NotFound />;
    if (page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR || !page.attributes) return <Error />;

    const { type, attributes } = page;

    const {
        author: author,
        is_published: isPublished,
        name: title,
        main_images: mainImages,
        parent,
        thumbnail,
        seo_description: seoDescription,
        seo_keywords: seoKeywords,
        seo_title: seoTitle,
        slug
    } = attributes;

    const titleToUse = seoTitle ? seoTitle : title;

    const openGraphSlug = getOpenGraphSlug(slug, parent, type);

    let image = mainImages[0] || thumbnail;

    if (!image) {
        image = `https://www.aucklandzoo.co.nz${defaultBannerImage}`;
    }

    let templateTypeToUse; // eslint-disable-line one-var
    if (slug === newTicketFlowSlug) {
        templateTypeToUse = 'tickets';
    } else if (slug === 'day-pass') {
        templateTypeToUse = 'day-pass';
    } else {
        templateTypeToUse = templateType(slug, type);
    }

    const isNoIndex = !isAppProd || !isPublished;

    return (
        <main role="main" className="main">
            <Helmet>
                {isNoIndex && <meta name="robots" content="noindex, nofollow" />}

                <title>{titleToUse}</title>
                <meta name="description" content={seoDescription} />
                <meta name="keywords" content={seoKeywords} />
                {author && <meta name="author" content={author} /> }

                {/* Schema.org for Google+ */}
                <meta itemProp="name" content={titleToUse} />
                <meta itemProp="description" content={seoDescription} />
                <meta itemProp="image" content={image} />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={titleToUse} />
                <meta name="twitter:description" content={seoDescription} />
                <meta name="twitter:image" content={image} />

                {/* Open Graph */}
                {/* <meta property="fb:app_id" content={FACEBOOK_APP_ID} /> */}
                <meta property="og:locale" content="en_NZ" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={titleToUse} />
                <meta property="og:description" content={seoDescription} />
                <meta property="og:url" content={`https://www.aucklandzoo.co.nz/${openGraphSlug}`} />
                <meta property="og:image" content={image} />
            </Helmet>
            <Template
                type={templateTypeToUse}
                data={attributes}
                globalFields={globalFields}
            />
            {printBreadcrumbList(page)}
        </main>
    );
};

ContentPage.propTypes = {
    globalFields: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired
};

export default ContentPage;
