/* eslint-disable camelcase */
import { generateOrderPricing } from 'app/utilities/buy-memberships';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import OrderPricing from '../order-pricing';
import { resetBuyMembershipsFlowAction } from 'app/ducks/buy-memberships';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Complete = () => {
    const { paymentStatus } = useSelector((state) => state.buyMemberships);
    const { renewPassId, receiptInfo } = paymentStatus || {};

    const dispatch = useDispatch();

    const renderSuccessMessage = () => (
        <>
            <Icon name="check" title="Success" />
            <h4 className="title center">Your order has been confirmed</h4>
            <div className="message-body">
                An email confirmation and receipt will be sent to you shortly.
            </div>
        </>
    );

    const renderMessage = () => {
        return (
            <div className="message || block || section">
                <div className="constrain-width large no-pad">
                    <div className="inner">
                        <div className="constrain-width medium no-pad">
                            {renderSuccessMessage()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderRenewNextSteps = () => {
        return (
            <React.Fragment>
                <div className="paragraph">
                    <p>Sit back and relax. Your membership has been renewed; you can use your existing cards on your next trip to the zoo.</p>
                    <p>For any new members added to your pass, upload your photos here on the portal in <Link to={MEMBERS_PORTAL_ROUTES.MEMBER_DETAILS}>Member Details</Link>.</p>
                </div>
                <p>
                    <strong>If you have any questions, or need to update any of your existing photos, please visit our friendly staff in the Membership Hub or contact us at <a href="mailto:annual.passes@aucklandzoo.co.nz">annual.passes@aucklandzoo.co.nz</a></strong>
                </p>
            </React.Fragment>
        );
    };

    const renderPurchaseNextSteps = () => {
        return (
            <React.Fragment>
                <div className="paragraph">
                    <p>Your membership is now active.</p>
                    <p>To finish off the creation of your annual pass, you can take or upload your photo in <Link to={MEMBERS_PORTAL_ROUTES.MEMBER_DETAILS}>Member Details</Link>, or if you would like, our friendly staff can take your photo for you at the Membership Hub in the Zoo Gift Shop. Once your membership has a photo ID you are all set to visit us and connect with wildlife, nature and your whānau.</p>
                    <p>To visit the Zoo, scan your digital cards using the Scan Pass function when you arrive at the entry gate, or you can collect your physical copy from the Membership hub.</p>
                </div>
                <p>
                    <strong>If you have any questions, please feel free to contact us at <a href="mailto:annual.passes@aucklandzoo.co.nz">annual.passes@aucklandzoo.co.nz</a></strong>
                </p>
            </React.Fragment>
        );
    };

    const renderNextSteps = () => (
        <div className="block || section">
            <div className="constrain-width large no-pad">
                <div className="inner">
                    <div className="constrain-width medium no-pad next-steps">
                        <h4 className="title">Next steps:</h4>
                        {renewPassId ? renderRenewNextSteps() : renderPurchaseNextSteps()}
                    </div>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        // Clear buy memberships state when user complete one order
        return () => dispatch(resetBuyMembershipsFlowAction());
    }, []);

    return (
        <div className="thank-you">
            {renderMessage()}
            {renderNextSteps()}
            <div className="block || section">
                <div className="constrain-width large no-pad || complete-order">
                    <div className="inner">
                        <div className="constrain-width medium no-pad">
                            <h4 className="title">Order details</h4>
                            <OrderPricing
                                {...generateOrderPricing(receiptInfo)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="to-homepage-wrapper">
                <Link className="button primary" to={MEMBERS_PORTAL_ROUTES.DASHBOARD}>
                    To Dashboard
                </Link>
            </div>
        </div>
    );
};

export default Complete;
