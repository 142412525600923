import Icon from 'app/components/partials/icon';
import Modal from 'app/components/partials/modal';
import PassholderScanCard from './passholder-scan-card';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { useEffect, useRef }  from 'react';

const slickConfig = {
    adaptiveHeight: false,
    arrows: false,
    dots: false,
    speed: 600,
    // fix slide flickering issue when sliding between last and first slides
    useTransform: false
};

const PassholderScanModal = ({ passholders, modalVisible, gotoSlide = 0, closeHandler }) => {
    const slider = useRef(null);

    useEffect(() => {
        // Go to the given slide when the modal becomes visible (disable animation)
        if (modalVisible) {
            slider.current.slickGoTo(gotoSlide, true);
        }
    }, [modalVisible]);

    const renderSliderButtons = (sliderRef) => {
        return (
            <React.Fragment>
                <button className="pass-scan-slider-btn next" onClick={() => sliderRef.current.slickNext()}>
                    <Icon name="chevron" title="Next" />
                </button>
                <button className="pass-scan-slider-btn prev" onClick={() => sliderRef.current.slickPrev()}>
                    <Icon name="chevron" title="Prev" />
                </button>
            </React.Fragment>
        );
    };

    const renderCloseButton = (closeHandler) => {
        return (
            <React.Fragment>
                <div className="pass-scan-modal-close-desktop">
                    <button className="btn-close" onClick={closeHandler}>
                        <Icon name="close" title="" />
                        <span>Close</span>
                    </button>
                </div>
                <div className="pass-scan-modal-close-mobile">
                    <button className="btn-close" onClick={closeHandler}>Close</button>
                </div>
            </React.Fragment>
        );
    };

    return (
        <Modal
            isVisible={modalVisible}
            onCloseHandler={closeHandler}
            isCustom
        >
            <div className="pass-scan-modal-content constrain-width">
                <Slider {...slickConfig} ref={slider}>
                    {passholders.map((passholder, idx) => {
                        return (
                            <PassholderScanCard
                                key={idx}
                                customerNo={passholder.customer_no}
                                passRole={passholder.product}
                                name={passholder.name}
                                imageUrl={passholder.profile_image_url}
                                scanable={passholder.scanable}
                            />
                        );
                    })}
                </Slider>
                {passholders.length > 1 && renderSliderButtons(slider)}
                {renderCloseButton(closeHandler)}
            </div>
        </Modal>
    );
};

PassholderScanModal.propTypes = {
    passholders: PropTypes.array.isRequired,
    modalVisible: PropTypes.bool.isRequired,
    gotoSlide: PropTypes.number.isRequired,
    closeHandler: PropTypes.func.isRequired
};

export default PassholderScanModal;
