import Banner from 'app/components/partials/banner';
import ContentBlocks from 'app/components/content-blocks/index';
import printArticleMetadata from 'app/utilities/print-article-metadata.js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Article extends Component {
    render() {
        const {
            author,
            author_occupation: authorOccupation,
            author_image: authorImage,
            blocks,
            name: title,
            highlighted_information: highlight,
            main_images: images,
            latin_name: latinName,
            publish_at: publishDate
        } = this.props.data;

        return (
            <div className="page || article-view">
                <Banner
                    title={title}
                    subtitle={latinName}
                    author={{
                        name: author,
                        occuppation: authorOccupation,
                        image: authorImage
                    }}
                    publishDate={publishDate}
                    highlight={highlight}
                    images={images} />
                {printArticleMetadata(this.props.data)}
                <ContentBlocks data={blocks} />
            </div>
        );
    }
}

Article.propTypes = {
    data: PropTypes.object.isRequired,
    // TODO: When recent articles need to be appended
    // recent: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ])
};

export default Article;
