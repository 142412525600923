import { displayDateOfBirthFormat } from 'config/buy-memberships-passholder-details';
import Icon from 'app/components/partials/icon';
import moment from 'moment';
import PassholderScanModal from './passholder-scan-modal';
import PassholderUploadImageModal from './passholder-upload-image-model';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const PassholderList = ({ data, fetchPassholdersHandler }) => {
    const { passNo, passholders, scanable } = data;

    const [isCardVisible, setIsCardVisible] = useState(false);
    const [gotoSlide, setGotoSlide] = useState(0);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [onChangePassholder, setOnChangePassholder] = useState(passholders[0]);

    const generateDateOfBirth = (date) => moment(date).isValid() && moment(date).format(displayDateOfBirthFormat) || '';

    const openModal = (slide) => {
        // Must set slide before setting modal visible
        setGotoSlide(slide);
        setIsCardVisible(true);
    };

    // Init the photo upload modal parameters
    const openPhotoUploadModal = (index) => {
        setOnChangePassholder(passholders[index]);
        setIsUploadModalVisible(true);
    };

    return (
        <div className="pass-info">
            <h4>Membership #{passNo}</h4>
            <table>
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th>PASS TYPE</th>
                        <th>DATE OF BIRTH</th>
                        <th>CUSTOMER ID</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {passholders.map((passholder, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="mobile-table-header || table-body-content">NAME</div>
                                    <div className="table-body-content || icon-with-name-wapper">
                                        {passholder.profile_image_url ? <img className="image" src={passholder.profile_image_url} alt="Passholder" /> : <Icon name="user-circle" width="30" height="30" />}
                                        <p className="content-name">{passholder.name}</p>
                                    </div>
                                </td>
                                <td><div className="mobile-table-header || table-body-content">PASS TYPE</div><div className="table-body-content"><p>{passholder.product}</p></div></td>
                                <td><div className="mobile-table-header || table-body-content">DATE OF BIRTH</div><div className="table-body-content"><p>{generateDateOfBirth(passholder.dob)}</p></div></td>
                                <td><div className="mobile-table-header || table-body-content">CUSTOMER ID</div><div className="table-body-content"><p>{passholder.customer_no}</p></div></td>
                                {scanable && (<td><button onClick={() => openModal(index)} className="button primary" disabled={Boolean(!passholder.scanable)}>Scan Pass</button></td>)}
                                {scanable && passholder.need_upload_photo && (<td><button onClick={() => openPhotoUploadModal(index)} className="button">Upload Photo</button></td>)}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <PassholderScanModal
                passholders={passholders}
                modalVisible={isCardVisible}
                gotoSlide={gotoSlide}
                closeHandler={() => setIsCardVisible(false)}
            />
            <PassholderUploadImageModal passholder={onChangePassholder} modalVisible={isUploadModalVisible} closeHandler={() => setIsUploadModalVisible(false)} fetchPassholdersHandler={fetchPassholdersHandler} />
        </div>
    );
};

PassholderList.propTypes = {
    data: PropTypes.shape({
        passNo: PropTypes.string.isRequired,
        passholders: PropTypes.array.isRequired,
        scanable: PropTypes.bool.isRequired
    }),
    fetchPassholdersHandler: PropTypes.func.isRequired
};

export default PassholderList;
