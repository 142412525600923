/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import ContentPage from './content-page';
import selectGlobalFields from 'app/selectors/global-fields';
import selectPage from 'app/selectors/page';

function mapStateToProps(state) {
    return {
        page: selectPage(state, state.router.location.pathname),
        globalFields: selectGlobalFields(state)
    };
}

const VisibleContentPage = connect(mapStateToProps)(ContentPage);

export default VisibleContentPage;
