import ProductsSelectedList from './products-selected-list';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const OrderPricing = ({
    totalCosts,
    saveCosts,
    costsAfterDiscount,
    priceRows = null,
    displayProductesSelectedList
}) => {
    const { extraDiscount } = useSelector((state) => state.buyMemberships);

    return (
        <React.Fragment>
            {displayProductesSelectedList && <section className="section">
                <ProductsSelectedList priceRows={priceRows} />
            </section>}
            <section className="section">
                <p className="totle-price has-discount">Total cost:<span>${totalCosts}</span></p>
                <p className="totle-price primary">You save:<span>${saveCosts}</span></p>
                {extraDiscount && extraDiscount.ga && <p className="totle-price primary">Upgrade discount:<span>${extraDiscount.ga.discount.toFixed(2)}</span></p>}
                <p className="totle-price secondary bg-primary">You pay:<span>${costsAfterDiscount}</span></p>
            </section>
        </React.Fragment>
    );
};

OrderPricing.defaultProps = {
    displayProductesSelectedList: true
};

OrderPricing.propTypes = {
    totalCosts: PropTypes.string.isRequired,
    saveCosts: PropTypes.string.isRequired,
    costsAfterDiscount: PropTypes.string.isRequired,
    priceRows: PropTypes.array,
    displayProductesSelectedList: PropTypes.bool
};

export default OrderPricing;
