/**
 * Gets the local storage item using key
 * @param  {string} key              - unique indetifier
 * @return {object|string|array}     - returns data stored at that key from localStorage
 */
export function getLocal(key) {
    if (!key) return;

    const storage = window.localStorage;
    const data = JSON.parse(storage.getItem(key)) || null;

    return data ? data : null;
}


/**
 * Sets the local storage item using key and data
 * @param  {string} key             - unique indetifier
 * @return {object|string|array}    - object, string or array
 */
export function setLocal(key, data) {
    if (!key || !data) return;

    const storage = window.localStorage;

    storage.setItem(key, JSON.stringify(data));
}
