import { clientFetch } from 'app/utilities/fetch';
import { ENDPOINTS } from 'config/api';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import PassholderList from './passholder-list';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

const PassholderDetails = ({ data }) => {
    const { name: title, short_description: shortDescription } = data;

    // Passholders information
    const [passholders, setPassholders] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Generate query string used to fetch passholders
    const user = useSelector((state) => state.auth.user);
    const passesInfo = {};
    const queryString = user && user.annual_passes && user.annual_passes.length > 0 && user.annual_passes.map((pass) => {
        passesInfo[pass.id] = { ...pass };

        return `annual_pass_ids[]=${pass.id}`;
    }).join('&');

    // Fetch psssholders information when first into the page or updated passholder successfully
    const fetchPassholdersHandler = async() => {
        setPassholders(null);
        setIsLoading(true);
        setError(null);

        // No need to call API if this customer has no annual passes
        if (!queryString) {
            setIsLoading(false);

            return;
        }

        try {
            const response = await clientFetch(`${ENDPOINTS.PORTAL_PASSHOLDERS}?${queryString}`, { method: 'GET' }, true);
            setPassholders(response);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderPasses = (passes) => {
        return (
            <div>
                {Object.keys(passes).map((passId) => {
                    const data = { passNo: passesInfo[passId].pass_number, passholders: passes[passId], scanable: passesInfo[passId].can_scan };

                    return (<PassholderList key={passId} data={data} fetchPassholdersHandler={fetchPassholdersHandler} />);
                })}
            </div>
        );
    };

    const renderIneligible = () => {
        return <p>Looks like you don’t have an active membership yet. Click <Link to={FRONTEND_ROUTES.BUY_MEMBERSHIP}>here</Link> to become an Auckland Zoo Member today!</p>;
    };

    useEffect(async() => {
        await fetchPassholdersHandler();
    }, []);

    return (
        <div className="members-portal-passholders || constrain-width large">
            <h2>{title}</h2>
            <p>{shortDescription}</p>
            {isLoading && <Loader type="block" />}
            {!isLoading && !passholders && renderIneligible()}
            {!isLoading && !error && passholders && Object.keys(passholders).length > 0 && renderPasses(passholders) }
        </div>
    );
};

PassholderDetails.propTypes = {
    data: PropTypes.object.isRequired
};

export default PassholderDetails;
