export const INITIAL_STATE = {};

// Actions
export const GLOBAL_FIELDS_FETCH_SUCCESS = 'rfa-zoo-website/search/GLOBAL_FIELDS_FETCH_SUCCESS';

// Action Creators
export const globalFieldsFetchSuccessAction = (globalFields) => ({
    type: GLOBAL_FIELDS_FETCH_SUCCESS,
    payload: globalFields
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GLOBAL_FIELDS_FETCH_SUCCESS:
            return globalFieldsFetchSuccess(state, action.payload);
        default:
            return state;
    }
};

function globalFieldsFetchSuccess(state, globalFields) { // eslint-disable-line require-jsdoc
    return globalFields;
}

/**
 * Formats the API response
 * @param  {object} response – Raw API response
 * @return {object}          - Formatted API response
 */
export function formatGlobalFields(response) {
    if (response && response.data && response.data.length) {
        const { data } = response;

        const globalFields = {};

        data.map(({ attributes }) => {
            const { name, fields } = attributes;
            globalFields[name] = fields;
        });

        return globalFields;
    }

    return {};
}
