/* eslint-disable camelcase */
import * as defs from './gift-memberships-steps-defs';
import Complete from 'app/components/partials/gift-memberships/steps/complete';
import Customise from 'app/components/partials/buy-memberships/steps/customise';
import GifterDetails from 'app/components/partials/gift-memberships/steps/gifter-details';
import Payment from 'app/components/partials/buy-memberships/steps/payment';
import ReceiverDetails from 'app/components/partials/gift-memberships/steps/receiver-details';
import Review from 'app/components/partials/buy-memberships/steps/review';

export const GIFT_MEMBERSHIP_STEPS = [
    {
        key: 'customise',
        title: 'Create your gift membership',
        subtitle: '',
        index: defs.CUSTOMISE_STEP_INDEX,
        name: 'Customise',
        icon: 'user',
        component: Customise
    },
    {
        key: 'gifter-details',
        title: 'Your details',
        subtitle: 'Great work on choosing such a great gift. Please give us your details so we know who the gift is from.',
        index: defs.GIFTER_DETAILS_STEP_INDEX,
        name: 'Your details',
        icon: 'pass-details',
        component: GifterDetails
    },
    {
        key: 'recipient-details',
        title: 'Gift recipient details',
        subtitle: 'Please give us details of the person you are giving this gift to.',
        index: defs.RECIPIENT_DETAILS_STEP_INDEX,
        name: 'Recipient details',
        icon: 'pass-details',
        component: ReceiverDetails
    },
    {
        key: 'review',
        title: 'Please review your order',
        subtitle: 'You’re almost done! Review your details.',
        index: defs.REVIEW_STEP_INDEX,
        name: 'Review',
        icon: 'cart',
        component: Review
    },
    {
        key: 'payment',
        title: 'Billing detail',
        subtitle: '',
        index: defs.PAYMENT_STEP_INDEX,
        name: 'Payment',
        icon: 'payment',
        component: Payment
    },
    {
        key: 'complete',
        title: 'Thank you for your purchase!',
        subtitle: '',
        index: defs.COMPLETE_STEP_INDEX,
        name: 'Complete',
        icon: 'check',
        component: Complete
    }
];
