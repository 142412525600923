/* eslint-disable require-jsdoc */

export function selectNewsByCategoryActive(state) {
    return state.newsByCategory.activeCategory;
}

export function selectNewsByCategory(state) {
    return state.newsByCategory.items;
}

export function selectNewsByCategoryIsBusy(state) {
    return state.newsByCategory.isBusy;
}

export function selectNewsByCategoryPage(state) {
    return state.newsByCategory.page;
}

export function selectNewsByCategoryResultCount(state) {
    return state.newsByCategory.resultCount;
}
