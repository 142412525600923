import { ENDPOINTS } from 'config/api';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import MembershipDetailsCard from 'app/components/partials/portal/dashboard/membership-details-card';
import moment from 'moment';
import PropTypes from 'prop-types';
import { setUserAnnualPassesAction } from 'app/ducks/auth';
import useProtectedFetch from 'app/hooks/use-protected-fetch';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Dashboard = ({ setActiveAnnualPassVisitisData }) => {
    const [activeAnnualPassIndex, setActiveAnnualPassIndex] = useState(0);
    const [annualPasses, setAnnualPasses] = useState([]);
    const { data, isLoading, error } = useProtectedFetch(ENDPOINTS.PORTAL_DASHBOARD);
    const user = useSelector((state) => state.auth.user) || {};
    const dispatch = useDispatch();

    const matchAnnualPassesAndVisits = (passes, visits) => {
        return passes.reduce((accumulator, current) => {
            const annualPassVisits = visits.find(({ pass_id: passId }) => passId === current.id);

            if (annualPassVisits) accumulator.push({ ...current, visits: annualPassVisits });

            return accumulator;
        }, []);
    };

    const changeActivePass = (type = 'next') => {
        if (type === 'next') {
            return setActiveAnnualPassIndex(
                activeAnnualPassIndex === annualPasses.length - 1 ? 0 : activeAnnualPassIndex + 1
            );
        }

        setActiveAnnualPassIndex(
            activeAnnualPassIndex === 0 ? annualPasses.length - 1 : activeAnnualPassIndex - 1
        );
    };

    const renderGreetingMessage = () => {
        const currentHour = moment().hour();
        let greetingMessage = 'Hello';

        const { first_name: firstName } = user;

        if (currentHour >= 0 && currentHour < 12) {
            greetingMessage = 'Ata mārie (Good morning)';
        } else if (currentHour >= 12 && currentHour <= 24) {
            greetingMessage = 'Kia ora';
        }


        return (
            <h2 className="heading text-light">
                <span className="greeting">{greetingMessage},</span>
                <span className="name text-dark">{`${firstName}`}</span>
            </h2>
        );
    };

    const renderAnnualPassSelector = () => {
        const { pass_number: passNumber } = annualPasses[activeAnnualPassIndex] || {};

        return (
            <div className="annual-pass-selector">
                <button
                    className="btn-prev"
                    onClick={() => changeActivePass('prev')}
                    disabled={activeAnnualPassIndex === 0}
                >
                    <Icon className="icon prev" name="chevron" title="Previous" />
                </button>
                <p className="text text-semibold">{passNumber}</p>
                <button
                    className="btn-next"
                    onClick={() => changeActivePass('next')}
                    disabled={activeAnnualPassIndex === annualPasses.length - 1}
                >
                    <Icon className="icon next" name="chevron" title="Next" />
                </button>
            </div>
        );
    };

    const renderIneligible = () => {
        return <p>Looks like you don’t have an active membership yet. Click <Link to={FRONTEND_ROUTES.BUY_MEMBERSHIP}>here</Link> to become an Auckland Zoo Member today!</p>
    };

    useEffect(() => {
        if (annualPasses && annualPasses.length > 0) {
            const passStatus = annualPasses[activeAnnualPassIndex].status.toLowerCase();
            setActiveAnnualPassVisitisData(passStatus === 'pending' ? null : annualPasses[activeAnnualPassIndex].visits);
        }
    }, [activeAnnualPassIndex, annualPasses]);

    useEffect(() => {
        if (data && !error) {
            const { annual_passes: annualPasses, visits } = data;

            setAnnualPasses(matchAnnualPassesAndVisits(annualPasses, visits));

            // also update annual passes in redux store
            dispatch(setUserAnnualPassesAction(annualPasses));
        }
    }, [data]);

    return (
        <div className="members-portal-dashboard || constrain-width large">
            <div className="heading-container">
                {renderGreetingMessage()}
                {!isLoading && annualPasses && annualPasses.length > 1 && renderAnnualPassSelector()}
            </div>
            {isLoading && <Loader type="block" />}
            {!isLoading && annualPasses && annualPasses.length === 0 && renderIneligible()}
            {!isLoading && annualPasses && annualPasses.length > 0 && <MembershipDetailsCard data={annualPasses[activeAnnualPassIndex]} />}
        </div>
    );
};

Dashboard.propTypes = {
    data: PropTypes.object.isRequired,
    setActiveAnnualPassVisitisData: PropTypes.func.isRequired
};

export default Dashboard;
