import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';

export const NAVIGATION_ITEMS = [
    {
        label: 'Dashboard',
        link: MEMBERS_PORTAL_ROUTES.DASHBOARD
    },
    {
        label: 'Member Details',
        link: MEMBERS_PORTAL_ROUTES.MEMBER_DETAILS
    },
    {
        label: 'Renew',
        link: MEMBERS_PORTAL_ROUTES.RENEW
    },
    {
        label: 'Gift a Membership',
        link: MEMBERS_PORTAL_ROUTES.GIFT
    },
    {
        label: 'Redeem membership',
        link: MEMBERS_PORTAL_ROUTES.REDEEM_GIFT
    },
    {
        label: 'Account settings',
        link: MEMBERS_PORTAL_ROUTES.ACCOUNT_SETTINGS
    }
];
