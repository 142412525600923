import classnames from 'classnames';
import HorizontalSelector from 'app/components/partials/horizontal-selector';
import PropTypes from 'prop-types';
import Stackla from 'app/components/partials/stackla';
import React, { Component } from 'react';

class StacklaTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: 0
        };
    }

    render() {
        const { subtitle, tabs, title } = this.props;
        const { activeTabIndex } = this.state;

        return (
            <section className="stackla-tabs-block || constrain-width large">
                {title && <header className="section-header">
                    <div className="constrain-width large">
                        <div className="inner">
                            <div className="title-wrapper">
                                <h2 className="title || heading-1">{title}</h2>
                                {subtitle && <p className="subtitle">{subtitle}</p>}
                            </div>
                        </div>
                    </div>
                </header>}
                <div className="filter-by-row">
                    <div className="filter-by-label">
                        <div className="filter-by-label-inner">Filter by:</div>
                    </div>
                    <HorizontalSelector
                        labels={tabs.map(({ label }) => label)}
                        onChange={(activeTabIndex) => { this.setState({ activeTabIndex }); }}
                        value={activeTabIndex}
                    />
                </div>
                {tabs.map(({ label, stackla }, tabIndex) => (
                    <div
                        key={tabIndex}
                        className={classnames('stackla-tab', { 'is-active': tabIndex === activeTabIndex })}
                    >
                        <Stackla title={label} {...stackla} />
                    </div>
                ))}
            </section>
        );
    }
}

StacklaTabs.propTypes = {
    subtitle: PropTypes.string.isRequired,
    tabs: PropTypes.array.isRequired, // each item must have label and { hash, id, title }
    title: PropTypes.string.isRequired
};

export default StacklaTabs;
