
import Cropper from 'react-easy-crop';
import getCroppedImg from 'app/utilities/crop-image';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

// Cropped image final output width
const OUTPUT_WIDTH = 300;

const ImageCropper = (props) => {
    const { setInputFile, file } = props;

    // Cropper
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
    const finishCropImage = useCallback(async() => {
        try {
            const croppedImage = await getCroppedImg(file.preview, croppedAreaPixels, OUTPUT_WIDTH);

            setInputFile(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels]);

    return (
        <div className="cropper-zone">
            <div className="cropper-container">
                <Cropper
                    image={file.preview}
                    crop={crop}
                    zoom={zoom}
                    aspect={0.8333}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div className="cropper-controller">
                <button type="button" className="button" onClick={finishCropImage}>Preview</button>
            </div>
        </div>
    );
};

ImageCropper.propTypes = {
    setInputFile: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
};

export default ImageCropper;
