/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import DonationPaymentPage from './donation-payment-page';
import { fetchProductsListAction, setFormFieldValueAction, submitFormAction, touchedFormFieldAction } from 'app/ducks/donation-payment';
import {
    selectFieldTouched,
    selectFieldValue,
    selectFormError,
    selectSubmitted,
    selectSubmittedSuccessfully
} from 'app/selectors/donation-payment';

function mapStateToProps(state) {
    return {
        productsList: state.donationPayment.productsList,
        isBusy: state.donationPayment.isBusy,
        getFieldTouched: (fieldName) => selectFieldTouched(state, fieldName),
        getFieldValue: (fieldName) => selectFieldValue(state, fieldName),
        formError: selectFormError(state),
        formSubmitted: selectSubmitted(state),
        submittedSuccessfully: selectSubmittedSuccessfully(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProductsList: () => dispatch(fetchProductsListAction()),
        setFieldValue: (fieldName, value) => dispatch(setFormFieldValueAction(fieldName, value)),
        touchedField: (fieldName) => dispatch(touchedFormFieldAction(fieldName)),
        submitForm: (formData, navigate) => dispatch(submitFormAction(formData, navigate))
    };
}

const VisibleDonationPaymentPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: true }
)(DonationPaymentPage);

export default VisibleDonationPaymentPage;
