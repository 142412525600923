import moment from 'moment-timezone';
import { TIME_FORMAT } from 'config/time';

export function createCurrentNzDateObject() {
    return moment().tz('Pacific/Auckland').toDate();
}

export function dateObjectToTimeAsANumber(d) {
    const currentHoursNz = d.getHours(); // 0 -- 23
    const currentMinutesNz = d.getMinutes(); // 0 -- 59

    return currentHoursNz * 100 + currentMinutesNz; // eslint-disable-line no-magic-numbers
}

function timeAsANumberToString(timeAsANumber, postfix) {
    const timeAsAString = timeAsANumber.toString();
    if (timeAsAString.length === 1) {
        return `0:0${timeAsAString}${postfix}`;
    }
    if (timeAsAString.length === 2) { // eslint-disable-line no-magic-numbers
        return `0:${timeAsAString}${postfix}`;
    }
    if (timeAsAString.length === 3) { // eslint-disable-line no-magic-numbers
        return `${timeAsAString.substr(0, 1)}:${timeAsAString.substr(1)}${postfix}`;
    }
    if (timeAsAString.length === 4) { // eslint-disable-line no-magic-numbers
        return `${timeAsAString.substr(0, 2)}:${timeAsAString.substr(2)}${postfix}`; // eslint-disable-line no-magic-numbers
    }

    return '';
}

// possible timeAsA24HourNumber values: 420 (for '4:20am'), 1640 (for '4:40pm'), etc
export function processTime(timeAsA24HourNumber) {
    if (timeAsA24HourNumber < 100) { // eslint-disable-line no-magic-numbers
        return timeAsANumberToString(timeAsA24HourNumber + 1200, 'am'); // eslint-disable-line no-magic-numbers
    }
    if (timeAsA24HourNumber < 1200) { // eslint-disable-line no-magic-numbers
        return timeAsANumberToString(timeAsA24HourNumber, 'am');
    }
    if (timeAsA24HourNumber < 1300) { // eslint-disable-line no-magic-numbers
        return timeAsANumberToString(timeAsA24HourNumber, 'pm');
    }

    return timeAsANumberToString(timeAsA24HourNumber - 1200, 'pm'); // eslint-disable-line no-magic-numbers
}

// Turns string 'friday' into 'Friday'
export function processWeekday(weekdayLowercase) {
    return weekdayLowercase.charAt(0).toUpperCase() + weekdayLowercase.slice(1);
}


/**
 * Returns format for TIME_FORMAT.PUBLISHED_TIME
 * @param  {string} timeFromAPI - TIME_FORMAT.API
 * @return {string} TIME_FORMAT.PUBLISHED_TIME
 */
export function publishedTime(timeFromAPI) {
    return moment(timeFromAPI).format(TIME_FORMAT.PUBLISHED_TIME);
}


/**
 * Returns format for TIME_FORMAT.META
 * @param  {string} timeFromAPI - TIME_FORMAT.API
 * @return {string} TIME_FORMAT.META
 */
export function metaTime(timeFromAPI) {
    return moment(timeFromAPI).format(TIME_FORMAT.META);
}
