import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { truncateOnWordBoundary } from 'app/utilities/truncate';

const CHARACTER_LIMIT = {
    DESCRIPTION: 100,
    TITLE: 100
};

class Tile extends React.Component {
    render() {
        const { item } = this.props;

        let style = {};

        const {
            name,
            short_description: description,
            thumbnail
        } = item.data;

        if (thumbnail) {
            style = { backgroundImage: `url(${thumbnail})` };
        }

        return (
            <article className="tile">
                <EntityLink
                    item={item}
                    promoterBuName="Auckland Zoo"
                    className="tile-inner || image-overlay"
                    style={style}
                >
                    <div className="heading-group">
                        <span className="flag"><Icon name="chevron" /></span>
                        <h3 className="title">{truncateOnWordBoundary(name, CHARACTER_LIMIT.TITLE)}</h3>
                        {description && <p className="subtitle">{truncateOnWordBoundary(description, CHARACTER_LIMIT.DESCRIPTION)}</p>}
                    </div>
                </EntityLink>
            </article>
        );
    }
}

Tile.propTypes = {
    item: PropTypes.object.isRequired
};

export default Tile;
