import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const AnnualPassOption = ({ title, image, price, link, description, from, mediaStyle = {}, subtitle = '', isVisible = true }) => {
    const mediaInnerStyle = {
        backgroundImage: `url(${image})`,
        ...mediaStyle
    };

    const annualPassOptionClass = classnames('annual-pass-option', {
        isHidden: !isVisible
    });

    return (
        <a href={link} className={annualPassOptionClass}>
            <div className="media">
                {image && <div className="media-inner || preserve-image-ratio" style={mediaInnerStyle}>
                    <img className="fixed-image" src={image} alt={title} />
                </div>}
            </div>
            <div className="content">
                <div className="row">
                    <div className="title">{title} <span className="subtitle">{subtitle}</span></div>
                </div>
                {description && <div className="row description-row">
                    <div className="description">
                        <p>{description}</p>
                    </div>
                </div>}
                <div className="row cta-row">
                    <div className="price-tag">
                        <div className="price-label">{(from === 'tickets-section') && 'from'} <b>${Number(price).toFixed(2)}</b></div>
                    </div>
                    <span className="cta-arrow">
                        <Icon name="chevron" />
                    </span>
                </div>
            </div>
        </a>
    );
};

AnnualPassOption.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    description: PropTypes.string,
    from: PropTypes.string,
    mediaStyle: PropTypes.object,
    subtitle: PropTypes.string,
    isVisible: PropTypes.bool
};

export default AnnualPassOption;
