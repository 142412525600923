import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { NEWS } from 'config/news';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    items: undefined,
    isBusy: false,
    featuredItem: null,
    resultCount: undefined
};


// Actions
export const FETCH_NEWS_FAIL = 'rfa-zoo-website/news/FETCH_NEWS_FAIL';
export const FETCH_NEWS_SUCCESS = 'rfa-zoo-website/news/FETCH_NEWS_SUCCESS';
export const FETCH_NEWS = 'rfa-zoo-website/news/FETCH_NEWS';


// Action Creators
export const fetchNewsAction = (page) => ({
    type: FETCH_NEWS,
    payload: {
        page: page,
        isBusy: true
    }
});

export const fetchNewsFailAction = () => ({
    type: FETCH_NEWS_FAIL,
    payload: {
        isBusy: false
    }
});

export const fetchNewsSuccessAction = ({ items, featuredItem, resultCount }) => ({
    type: FETCH_NEWS_SUCCESS,
    payload: {
        items: items,
        isBusy: false,
        featuredItem: featuredItem,
        resultCount: resultCount
    }
});


// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NEWS:
            return fetchNews(state, action.payload);
        case FETCH_NEWS_FAIL:
            return fetchNewsFail(state, action.payload);
        case FETCH_NEWS_SUCCESS:
            return fetchNewsSuccess(state, action.payload);
        default:
            return state;
    }
};

function fetchNews(state, { isBusy, page }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        isBusy: isBusy,
        page: page
    };
}

function fetchNewsFail(state, { isBusy }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        isBusy: isBusy
    };
}

function fetchNewsSuccess(state, { items, featuredItem: responseFeaturedItem, resultCount }) { // eslint-disable-line require-jsdoc
    const currentItems = Array.isArray(state.items) ? state.items : [];
    const featuredItem = responseFeaturedItem ? responseFeaturedItem : state.featuredItem;

    return {
        ...state,
        isBusy: false,
        items: [...currentItems, ...items], // Append new items
        featuredItem: featuredItem,
        resultCount: resultCount
    };
}


// Epic creator
/**
 * News Epic
 * @param  {func} endpoint               - News endpoint
 * @param  {func} formatNews             - Format response function
 * @param  {func} fetchNewsSuccessAction - Success action creator
 * @param  {func} fetchNewsFailAction    - Fail action creator
 * @return {func}
 */
export const createNewsEpic = (endpoint, formatNews, fetchNewsSuccessAction, fetchNewsFailAction) => {
    return (action$) => action$.pipe(
        ofType(FETCH_NEWS),
        mergeMap(({ payload }) => {
            // Include the featured item on first request
            const includeFeatured = payload.page === 1;

            return (
                fetch(endpoint(NEWS.INTRO_LIMIT, includeFeatured, payload.page))
                    .then((response) => {
                        return fetchNewsSuccessAction(formatNews(response));
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return fetchNewsFailAction();
                    })
            );
        })
    );
};


// Epics
const itemsEpic = createNewsEpic(
    ENDPOINTS.NEWS,
    formatNews,
    fetchNewsSuccessAction,
    fetchNewsFailAction
);


/**
 * Formats the API response
 * @param  {object} response – Raw API response
 * @return {object}          - Formatted API response
 */
export function formatNews(response) {
    if (response && response.data && response.data.length) {
        const { data, featured, meta } = response;

        // Format "attributes" to "data"
        const items = data.map((item) => ({
            ...item,
            displayType: NEWS.DISPLAY_TYPES.EXTENDED,
            data: item.attributes
        }));

        let featuredItem = INITIAL_STATE.featuredItem;

        if (featured && featured.data && featured.data.id) {
            // Pass featured item that's tagged
            featuredItem = {
                id: featured.data.id,
                data: featured.data,
                displayType: NEWS.DISPLAY_TYPES.FEATURED,
                type: 'news'
            };
        }

        return {
            items,
            featuredItem,
            resultCount: meta.result_count
        };
    }

    return INITIAL_STATE;
}

export const epics = combineEpics(itemsEpic);
