import canUseDom from 'app/utilities/dom';

const env = canUseDom() ? window.__ENV__ : process.env; // eslint-disable-line

const {
    API_BASE_URL,
    CLIENT_ID,
    CLIENT_SECRET,
    NODE_ENV,
    APP_ENV,
    SENTRY_DSN,
    APP_PORT,
    GOOGLE_TAG_MANAGER_CONTAINER_ID,
    GOOGLE_OPTIMIZE_CONTAINER_ID,
    FACEBOOK_PIXEL_ID,
    SPOTIFY_PIXEL_ID,
    GOOGLE_SITE_VERIFICATION,
    FACEBOOK_SITE_VERIFICATION,
    STRIPE_PUBLIC_KEY,
    RECAPTCHA_KEY
} = env;

export const clientEnv = {
    API_BASE_URL: '/api',
    NODE_ENV,
    APP_ENV,
    SENTRY_DSN,
    APP_PORT,
    GOOGLE_TAG_MANAGER_CONTAINER_ID,
    GOOGLE_OPTIMIZE_CONTAINER_ID,
    FACEBOOK_PIXEL_ID,
    SPOTIFY_PIXEL_ID,
    GOOGLE_SITE_VERIFICATION,
    FACEBOOK_SITE_VERIFICATION,
    STRIPE_PUBLIC_KEY,
    RECAPTCHA_KEY
};

export default {
    API_BASE_URL,
    CLIENT_ID,
    CLIENT_SECRET,
    NODE_ENV,
    APP_ENV,
    SENTRY_DSN,
    APP_PORT,
    GOOGLE_TAG_MANAGER_CONTAINER_ID,
    GOOGLE_OPTIMIZE_CONTAINER_ID,
    FACEBOOK_PIXEL_ID,
    SPOTIFY_PIXEL_ID,
    GOOGLE_SITE_VERIFICATION,
    FACEBOOK_SITE_VERIFICATION,
    STRIPE_PUBLIC_KEY,
    RECAPTCHA_KEY
};

export const isProd = env.NODE_ENV === 'production';
export const isAppProd = env.APP_ENV === 'prod';
