import Account from 'app/components/partials/portal/account';
import classnames from 'classnames';
import ContentBlocks from 'app/components/content-blocks/index';
import Dashboard from 'app/components/partials/portal/dashboard';
import Gift from 'app/components/partials/portal/gift';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import PassholderDetails from 'app/components/partials/portal/passholder-details';
import PortalNavigation from 'app/components/partials/portal/navigation';
import PropTypes from 'prop-types';
import RedeemGift from 'app/components/partials/portal/redeem-gift';
import Renew from 'app/components/partials/portal/renew';
import { LabelList, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import React, { useEffect, useState } from 'react';

const MemberPortal = ({ data, type }) => {
    const [activeAnnualPassVisitisData, setActiveAnnualPassVisitisData] = useState(null);
    const [chartPage, setChartPage] = useState(1);

    const {
        num_passholders: numPassholders,
        visits_per_month: visitsPerMonth,
        visits_per_passholder: visitsPerPassholder
    } = activeAnnualPassVisitisData || {};

    const totalVisits = visitsPerPassholder ? visitsPerPassholder.reduce((prev, { num_visits: numVisits }) => {
        return prev + numVisits;
    }, 0) : 0;

    const { blocks } = data;

    // const lineChartData = activeAnnualPassVisitisData
    const generateLineChartData = () => {
        return visitsPerMonth.map(({ label, cnt }) => {
            return {
                name: label,
                visit: cnt,
            };
        });
    };

    const renderScrollbuttons = () => {
        const prevCls = classnames('scroll-btn', 'prev', { 'hidden': chartPage === 0 });
        const nextCls = classnames('scroll-btn', 'next', { 'hidden': chartPage === 1 });

        return (
            <React.Fragment>
                <button className={nextCls} onClick={() => setChartPage(1)}>
                    <Icon name="chevron" title="Next" />
                </button>
                <button className={prevCls} onClick={() => setChartPage(0)}>
                    <Icon name="chevron" title="Prev" />
                </button>
            </React.Fragment>
        );
    };

    const getChartScrollStyle = () => {
        const style = {};
        // just allow 2 "pages" for the scrolling
        if (chartPage === 1) {
            style.right = 0;
        } else {
            style.left = 0;
        }

        return style;
    };

    const renderLineChart = () => {
        return (
            <div className="line-chart-container">
                {renderScrollbuttons()}
                <div className="line-chart-scroller-wrapper">
                    <div className="line-chart-scroller" style={getChartScrollStyle()}>
                        <ResponsiveContainer width="100%" height={200}>
                            <LineChart data={generateLineChartData()} margin={{ top: 30, right: 30, left: 20, bottom: 5 }}>
                                <XAxis dataKey="name" tick={{ transform: 'translate(0, 6)' }} axisLine={false} tickLine={false} interval={0} />
                                <YAxis hide />
                                <Line
                                    type="linear"
                                    dataKey="visit"
                                    stroke="#f99d22"
                                    strokeWidth="2"
                                    dot={{ stroke: '#f99d22', strokeWidth: 2, r: 5 }}
                                >
                                    <LabelList dataKey="visit" position="top" offset="15" angle="0" />
                                </Line>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                </div>
            </div>
        );
    };

    const renderVisits = () => (
        <div className="visits-table-container">
            <table className="visits-table">
                <tbody>
                    {visitsPerPassholder.map(({ name, num_visits: numVisits }, index) => {
                        if (name) return (
                            <tr key={index}>
                                <td className="name">{name}</td>
                                <td className="visits" align="right">{numVisits} {numVisits > 1 ? 'visits' : 'visit'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    const renderAfterContent = () => (
        <div className="visits-chart-container">
            <div className="heading">
                {numPassholders && <h2 className="title"><span className="text-primary">{numPassholders}</span>{numPassholders > 1 ? 'Members' : 'Member'}</h2>}
                <Link className="link" to={MEMBERS_PORTAL_ROUTES.MEMBER_DETAILS}>
                    View Member(s)
                </Link>
            </div>
            <p className="subheading">Total visitation with current membership</p>
            {renderLineChart()}
            <p className="subheading">Member visitation with current membership</p>
            {visitsPerPassholder && visitsPerPassholder.length > 0 && renderVisits()}
            {<h2 className="title text-align-right"><span className="text-primary">{totalVisits}</span>{totalVisits > 1 ? 'Visits' : 'Visit'}</h2>}
        </div>
    );

    // Add chart data to rolling text content block
    const formattedContentBlocks = blocks.reduce((acc, current) => {
        if (current.type === 'rollingtextzoo') {
            // eslint-disable-next-line camelcase
            acc.push({ ...current, renderAfter: activeAnnualPassVisitisData ? renderAfterContent() : null });
        } else {
            acc.push(current);
        }

        return acc;
    }, []);

    const renderContent = () => {
        switch (type) {
            case 'dashboard':
                return <Dashboard data={data} setActiveAnnualPassVisitisData={setActiveAnnualPassVisitisData} />;
            case 'details':
                return <PassholderDetails data={data} />;
            case 'renew':
                return <Renew data={data} />;
            case 'gift':
                return <Gift data={data} />;
            case 'redeem-gift':
                return <RedeemGift data={data} />;
            case 'account':
                return <Account data={data} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        setActiveAnnualPassVisitisData(null);
    }, [type]);

    return (
        <div className="page || page-view no-banner">
            <section className="constrain-width large || members-portal">
                <div className="members-portal-navigation">
                    <PortalNavigation />
                </div>
                <div className="members-portal-content">
                    <ContentBlocks renderBefore={renderContent()} data={formattedContentBlocks} />
                </div>
            </section>

        </div>
    );
};

MemberPortal.propTypes = {
    data: PropTypes.object,
    globalFields: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};

export default MemberPortal;
