import PropTypes from 'prop-types';
import React from 'react';

const Gift = ({ data }) => {
    const { name: title, short_description: shortDescription } = data;

    return (
        <div className="members-portal-gift || constrain-width large">
            <h2>{title}</h2>
            <p>{shortDescription}</p>
        </div>
    );
};

Gift.propTypes = {
    data: PropTypes.object.isRequired
};

export default Gift;
