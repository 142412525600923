import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const CheckBox = ({ label, name, value, onChangeHandler, inputType }) => {
    const formFieldClass = classnames('form-field checkbox-wrapper', {
        'full-width': inputType === 'large'
    });

    return (
        <div className={formFieldClass}>
            <label>
                <input
                    name={name}
                    type="checkbox"
                    checked={value}
                    onChange={onChangeHandler}
                />
                <span className="checkbox-button">
                    <Icon className="icon" name="check" />
                </span>
                <span dangerouslySetInnerHTML={{ __html: label }}></span>
            </label>
        </div>
    );
};

CheckBox.defaultProps = {
    inputType: 'normal'
};

CheckBox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    onChangeHandler: PropTypes.func,
    inputType: PropTypes.string
};

export default CheckBox;
