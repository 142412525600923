export const NAVIGATION_HEADER = [
    {
        label: 'Visit',
        link: '/visit',
        children: [
            {
                label: 'Plan Your Day',
                link: '/visit/plan-your-day'
            },
            {
                label: "What's On", // eslint-disable-line quotes
                link: '/visit/whats-on'
            },
            {
                label: 'Membership',
                link: '/visit/membership'
            },
            {
                label: 'Vet Hospital',
                link: '/visit/vet-hospital'
            },
            {
                label: 'Education',
                link: '/visit/education'
            },
            {
                label: 'Zoo Map',
                link: '/visit/zoo-map'
            },
        ]
    },
    {
        label: 'Experiences',
        link: '/experiences',
        children: [
            {
                label: 'Zoo Experiences',
                link: '/experiences/zoo-experiences'
            },
            {
                label: 'Birthday Parties',
                link: '/experiences/birthday-parties'
            },
            {
                label: 'Junior Keeper',
                link: '/experiences/experience-notice'
            },
            {
                label: 'Functions & Venues',
                link: '/experiences/functions-and-venues'
            },
        ]
    },
    {
        label: 'Get involved',
        link: '/get-involved',
        children: [
            {
                label: 'Work at the Zoo',
                link: '/get-involved/work-at-the-zoo'
            },
            {
                label: 'Volunteer',
                link: '/get-involved/volunteer'
            },
            {
                label: 'Sponsorship',
                link: '/get-involved/sponsorship'
            },
            {
                label: 'Conservation',
                link: '/get-involved/conservation'
            },
            {
                label: 'Donate',
                link: '/get-involved/donate',
                type: 'button'
            }
        ]
    },
    {
        label: 'Animals',
        link: '/animals'
    },
    {
        label: 'News',
        link: '/news'
    },
    {
        label: 'About the Zoo',
        isMobileOnly: true,
        children: [
            {
                label: 'About Us',
                link: '/about-the-zoo'
            },
            {
                label: 'Get in Touch',
                link: '/get-in-touch'
            }
        ]
    }
];

export const NAVIGATION_HEADER_SECONDARY = [
    {
        label: 'About us',
        link: '/about-the-zoo',
        external: false
    },
    {
        label: 'Education',
        link: '/visit/education',
        external: false
    },
    {
        label: 'Donate',
        link: '/get-involved/donate',
        external: false
    },
    {
        label: 'Shop',
        link: 'https://shop.aucklandzoo.co.nz/',
        external: true
    }
];

export const HEADER_CTA = {
    'label': 'Tickets',
    'link': '/tickets'
};

export const NAVIGATION_FOOTER = [
    {
        title: 'Support',
        children: [
            {
                label: 'Tickets',
                link: '/tickets',
                internal: true
            },
            {
                label: 'Shop',
                link: 'https://shop.aucklandzoo.co.nz/',
                internal: false
            },
            {
                label: 'Donate',
                link: '/get-involved/donate',
                internal: true
            }
        ]
    },
    {
        title: 'Learning',
        children: [
            {
                label: 'Conservation',
                link: '/get-involved/conservation',
                internal: true
            },
            {
                label: 'Education',
                link: '/visit/education',
                internal: true
            },
            {
                label: 'Vet Hospital',
                link: '/visit/vet-hospital',
                internal: true
            }
        ]
    },
    {
        title: 'About the Zoo',
        children: [
            {
                label: 'About Us',
                link: '/about-the-zoo',
                internal: true
            },
            {
                label: 'Get in touch',
                link: '/get-in-touch',
                internal: true
            }
        ]
    }
];
