import { clientFetch } from 'app/utilities/fetch';
import { generatePassholdersDisplayInfo } from 'app/utilities/buy-memberships';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import PasshoderDetailsForm from './passholder-details-form';
import PropTypes from 'prop-types';
import { updateSelectedData } from 'app/ducks/buy-memberships';
import { useSelector } from 'react-redux';
import { ENDPOINTS, RESPONSE } from 'config/api';
import React, { useState } from 'react';

const RedeemGift = ({ data }) => {
    const [voucher, setVoucher] = useState('');
    const [productsSelected, setProductsSelected] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState(null);

    const products = useSelector((state) => state.tickets.membership.products);
    const { name: title } = data;

    const getGiftProductsSelected = (data) => {
        return Object.entries(data).reduce((prev, [name, quantities]) => {
            const getProduct = products.find((product) => product.name.toLowerCase() === name.toLowerCase());

            if (getProduct) prev.push({ ...getProduct, product: name, quantities });

            return prev;
        }, []);
    };

    const verifyVoucherHandler = async(event) => {
        event.preventDefault();
        setStatus(null);
        setIsSubmitting(true);
        try {
            const response = await clientFetch(ENDPOINTS.GIFT_MEMBERSHIP_CODE(voucher), { method: 'GET' }, true);
            setProductsSelected({
                products: updateSelectedData([], getGiftProductsSelected(response.product_qtys)),
                hasGuestPass: response.has_guest_pass
            });
        } catch (err) {
            const { error } = err.error;
            const { code, message } = error || {};

            let customErrorMessage = message || RESPONSE.DEFAULT;

            if (code && code === 'gift_code_redeemed') {
                customErrorMessage = 'This voucher code has already been redeemed.';
            }

            if (code && code === 'gift_code_invalid') {
                customErrorMessage = 'This is an invalid voucher code. Please get in touch with <a href="mailto:annual.passes@aucklandzoo.co.nz">annual.passes@aucklandzoo.co.nz</a>.';
            }

            setStatus({ error: customErrorMessage });
        } finally {
            setIsSubmitting(false);
        }
    };

    const getGiftProductsInfo = () => {
        const { products, hasGuestPass } = productsSelected || {};

        return generatePassholdersDisplayInfo([...products, ...hasGuestPass ? [{ product: 'adult guest', quantities: 1 }] : []]).toLowerCase();
    };

    const renderVoucherVerifyForm = () => (
        <form className="form || voucher-verify-form" onSubmit={verifyVoucherHandler}>
            <div className="section">
                <h4 className="title with-tail">Enter voucher code</h4>
                <Input
                    label="Voucher code"
                    type="text"
                    className="input"
                    name="voucher"
                    id="voucher"
                    value={voucher}
                    onChangeHandler={(event) => setVoucher(event.target.value)}
                    readOnly={Boolean(productsSelected)}
                    large
                    isRequired
                />
            </div>
            {status && status.error && <div className="error-message" dangerouslySetInnerHTML={{ __html: status.error }}></div>}
            {!productsSelected &&
                <button className="button primary" type="submit" disabled={!voucher || isSubmitting}>
                    {isSubmitting ? <Loader type="small" /> : 'Next'}
                </button>
            }
        </form>
    );

    return (
        <div className="section || members-portal-redeem-gift || constrain-width large">
            <h2>{title}</h2>
            {productsSelected && <p>{`You have a membership for ${getGiftProductsInfo()}. Please enter details below to redeem.`}</p>}
            <div className="form-container">
                {renderVoucherVerifyForm()}
                {productsSelected && <PasshoderDetailsForm {...productsSelected} voucher={voucher} />}
            </div>
        </div>
    );
};

RedeemGift.propTypes = {
    data: PropTypes.object.isRequired
};

export default RedeemGift;
