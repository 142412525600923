export const CUSTOMISE_STEP_INDEX = 1;
export const GIFTER_DETAILS_STEP_INDEX = 2;
export const RECIPIENT_DETAILS_STEP_INDEX = 3;
export const REVIEW_STEP_INDEX = 4;
export const PAYMENT_STEP_INDEX = 5;
export const COMPLETE_STEP_INDEX = 6;

export const CUSTOMISE_SECTIONS = [
    {
        title: 'Named passholders',
        subtitle: 'You can add as many or as few people to the gift membership as you like. You don’t need to fill in the member details as the recipient will do that when they redeem the gift.',
        type: 'normal'
    },
    {
        title: 'Guests',
        subtitle: 'You can add one guest member to the gift membership – which can be any used by any adult at one time. Adult guest member must be accompanied by a named member.',
        type: 'guest'
    }
];
