/* eslint-disable require-jsdoc */
import { clientFetch } from 'app/utilities/fetch';
import { createNewsletterSignupFormFields } from 'app/utilities/newsletter-form-fields';
import { ENDPOINTS } from 'config/api';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    error: false,
    fields: createNewsletterSignupFormFields('modal').reduce((acc, { name }) => {
        acc[name] = {
            value: '',
            touched: false
        };

        return acc;
    }, {}),
    submitted: false,
    submittedSuccessfully: undefined
};

// Form Actions
export const SET_FORM_FIELD_VALUE = 'rfa-zoo-website/newsletter/SET_FORM_FIELD_VALUE';
export const TOUCHED_FORM_FIELD = 'rfa-zoo-website/newsletter/TOUCHED_FORM_FIELD';
export const SUBMIT_FORM = 'rfa-zoo-website/newsletter/SUBMIT_FORM';
export const FORM_SUCCESSFULLY_SUBMITTED = 'rfa-zoo-website/newsletter/FORM_SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT_FORM = 'rfa-zoo-website/newsletter/FAILED_TO_SUBMIT_FORM';
export const RESET_FORM = 'rfa-zoo-website/newsletter/RESET_FORM';

// Form Action Creators
export const setFormFieldValueAction = (fieldName, value) => ({
    type: SET_FORM_FIELD_VALUE,
    fieldName,
    value
});

export const touchedFormFieldAction = (fieldName) => ({
    type: TOUCHED_FORM_FIELD,
    fieldName
});

export const submitFormAction = (formData) => ({
    type: SUBMIT_FORM,
    formData
});

export const formSuccessfullySubmittedAction = () => ({
    type: FORM_SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitFormAction = () => ({
    type: FAILED_TO_SUBMIT_FORM
});

export const resetFormAction = () => ({
    type: RESET_FORM
});

// Form Reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FORM_FIELD_VALUE:
            return setFormFieldValue(state, action.fieldName, action.value);
        case TOUCHED_FORM_FIELD:
            return touchedFormField(state, action.fieldName);
        case SUBMIT_FORM:
            return submitForm(state);
        case FORM_SUCCESSFULLY_SUBMITTED:
            return formSuccessfullySubmitted(state);
        case FAILED_TO_SUBMIT_FORM:
            return failedToSubmitForm(state);
        case RESET_FORM:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Form Reducer Helpers
function setFormFieldValue(state, fieldName, value) {
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                value
            }
        }
    };
}

function touchedFormField(state, fieldName) {
    return {
        ...state,
        error: false,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                touched: true
            }
        }
    };
}

function submitForm(state) {
    return {
        ...state,
        error: false,
        submitted: true,
        submittedSuccessfully: undefined
    };
}

function formSuccessfullySubmitted(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: true
    };
}

function failedToSubmitForm(state) {
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: false,
        error: true
    };
}

// Epic creators

/**
 * @callback formSuccessfullySubmittedAction
 * @returns {object} action
 */

/**
 * @callback failedToSubmitFormAction
 * @returns {object} action
 */

/**
 * Creates newsletter form submission epic
 * @param {string} newsletterEndpoint - get in touch endpoint
 * @param {formSuccessfullySubmittedAction} formSuccessfullySubmittedAction - action creator
 * @param {failedToSubmitFormAction} failedToSubmitFormAction - action creator
 */
export const createFormSubmissionEpic = (newsletterEndpoint, formSuccessfullySubmittedAction, failedToSubmitFormAction) => {
    return (action$) => action$.pipe(
        ofType(SUBMIT_FORM),
        mergeMap(({ formData: { data, setIsLoading } }) => {
            /**
             Uncomment the following return statement to mock successful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(formSuccessfullySubmittedAction());
            //     }, 3000);
            // });

            /**
             Uncomment the following return statement to mock unsuccessful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(failedToSubmitFormAction());
            //     }, 3000);
            // });

            return (
                clientFetch(newsletterEndpoint, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    mode: 'no-cors',
                })
                    .then(() => formSuccessfullySubmittedAction())
                    .catch(() => failedToSubmitFormAction())
                    .finally(() => {
                        setIsLoading(false);
                    })
            );
        })
    );
};

export const createScheduleFormResettingEpic = (setTimeout, resetFormOnSuccessfullSubmissionDelay) => {
    return (action$) => action$.pipe(
        ofType(FORM_SUCCESSFULLY_SUBMITTED),
        mergeMap(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(resetFormAction());
                }, resetFormOnSuccessfullSubmissionDelay);
            });
        })
    );
};

// Epics
const formSubmissionEpic = createFormSubmissionEpic(
    ENDPOINTS.SUBSCRIBE,
    formSuccessfullySubmittedAction,
    failedToSubmitFormAction
);

export const epics = combineEpics(
    formSubmissionEpic
);
