import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Button extends Component {
    render() {
        const { title, url } = this.props;

        return (
            <section className="section || button-block">
                <div className="constrain-width large">
                    <div className="inner">
                        <div className="content">
                            <a className="button primary large" href={url}>{title}<Icon name="chevron" /></a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default Button;
