import { connect } from 'react-redux';
import Encounters from 'app/components/content-blocks/encounters';
import { fetchEncountersAction, fetchEncountersSuccessAction } from 'app/ducks/encounters';
import { selectEncoutners, selectWeekDay } from 'app/selectors/encounters';

const mapStateToProps = (state) => ({
    data: selectEncoutners(state),
    weekDay: selectWeekDay(state)
});

const mapDispatchToProps = (dispatch) => ({
    changeWeekDay: (weekDay) => dispatch(fetchEncountersAction(weekDay)),
    loadInitialData: (data) => dispatch(fetchEncountersSuccessAction('today', data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Encounters);
