/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import selectStatus from 'app/selectors/status';
import React, { Component } from 'react';

export default function(WrappedComponent) {
    class WrapperComponent extends Component {
        shouldComponentUpdate(nextProps) {
            const currentIsRouting = this.props.status.isRouting;
            const nextIsRouting = nextProps.status.isRouting;

            return currentIsRouting === nextIsRouting || !nextIsRouting;
        }

        render() {
            return <WrappedComponent {...this.props} {...this.state} />;
        }
    }

    function mapStateToProps(state) {
        return {
            status: selectStatus(state)
        };
    }

    function mapDispatchToProps() {
        return {};
    }

    WrapperComponent.propTypes = {
        status: PropTypes.object.isRequired
    };


    return connect(
        mapStateToProps,
        mapDispatchToProps,
        null,
        { pure: true }
    )(WrapperComponent);
}
