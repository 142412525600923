import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const AuthenticatedRoute = ({ children, redirectTo }) => {
    const location = useLocation();
    const { isAuth, isLoading } = useSelector((state) => state.auth);

    if (isLoading) return (
        <div className="loader-container">
            <Loader type="block" />
        </div>
    );

    return isAuth ? children : <Navigate to={redirectTo} state={{ from: location }} replace />;
};

AuthenticatedRoute.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    redirectTo: PropTypes.string.isRequired
};

export default AuthenticatedRoute;
