import Banner from 'app/components/partials/banner';
import defaultBannerImage from 'static/images/default-page-banner.jpg';
import Helmet from 'react-helmet';
import React from 'react';

export default function DonationThankYouPage() { // eslint-disable-line require-jsdoc
    return (
        <main role="main" className="main">
            <Helmet>
                <title>Thank you for donation</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="page || page-view">
                <Banner
                    title="Thank you!"
                    introduction="Thank you for working with us to continue to build a future for wildlife, your donation has been successful. Together, we’re making a real and tangible difference for wildlife, restoring wild places and helping communities."
                    images={[defaultBannerImage]}
                />
            </div>
        </main>
    );
}
