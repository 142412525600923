import Icon from 'app/components/partials/icon';
import React from 'react';

const Directions = () => (
    <section>
        <div className="directions">
            <div className="constrain-width large">
                <div className="sub-introduction-wrapper">
                    <div className="sub-introduction || highlighter">
                        <div className="inner">
                            <div className="content">
                                <Icon className="fixed-icon" name="pointer" />
                                <h4 className="title">Location</h4>
                                <p>Auckland Zoo is on Motions Road, just west of downtown Auckland and next to Western Springs Reserve.</p>
                                <p>
                                    <a className="button" href="https://www.google.co.nz/maps/place/Auckland+Zoo/@-36.864113,174.719685,15z/data=!4m2!3m1!1s0x0:0x56925ebc833ee8ee?sa=X&ved=0ahUKEwj_38C6pYrUAhUDH5QKHdlFDqMQ_BIIiAEwDg">
                                        How to get here <Icon name="chevron" />
                                    </a>
                                </p>

                                <p>For bus / public transport options to get to Auckland Zoo, visit the <a href="https://at.govt.nz/" rel="noopener noreferrer nofollow" target="_blank">Auckland Transport website</a>. If taking the bus, the closest stop is 8124 on Great North Road, opposite Motions Road (with a short walk down Motions Road to Auckland Zoo).</p>
                            </div>

                            <div className="content">
                                <Icon className="fixed-icon" name="parking" />
                                <h4 className="title">Parking</h4>
                                <div>
                                    <p>Auckland Zoo has two large car parks providing off-street parking for cars, charter buses and tour coaches. It costs $2/hour to park in the Zoo car parks, with a maximum charge of $8. Parking charges apply from 9am to 5pm every day.</p>
                                    <p>Accessible car parking is available in the Motions Road car park, closest to the main entrance to the Zoo, for vehicles displaying a current mobility parking permit. Street parking along Motions Road and Old Mill Road is free and, in some areas is time-restricted to 3 hours.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Directions;
