import * as Yup from 'yup';
import AuthCredentials from 'app/components/partials/portal/auth/credentials';
import BgImage from 'static/images/login-bg.jpg';
import { clientFetch } from 'app/utilities/fetch';
import DatePicker from 'app/components/partials/date-picker';
import { displayDateOfBirthFormat } from 'config/buy-memberships-passholder-details';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import { membershipPassholdersDateOfBirthValidator } from 'app/utilities/form-validation';
import { useFormik } from 'formik';
import { userLogoutAction } from 'app/ducks/auth';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ENDPOINTS, ERROR_MESSAGE, RESPONSE } from 'config/api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Extracts any redirect paths found in the URL query
 * @param {URLSearchParams} searchParams
 * @returns {string} empty string if no redirect, otherwise a URL query param with ? prepended
 */
function extractRedirectQuery(searchParams) {
    const redirectPath = searchParams.get('redirect');
    if (redirectPath) {
        const params = createSearchParams({ redirect: redirectPath });

        return `?${params.toString()}`;
    }

    return '';
}

const ResetPasswordPage = () => {
    const [isChecking, setIsCheking] = useState(true);
    const [verifyError, setVerifyError] = useState(null);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [needsDetails, setNeedsDetails] = useState(false);
    const isAuth = useSelector((state) => state.auth.isAuth);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const title = needsDetails ? 'Enter Your Details' : 'New Password';
    const heading = isChecking || verifyError ? 'Checking your email' : title;

    const getValidationSchema = () => {
        let schema = {
            password: Yup.string().required('This is a required field').min(8, 'Password must contain at least 8 characters'),
            confirmPassword:
                Yup
                    .string()
                    .required('This is a required field')
                    .min(8, 'Password must contain at least 8 characters')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
        };

        if (needsDetails) {
            schema = {
                ...schema,
                firstName: Yup.string().required('This is a required field').max(255),
                lastName: Yup.string().required('This is a required field').max(255),
                dob: Yup.string().required('This is a required field')
                    .test({
                        name: 'primary-account',
                        test(value, ctx) {
                            const { isValid, message } = membershipPassholdersDateOfBirthValidator('primaryAccountHolder', value);

                            if (!isValid) {
                                return ctx.createError({ message });
                            }

                            return true;
                        }
                    }),
            };
        }

        return Yup.object(schema);
    };

    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '', firstName: '', lastName: '', dob: '' },
        validationSchema: getValidationSchema(),
        onSubmit: async(values, { resetForm, setStatus, setSubmitting }) => {
            setStatus();
            const token = searchParams.get('token');
            const email = searchParams.get('email');
            const broker = searchParams.get('broker');
            let payload = {
                token,
                email,
                broker,
                password: values.password,
                // eslint-disable-next-line camelcase
                password_confirmation: values.confirmPassword
            };

            if (needsDetails) {
                payload = {
                    ...payload,
                    // eslint-disable-next-line camelcase
                    first_name: values.firstName,
                    // eslint-disable-next-line camelcase
                    last_name: values.lastName,
                    dob: values.dob,
                };
            }

            try {
                await clientFetch(ENDPOINTS.PORTAL_RESET_PASSWORD, {
                    method: 'POST',
                    body: JSON.stringify(payload)
                });

                const ctx = needsDetails
                    ? 'Your account details have been successfully updated'
                    : 'Your password has been successfully reset';
                const successMessage = `${ctx}. You will be redirected to login page, please use your new password to login.`;

                resetForm();
                setIsRedirecting(true);
                setStatus({ success: successMessage });
                if (isAuth) dispatch(userLogoutAction());
                setTimeout(() => {
                    // For Customer UX, reset links in emails may have a redirect to the final desired path (e.g. redeem membership page)
                    // If the link has a redirect path attached, pass it through to the login page
                    const query = extractRedirectQuery(searchParams);
                    navigate(`${FRONTEND_ROUTES.PORTAL_LOGIN}${query}`);
                }, 5000);
            } catch (err) {
                const { error, status } = err;

                const errorMessage =
                    status === RESPONSE.BAD_REQUEST &&
                    error &&
                    error.error &&
                    error.error.message || ERROR_MESSAGE.DEFAULT;

                setStatus({ error: errorMessage });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const verifyTokenAndEmail = async(token, email, broker = null) => {
        try {
            const res = await clientFetch(ENDPOINTS.PORTAL_VERIFY_TOKEN, {
                method: 'POST',
                body: JSON.stringify({ token, email, broker })
            });
            // As part of gifting redeem flow, new user needs to fill in name and date of birth
            setNeedsDetails(res.needs_setup);
        } catch (err) {
            const { error, status } = err || {};
            const errorMessage =
                status === RESPONSE.BAD_REQUEST &&
                error &&
                error.error &&
                error.error.message || ERROR_MESSAGE.DEFAULT;

            const errorCode = error && error.error && error.error.code || null;

            if (status === RESPONSE.BAD_REQUEST && errorCode && errorCode === 'link_expired') {
                navigate(FRONTEND_ROUTES.PORTAL_FORGOT_PASSWORD, { state: { tokenAndEmailVerifyError: errorMessage } });
            }

            setVerifyError(errorMessage);
        } finally {
            setIsCheking(false);
        }
    };

    const renderDetailFields = () => {
        return (
            <React.Fragment>
                <Input
                    label="First Name"
                    type="text"
                    className="input"
                    name="firstName"
                    id="firstName"
                    placeholder=""
                    value={formik.values.firstName}
                    onChangeHandler={formik.handleChange}
                    onBlurHandler={formik.handleBlur}
                    isRequired
                    error={formik.touched.firstName && formik.errors.firstName}
                />
                <Input
                    label="Last Name"
                    type="text"
                    className="input"
                    name="lastName"
                    id="lastName"
                    placeholder=""
                    value={formik.values.lastName}
                    onChangeHandler={formik.handleChange}
                    onBlurHandler={formik.handleBlur}
                    isRequired
                    error={formik.touched.lastName && formik.errors.lastName}
                />
                <DatePicker
                    label="Date of birth"
                    type="text"
                    className="input"
                    name="dob"
                    id="dob"
                    placeholder=""
                    displayDateFormat={displayDateOfBirthFormat}
                    value={formik.values.dob}
                    // handle change manually since our date picker doesn't emit a proper event
                    onChangeHandler={(e) => formik.setFieldValue('dob', e.target.value)}
                    onBlurHandler={formik.handleBlur}
                    isRequired
                    error={formik.touched.dob && formik.errors.dob}
                />
            </React.Fragment>
        );
    };

    const renderResetPasswordForm = () => (
        <form className="form || reset-password-form" onSubmit={formik.handleSubmit}>
            <div className="section">
                {/* only needed for gift receiver setting up user details */}
                {needsDetails && renderDetailFields()}
                <Input
                    label="New password"
                    type="password"
                    className="input"
                    name="password"
                    id="password"
                    placeholder=""
                    value={formik.values.password}
                    onChangeHandler={formik.handleChange}
                    onBlurHandler={formik.handleBlur}
                    isRequired
                    error={formik.touched.password && formik.errors.password}
                />
                <Input
                    label="Confirm new password"
                    type="password"
                    className="input"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder=""
                    value={formik.values.confirmPassword}
                    onChangeHandler={formik.handleChange}
                    onBlurHandler={formik.handleBlur}
                    isRequired
                    error={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
            </div>
            {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}
            {formik.status && formik.status.success && <div className="success-message">{formik.status.success}</div>}
            <button className="button primary || submit-btn" type="submit" disabled={formik.isSubmitting || isRedirecting}>
                {formik.isSubmitting || isRedirecting ? <Loader type="small" /> : 'Submit'}
            </button>
        </form>
    );

    useEffect(() => {
        const token = searchParams.get('token');
        const email = searchParams.get('email');
        const broker = searchParams.get('broker');

        verifyTokenAndEmail(token, email, broker);
    }, []);

    return (
        <AuthCredentials title={title} imgSrc={BgImage}>
            <h2 className="title || heading-2">{heading}</h2>
            {isChecking && <Loader />}
            {!isChecking && !verifyError && renderResetPasswordForm()}
            {!isChecking && verifyError && <div className="error-message">{verifyError}</div>}
        </AuthCredentials>
    );
};

export default ResetPasswordPage;
