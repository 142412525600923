import classnames from 'classnames';
import PropTypes from 'prop-types';
import Stackla from 'app/components/partials/stackla';
import React, { Component } from 'react';

class StacklaWidget extends Component {
    render() {
        const { hash, id, subtitle, title, isActive } = this.props;

        const stacklaClass = classnames('stackla-widget-block || constrain-width large', {
            'is-active': isActive
        });

        return (
            <section className={stacklaClass}>
                {title && <header className="section-header">
                    <div className="constrain-width large">
                        <div className="inner">
                            <div className="title-wrapper">
                                <h2 className="title || heading-1">{title}</h2>
                                {subtitle && <p className="subtitle">{subtitle}</p>}
                            </div>
                        </div>
                    </div>
                </header>}
                <Stackla
                    hash={hash}
                    id={id}
                    title={title}
                />
            </section>
        );
    }
}

const WIDGET_TYPES = ['masonry'];

StacklaWidget.defaultProps = {
    isActive: true,
    type: WIDGET_TYPES[0]
};

StacklaWidget.propTypes = {
    hash: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    // TODO: Include type for future variants/layout potentially
    type: PropTypes.oneOf(WIDGET_TYPES).isRequired
};

export default StacklaWidget;
