import { createValidationDataObject } from 'app/utilities/form-validation';
import validator from 'validator';

export const getFieldValidator = (fieldName) => DONATION_PAYMENT_FORM_FIELDS.find((field) => field.name === fieldName).validator;

export const getFieldData = (fieldName) => DONATION_PAYMENT_FORM_FIELDS.find((field) => field.name === fieldName);

const DONATION_PAYMENT_FORM_FIELDS = [
    {
        name: 'product_id',
        inputType: 'select',
        label: 'Choose your donation',
        placeholder: 'Please select',
        inputSize: 'large',
        isRequired: true,
        validator: (productId) => {
            if (productId) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'amount',
        inputType: 'input',
        label: 'Donation amount',
        type: 'number',
        placeholder: '$',
        inputSize: 'medium',
        isRequired: true,
        validator: (donationAmount) => {
            if (donationAmount) {
                if (validator.isInt(donationAmount)) {
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid donation amount');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'name',
        inputType: 'input',
        label: 'Name',
        type: 'text',
        placeholder: '',
        inputSize: 'medium',
        isRequired: true,
        validator: (name) => {
            if (name) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'email',
        inputType: 'input',
        label: 'Email',
        type: 'email',
        placeholder: '',
        inputSize: 'medium',
        isRequired: true,
        validator: (email) => {
            if (email) {
                if (validator.isEmail(email)) {
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'line1',
        inputType: 'input',
        label: 'Address line 1',
        type: 'text',
        placeholder: '',
        inputSize: 'large',
        isRequired: true,
        validator: (line1) => {
            if (line1) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'line2',
        inputType: 'input',
        label: 'Address line 2',
        type: 'text',
        placeholder: '',
        inputSize: 'large',
        isRequired: true,
        validator: (line2) => {
            if (line2) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'city',
        inputType: 'input',
        label: 'City',
        type: 'text',
        placeholder: '',
        inputSize: 'small',
        isRequired: true,
        validator: (city) => {
            if (city) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'post_code',
        inputType: 'input',
        label: 'Postcode',
        type: 'text',
        placeholder: '',
        inputSize: 'small',
        isRequired: true,
        validator: (postCode) => {
            if (postCode) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'country',
        inputType: 'input',
        label: 'Country',
        type: 'text',
        placeholder: '',
        inputSize: 'small',
        isRequired: true,
        validator: (country) => {
            if (country) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'card_number',
        inputType: 'input',
        label: 'Card number',
        type: 'number',
        placeholder: '',
        inputSize: 'large',
        isRequired: true,
        validator: (cardNumber) => {
            if (cardNumber) {
                if (validator.isInt(cardNumber)) {
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid card number');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'expire_month',
        inputType: 'input',
        label: 'Expiration date',
        type: 'number',
        placeholder: 'MM',
        inputSize: 'small',
        isRequired: true,
        validator: (expireMonth) => {
            if (expireMonth) {
                if (validator.isInt(expireMonth, {
                    min: 1,
                    max: 12
                })) {
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid month');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'expire_year',
        inputType: 'input',
        label: '',
        type: 'number',
        placeholder: 'YYYY',
        inputSize: 'small',
        isRequired: true,
        validator: (expireYear) => {
            if (expireYear) {
                if (!validator.isInt(expireYear) ||
                    !validator.isLength(expireYear, {
                        min: 4,
                        max: 4
                    })) {
                    return createValidationDataObject(false, 'Not a valid year');
                }

                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: 'cvc',
        inputType: 'input',
        label: 'Security code',
        type: 'number',
        placeholder: '',
        inputSize: 'small',
        isRequired: true,
        validator: (cvc) => {
            if (cvc) {
                if (!validator.isInt(cvc) ||
                    !validator.isLength(cvc, {
                        min: 3,
                        max: 3
                    })) {
                    return createValidationDataObject(false, 'Not a valid cvc');
                }

                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    }
];

export default DONATION_PAYMENT_FORM_FIELDS;
