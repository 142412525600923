/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Jotform extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const formId = this.props.form_id;

        const ifr = document.getElementById(`JotFormIFrame-${formId}`);
        if (window.location.href && window.location.href.indexOf('?') > -1) {
            const get = window.location.href.substr(window.location.href.indexOf('?') + 1);
            if (ifr && get.length > 0) {
                let src = ifr.src;
                src = src.indexOf('?') > -1 ? src + '&' + get : src + '?' + get; // eslint-disable-line prefer-template
                ifr.src = src;
            }
        }
        function handleIFrameMessage(e) { // eslint-disable-line require-jsdoc
            if (typeof e.data === 'object') {
                return;
            }
            const args = e.data.split(':');
            let iframe;
            if (args.length > 2) { // eslint-disable-line no-magic-numbers
                iframe = document.getElementById('JotFormIFrame-' + args[(args.length - 1)]); // eslint-disable-line prefer-template
            } else {
                iframe = document.getElementById('JotFormIFrame');
            }
            if (!iframe) {
                return;
            }
            switch (args[0]) { // eslint-disable-line default-case
                case 'scrollIntoView':
                    iframe.scrollIntoView();
                    break;
                case 'setHeight':
                    iframe.style.height = args[1] + 'px'; // eslint-disable-line prefer-template
                    break;
                case 'collapseErrorPage':
                    if (iframe.clientHeight > window.innerHeight) {
                        iframe.style.height = window.innerHeight + 'px'; // eslint-disable-line prefer-template
                    }
                    break;
                case 'reloadPage':
                    window.location.reload();
                    break;
                case 'loadScript': // eslint-disable-line no-case-declarations
                    let src = args[1];
                    if (args.length > 3) { // eslint-disable-line no-magic-numbers
                        src = args[1] + ':' + args[2]; // eslint-disable-line prefer-template
                    }
                    const script = document.createElement('script');
                    script.src = src;
                    script.type = 'text/javascript';
                    document.body.appendChild(script);
                    break;
                case 'exitFullscreen':
                    if (window.document.exitFullscreen)
                        window.document.exitFullscreen();
                    else if (window.document.mozCancelFullScreen)
                        window.document.mozCancelFullScreen();
                    else if (window.document.mozCancelFullscreen)
                        window.document.mozCancelFullScreen();
                    else if (window.document.webkitExitFullscreen)
                        window.document.webkitExitFullscreen();
                    else if (window.document.msExitFullscreen)
                        window.document.msExitFullscreen();
                    break;
            }
            const isJotForm = (e.origin.indexOf('jotform') > -1) ? true : false; // eslint-disable-line no-unneeded-ternary
            if (isJotForm && 'contentWindow' in iframe && 'postMessage' in iframe.contentWindow) {
                const urls = {
                    docurl: encodeURIComponent(document.URL),
                    referrer: encodeURIComponent(document.referrer)
                };
                iframe.contentWindow.postMessage(JSON.stringify({
                    type: 'urls',
                    value: urls
                }), '*');
            }
        }
        if (window.addEventListener) {
            window.addEventListener('message', handleIFrameMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent('onmessage', handleIFrameMessage);
        }
    }

    render() {
        const { form_id: formId } = this.props;

        return (
            <section className="section">
                <iframe
                    id={`JotFormIFrame-${formId}`}
                    title="Auckland Zoo Contact Us"
                    allowtransparency="true"
                    allowFullScreen
                    allow="geolocation; microphone; camera"
                    src={`https://aucklandunlimited.jotform.com/${formId}`}
                    frameBorder="0"
                    style={{
                        width: 1,
                        minWidth: '100%',
                        height: 539,
                        border: 'none'
                    }}
                    scrolling="no"
                />
            </section>
        );
    }
}

Jotform.propTypes = {
    form_id: PropTypes.string.isRequired
};

export default Jotform;
