import Loader from '../partials/loader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Audio extends Component {
    render() {
        const { soundCloudTrackID } = this.props; // e.g 486817179

        return (
            <section className="section || text-block || audio-block">
                <div className="constrain-width large">
                    <div className="inner">
                        <div className="content">
                            <div className="constrain-content">

                                <div className="sound-cloud-player">
                                    <Loader />
                                    <iframe
                                        title="SoundCloud Player"
                                        width="100%"
                                        height="166"
                                        scrolling="no"
                                        frameBorder="no"
                                        allow="autoplay"
                                        src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${soundCloudTrackID}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Audio.propTypes = {
    soundCloudTrackID: PropTypes.string.isRequired
};

export default Audio;
