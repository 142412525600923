/* eslint-disable require-jsdoc */

export function selectIsBusy({ search }) {
    const { cache, keywords } = search;

    return !cache.hasOwnProperty(keywords);
}

export function selectSearchKeywords({ search }) {
    return search.keywords;
}

export function selectSearchResults({ search }) {
    const { cache, keywords } = search;

    if (!keywords) {
        return { count: 0, results: [] };
    }

    if (cache.hasOwnProperty(keywords)) {
        return cache[keywords];
    }

    return { count: 0, results: [] };
}

export function selectSearchDialogIsActive({ search }) {
    return search.searchDialogIsActive;
}
