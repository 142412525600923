import ArticleTile from 'app/components/partials/article-tile';
import FeaturedArticleTile from 'app/components/partials/featured-article-tile';
import Loader from 'app/components/partials/loader';
import { NEWS } from 'config/news';
import PropTypes from 'prop-types';
import React from 'react';
import TileHighlight from 'app/components/partials/tile-highlight';

const FUAX_TIMEOUT = 1000;

class NewsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isClientLoading: false,
            page: 1
        };
    }

    componentDidMount() {
        // Only make initial call hasn't been made already
        if (!Array.isArray(this.props.items)) {
            // Fuax loading time for user
            this.setState({ isClientLoading: true }, () => {
                setTimeout(() => {
                    this.props.loadData(1);
                }, FUAX_TIMEOUT);
            });
        }
    }

    UNSAFE_componentWillReceiveProps({ isBusy }) {
        if (!isBusy) {
            this.setState({
                isClientLoading: isBusy
            });
        }
    }


    // Handler
    handleLoadMore() {
        const { isClientLoading, page } = this.state;

        if (isClientLoading) return;

        // Add local loading state
        this.setState({ isClientLoading: true }, () => {
            setTimeout(() => {
                const requestPage = page + 1;

                this.props.loadData(requestPage);

                this.setState({
                    isClientLoading: false,
                    page: requestPage
                });
            }, FUAX_TIMEOUT);
        });
    }


    // Render
    render() {
        const { items, featuredItem } = this.props;

        // If featuredItem set pass
        const combinedItems = featuredItem ? [featuredItem, ...items] : items;

        // Get latest items ignoring featured
        const lastestItems = [];
        if (combinedItems) {
            combinedItems.slice(1, combinedItems.length).map((item) => {
                if (featuredItem && item.id.toString() === featuredItem.id.toString()) return null;

                lastestItems.push(item);
            });
        }

        const displayThree = lastestItems.length >= NEWS.INTRO_LIMIT;

        return (
            <section className={`news-list ${combinedItems ? '' : 'is-empty'}`}>
                <div className="constrain-width large">
                    <div className="news-list-inner">

                        {!combinedItems &&
                            <div className="news-list-actions"><Loader /></div>
                        }

                        {/* Header */}
                        {combinedItems &&
                            <header className="news-header">
                                <div className="tile-grid">
                                    {combinedItems && <FeaturedArticleTile item={combinedItems[0]} />}
                                    <div className="tile-grid-sidebar">
                                        <div className="tile-grid-sidebar-inner">
                                            <div className="tile-grid-sidebar-header">
                                                <h3>Latest at the Zoo</h3>
                                            </div>
                                            <div className={`news-highlight ${displayThree ? '' : 'has-articles'}`}>
                                                {lastestItems.length > 0 && lastestItems.map((item, index) => {
                                                    // Check original items length
                                                    if (displayThree) {
                                                        return <TileHighlight key={index} item={item} />;
                                                    }

                                                    return <ArticleTile key={index} item={item} />;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        }

                    </div>
                </div>
            </section>
        );
    }
}

NewsList.propTypes = {
    items: PropTypes.array,
    isBusy: PropTypes.bool.isRequired,
    featuredItem: PropTypes.object,
    loadData: PropTypes.func.isRequired,
    resultCount: PropTypes.number
};

export default NewsList;
