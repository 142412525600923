/* eslint-disable camelcase */
import moment from 'moment';
import {
    createValidationDataObject,
    isEmailValid,
    isValueRequiredValid,
    membershipPassholdersDateOfBirthValidator
} from 'app/utilities/form-validation';

export const dateOfBirthFormat = 'YYYY-MM-DD';
export const displayDateOfBirthFormat = 'DD/MM/YYYY';

const generateFormFieldsDetail = (productType) => ([
    {
        name: 'first_name',
        label: 'First name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (first_name) => {
            if (!isValueRequiredValid(first_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'last_name',
        label: 'Last name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (last_name) => {
            if (!isValueRequiredValid(last_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'dob',
        label: 'Date of birth',
        rezdyLabel: 'Date of birth',
        placeholder: displayDateOfBirthFormat.toLowerCase(),
        type: 'date',
        defaultValue: '',
        isRequired: true,
        validator: (dob) => {
            if (!isValueRequiredValid(dob)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            if (dob) {
                const date = moment(dob, dateOfBirthFormat, true);

                if (!date.isValid()) {
                    return createValidationDataObject(false, `Invalid date format: e.g. ${displayDateOfBirthFormat.toLowerCase()}`);
                }

                const { isValid, message } = membershipPassholdersDateOfBirthValidator(productType, date);

                if (!isValid) {
                    return createValidationDataObject(false, message);
                }

                return createValidationDataObject();
            }

            return createValidationDataObject();
        }
    },
    // skipping email field based on dev task dev-1677.
    // keep code in just in case we need to collect in the future.
    ...productType !== 'cub' && productType !== 'child' && productType !== 'adult' && productType !== 'senior' && productType !== 'student' ? [{
        name: 'email',
        label: 'Email',
        placeholder: '',
        type: 'email',
        defaultValue: '',
        isRequired: false,
        validator: (email) => {
            if (email && !isEmailValid(email)) {
                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject();
        }
    }] : []
]);

export const createFormFieldsDetail = (productType) => generateFormFieldsDetail(productType.toLowerCase());
