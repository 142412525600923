import PropTypes from 'prop-types';
import { REZDY_ENV_URL } from '../../../config/rezdy';
import React, { Component } from 'react';

class Rezdy extends Component { // eslint-disable-line require-jsdoc
    constructor(props) {
        super(props);

        this.iframe = null;
        this.scriptTag = null;
    }

    componentWillUnmount() {
        if (this.scriptTag) {
            this.scriptTag.remove();
        }
    }

    componentDidMount() {
        const vm = this;

        // Add Rezdy API script
        this.scriptTag = document.createElement('script');

        this.scriptTag.src = `${REZDY_ENV_URL}/pluginJs`;
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(this.scriptTag, firstScriptTag);

        function check_ga() { // eslint-disable-line
            if (typeof ga === 'function') {
                ga(function(tracker) { // eslint-disable-line
                // When using Google Tag Manager there is no default tracker
                    if (tracker === undefined) {
                        tracker = ga.getAll()[0]; // eslint-disable-line
                    }

                    // Gets the client ID of the default tracker.
                    const gaClientId = tracker.get('clientId');

                    vm.iframe.addEventListener('load', function() {
                        this.contentWindow.postMessage(gaClientId, `${REZDY_ENV_URL}`);
                    });
                });
            } else {
                setTimeout(function() { check_ga(); }, 500); // eslint-disable-line
            }
        }
        check_ga();
    }

    render() {
        const { url, title } = this.props;

        return (
            <div className="rezdy-block || section || constrain-width large">
                <div className="rezdy-block-inner">
                    <iframe
                        ref={(iframe) => { this.iframe = iframe; }}
                        title={title}
                        seamless=""
                        width="100%"
                        height="1000px"
                        frameBorder="0"
                        className="rezdy"
                        src={url}>
                    </iframe>
                </div>
            </div>
        );
    }
}

Rezdy.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default Rezdy;
