import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const SLIDE_ROTATION_DURATION = 5000;

class Carousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        this.autoRotate = null;
    }

    getInitialState() {
        return {
            currentItem: 0,
            autoRotate: true
        };
    }


    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.imageCarousel && this.captionCarousel && nextProps.items !== this.props.items) {
            this.resetCarousel();
        }
    }

    componentDidMount() {
        if (this.props.items.length > 1) {
            this.startAutoRotate();
        }
    }

    componentWillUnmount() {
        this.stopAutoRotate();
    }


    // Helper
    resetCarousel() {
        this.imageCarousel.slickGoTo(0);
        this.captionCarousel.slickGoTo(0);
        this.setState(this.getInitialState);
    }

    isNextDisabled() {
        if (this.state.currentItem >= this.props.items.length - 1) {
            return true;
        }

        return false;
    }

    isPreviousDisabled() {
        if (this.state.currentItem <= 0) {
            return true;
        }

        return false;
    }

    startAutoRotate() {
        this.autoRotate = setInterval(() => {
            const shouldRotate = this.state.autoRotate;
            const isLastSlide = (this.state.currentItem + 1 === this.props.items.length);

            if (shouldRotate && isLastSlide) {
                this.resetCarousel();
            } else if (shouldRotate) {
                this.handleNext();
            } else {
                this.stopAutoRotate();
            }
        }, SLIDE_ROTATION_DURATION);
    }

    stopAutoRotate() {
        clearInterval(this.autoRotate);
    }


    // Handler
    handleNext() {
        if (this.isNextDisabled()) {
            return false;
        }

        this.captionCarousel.slickNext();
        this.imageCarousel.slickNext();

        this.setState({ currentItem: this.state.currentItem + 1 });
    }

    handlePrevious() {
        if (this.isPreviousDisabled()) {
            return false;
        }

        this.captionCarousel.slickPrev();
        this.imageCarousel.slickPrev();

        this.setState({ currentItem: this.state.currentItem - 1 });
    }


    // Render
    render() {
        const { items } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <section
                role="button"
                tabIndex="0"
                className="carousel-block || constrain-width large"
                onClick={this.stopAutoRotate.bind(this)}
            >
                <div className="carousel">
                    <Slider className="image-carousel" ref={(imageCarousel) => { this.imageCarousel = imageCarousel; }} {...IMAGE_CAROUSEL_CONFIG}>
                        {items.map(({ file, link, title }, index) => {
                            return (
                                <div key={index}>
                                    <a className="carousel-item || preserve-image-ratio" href={link} style={{ backgroundImage: `url(${file})` }}>
                                        <img className="fixed-image" src={file} alt={title} />
                                    </a>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                <div className="caption-carousel overlaid">
                    <div className="constrain-width">
                        <div className="inner">
                            {items.length > 1 &&
                                <button className={`change-slide previous ${this.isPreviousDisabled() ? 'is-disabled' : ''}`} onClick={this.handlePrevious.bind(this)}>
                                    <Icon name="chevron" title="Previous" />
                                </button>
                            }
                            {items.length > 1 &&
                                <button className={`change-slide next ${this.isNextDisabled() ? 'is-disabled' : ''}`} onClick={this.handleNext.bind(this)}>
                                    <Icon name="chevron" title="Next" />
                                </button>
                            }
                            <div className="wrapper">
                                <Slider ref={(captionCarousel) => { this.captionCarousel = captionCarousel; }} {...IMAGE_CAROUSEL_CONFIG}>
                                    {items.map(({ subtitle, link, title }, index) => {
                                        return (
                                            <div key={index} className="carousel-item">
                                                <h3><a tabIndex="-1" href={link}>{title}</a></h3>
                                                <p>{subtitle}</p>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Carousel.propTypes = {
    items: PropTypes.array.isRequired
};

const IMAGE_CAROUSEL_CONFIG = {
    accessibility: false, // We are using native keyboard navigation instead of arrows
    adaptiveHeight: false,
    dots: false,
    infinite: false,
    pauseOnHover: true,
    speed: 600, // Set in CSS
    swipe: false,
    usecSS: true,
    arrows: false
};

export default Carousel;
