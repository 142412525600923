import { createValidationDataObject } from 'app/utilities/form-validation';
import DatePicker from 'app/components/partials/date-picker';
import { displayDateOfBirthFormat } from 'config/buy-memberships-passholder-details';
import { generatePassholdersFormData } from 'app/utilities/buy-memberships';
import Input from 'app/components/partials/input';
import PropTypes from 'prop-types';
import React from 'react';
import { setPassHoldersFieldValueAction, touchedPassHoldersFieldAction } from 'app/ducks/buy-memberships';
import { useDispatch, useSelector } from 'react-redux';

const MemberDetails = ({ stepClickHandler }) => {
    const dispatch = useDispatch();
    const { currentStepIndex, productsSelected } = useSelector((state) => state.buyMemberships);
    const passHolders = useSelector((state) => state.buyMemberships.passHolders);

    const passHoldersForm = generatePassholdersFormData(productsSelected);

    const getFieldValueAndTouched = (product, passIndexByProduct, fieldName) => {
        const pass = passHolders
            .find((pass) => pass.product === product && pass.passIndexByProduct === passIndexByProduct);

        return pass.formFields.find((field) => field.fieldName === fieldName);
    };

    const validateForm = () => {
        const invalid = passHoldersForm.some(({ product, formFieldsGroup }) => {
            return formFieldsGroup.some((group, index) => {
                const passIndexByProduct = index;

                return group.some(({ name, validator }) => !validator(getFieldValueAndTouched(product, passIndexByProduct, name).value).valid);
            });
        }, []);

        return invalid;
    };

    const renderFormField = (
        FIELD,
        setValue,
        setTouched,
        product,
        passIndexByProduct
    ) => {
        const { name: fieldName, label, validator, type, placeholder, isRequired } = FIELD;
        const { value, touched } = getFieldValueAndTouched(product, passIndexByProduct, fieldName);

        // Display error if touched or there is a filled value
        const validationData = touched || value ? validator(value) : createValidationDataObject();
        const formFieldId = `${product}-${passIndexByProduct}-${fieldName}`;

        const fieldData = {
            key: formFieldId,
            id: formFieldId,
            name: fieldName,
            label,
            type,
            placeholder,
            value,
            onChangeHandler: (event) => setValue(fieldName, event.target.value),
            onBlurHandler: () => {
                if (!touched) {
                    setTouched(fieldName);
                }
            },
            isRequired,
            error: validationData.valid ? '' : validationData.message
        };

        return type === 'date' ? <DatePicker { ...fieldData } displayDateFormat={displayDateOfBirthFormat} disableFutureDates disableToday /> : <Input { ...fieldData } />;
    };

    const renderSecionByProduct = (
        product,
        description,
        formFieldsGroup
    ) => {
        return formFieldsGroup.map((formFields, passIndexByProduct) => {
            const setValue = (fieldName, value) => dispatch(setPassHoldersFieldValueAction(product, passIndexByProduct, fieldName, value));
            const setTouched = (fieldName) => dispatch(touchedPassHoldersFieldAction(product, passIndexByProduct, fieldName));

            return (
                <div className="section" key={passIndexByProduct}>
                    <h4 className="title with-tail">
                        {`${product} ${passIndexByProduct + 1}`}
                        <small>{description}</small>
                    </h4>
                    <div className="form-group">
                        {formFields.map((field) =>
                            renderFormField(field, setValue, setTouched, product, passIndexByProduct)
                        )}
                    </div>
                </div>
            );
        });
    };


    return (
        <div className="inner">
            <div className="constrain-width medium no-pad">
                <form className="pass-details-form">
                    {passHoldersForm.map(({ product, description, formFieldsGroup }) => renderSecionByProduct(product, description, formFieldsGroup))}
                </form>

                <section className="section button-group">
                    <button
                        className="button"
                        onClick={() => stepClickHandler(currentStepIndex - 1)}
                    >Back</button>
                    <button
                        className="button primary"
                        onClick={() => stepClickHandler(currentStepIndex + 1)}
                        disabled={validateForm()}
                    >
                        Next
                    </button>
                </section>
            </div>
        </div>
    );
};

MemberDetails.propTypes = {
    stepClickHandler: PropTypes.func.isRequired
};

export default MemberDetails;
