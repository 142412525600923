import PropTypes from 'prop-types';
import React from 'react';
import TileGrid from 'app/components/partials/tile-grid';

class ContentSet extends React.Component {
    render() {
        const { items, title } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <section className="tile-block">
                { title &&
                <header className="section-header">
                    <div className="constrain-width large">
                        <div className="inner">
                            <div className="title-wrapper">
                                <h2 className="title || heading-1">{title}</h2>
                            </div>
                        </div>
                    </div>
                </header>
                }
                <div className="constrain-width large">
                    <TileGrid items={items} />
                </div>
            </section>
        );
    }
}

ContentSet.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string
};

export default ContentSet;
