import classnames from 'classnames';
import { NAVIGATION_ITEMS } from 'config/members-portal';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useDispatch } from 'react-redux';
import { userLogoutAction } from 'app/ducks/auth';

const Navigation = () => {
    const dispatch = useDispatch();

    return (
        <>
            {NAVIGATION_ITEMS.map(({ label, link }, index) => (
                <NavLink
                    className={({ isActive }) => classnames('nav-link', { 'is-active': isActive })}
                    to={link}
                    key={index}
                >
                    {label}
                </NavLink>
            ))}
            <button className="nav-link" onClick={() => dispatch(userLogoutAction())}>
                Logout
            </button>
        </>
    );
};

export default Navigation;
