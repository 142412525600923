/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import SearchDialog from './search-dialog';
import {
    initiateSearchResultFetchAction,
    toggleSearchDialogAction
} from 'app/ducks/search';
import {
    selectIsBusy,
    selectSearchDialogIsActive,
    selectSearchKeywords,
    selectSearchResults
} from 'app/selectors/search';

function mapStateToProps(state) {
    return {
        isBusy: selectIsBusy(state),
        searchDialogIsActive: selectSearchDialogIsActive(state),
        searchKeywords: selectSearchKeywords(state),
        searchResults: selectSearchResults(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initiateSearch: (keywords, page) => dispatch(initiateSearchResultFetchAction(keywords, page)),
        toggleSearchDialog: () => dispatch(toggleSearchDialogAction())
    };
}

const VisibleHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchDialog);

export default VisibleHeader;
