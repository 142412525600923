import { processTime, processWeekday } from 'app/utilities/date-time';

/**
 * Format the encounter object data
 *
 * @param  {object} data with the attributtes
 *
 * @return {object} data re-formatted
 */
export function encounter(data) {
    const {
        link,
        name,
        thumbnail,
        time
    } = data;

    const { parent, slug, type } = JSON.parse(link);

    return {
        time: processTime(time),
        title: name,
        image: thumbnail,
        linkedItem: {
            type,
            data: {
                parent,
                slug
            }
        }
    };
}

/**
 * Format the entity type
 *
 * @param  {string} type currently stored as plural from CMS
 *
 * @return {string} type re-formatted as single
 */
export function entityType(type) {
    switch (type) {
        case 'animals':
            return 'animal';
        case 'encounters':
            return 'encounter';
        case 'pages':
            return 'page';
        default:
            return type;
    }
}

/**
 * Format the search object data
 *
 * @param  {object} data with the attributtes
 *
 * @return {object} data re-formatted
 */
export function searchResult(data) {
    const type = entityType(data.type);
    const {
        link,
        name,
        parent,
        slug,
        thumbnail
    } = data.attributes;

    // Display encounters slightly differently
    if (type === 'encounter') {
        const {
            slug: linkSlug,
            parent: linkParent,
            type: linkType
        } = JSON.parse(link);

        const { time, week_day: weekday } = data.attributes;

        return {
            type: `${type} – ${processWeekday(weekday)} ${processTime(time)}`,
            title: name,
            image: thumbnail,
            linkedItem: {
                type: linkType,
                data: {
                    parent: linkParent,
                    slug: linkSlug
                }
            }
        };
    }

    return {
        type: type,
        title: name,
        image: thumbnail,
        linkedItem: {
            type,
            data: {
                parent,
                slug
            }
        }
    };
}

export const JSON_SPACE = 4;

export function getJsonStr(jsonObject) {
    return JSON.stringify(jsonObject, null, JSON_SPACE);
}