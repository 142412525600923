import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class StacklaGoConnect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };
    }

    setModalVisibility(visible) {
        this.setState({ showModal: visible });
    }

    render() {
        const { widgetId } = this.props;
        const { showModal } = this.state;

        return (
            <div>
                <button className="button primary" onClick={this.setModalVisibility.bind(this, true)}>Upload here</button>
                <Modal isVisible={showModal} onCloseHandler={() => this.setModalVisibility(false)}>
                    <div id="stackla-goconnect-widget" data-widget-id={widgetId}></div>
                </Modal>
            </div>
        );
    }
}

StacklaGoConnect.propTypes = {
    widgetId: PropTypes.string.isRequired
};

export default StacklaGoConnect;
