import { clientFetch } from 'app/utilities/fetch';
import { useEffect, useState } from 'react';

const useProtectedFetch = (url, options = { method: 'GET' }) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async() => {
        try {
            const response = await clientFetch(url, options, true);
            setData(response);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!url) return;

        fetchData();
    }, [url]);

    return {
        data,
        isLoading,
        error
    };
};

export default useProtectedFetch;
