import * as format from 'app/utilities/format';
import _ from 'lodash';
import classnames from 'classnames';
import Icon from './icon';
import Loader from './loader';
import PropTypes from 'prop-types';
import SearchResult from './search-result.container';
import updateBody from 'app/utilities/update-body';
import React, { useEffect, useRef, useState } from 'react';

const SearchDialog = ({ initiateSearch, isBusy, searchDialogIsActive, searchKeywords, searchResults, toggleSearchDialog }) => {
    const [searchKeywordsReady, setSearchKeywordsReady] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [inputKeywords, setInputKeywords] = useState('');

    const searchInput = useRef(null);

    const TYPE_DELAY = 750;
    const loadMoreItemCount = 12;

    const searchClass = classnames('animate-fade-up || search-dialog', {
        'is-active': searchDialogIsActive,
        'is-loading': isBusy
    });

    const searchResultsClass = classnames('animate-fade-up || search-results', {
        'is-faux-hidden': isBusy
    });

    // Helpers
    const focusSearchInput = () => {
        searchInput.current.focus();
    };

    const debounceSearchInput = (target) => {
        clearTimeout(searchKeywordsReady);
        setSearchKeywordsReady(_.delay(() => initiateSearch(target, 1), TYPE_DELAY));
    };

    // Handler
    const handleChange = (event) => {
        setInputKeywords(event.target.value);
        setCurrentPage(1);
        debounceSearchInput(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const loadMoreBtnClickHandler = () => {
        const nextPage = currentPage + 1;
        initiateSearch(inputKeywords, nextPage);
        setCurrentPage(nextPage);
    };

    // Render
    const renderSearchResultCount = () => {
        const { results = [], count = 0 } = searchResults;

        if (count === 0 || results.length === 0) {
            return (
                <p className="animate-fade-up">
                    Your search has no results! Try using a different keyword.
                </p>
            );
        }

        return (
            <p className="animate-fade-up">
                We found {count > 1 ? `${count} results` : `${count} result`}
            </p>
        );
    };

    const renderLoaderOrSearchResultCount = () => {
        if (isBusy) return <Loader type="block" />;

        return renderSearchResultCount();
    };

    const renderSearchItems = () => {
        const resultsToRender  = searchResults.results.slice(0, currentPage * loadMoreItemCount);

        return (
            <section className={searchResultsClass}>
                <div className="search-results-inner" role="group">
                    {resultsToRender.map((item, index) => {
                        if (index <= currentPage * loadMoreItemCount) {
                            const itemData = format.searchResult(item);

                            return <SearchResult key={index} {...itemData} />;
                        }

                        return;
                    })}
                </div>
            </section>
        );
    };

    const renderLoadMoreBtn = () => {
        const { results, count } = searchResults || {};
        const pagesToRender = Math.ceil(count / loadMoreItemCount);
        // Render the button when there are more items to display && keywords not empty && initiate loading is done
        const shouldRenderBtn = inputKeywords && !isBusy && currentPage <= pagesToRender;
        // Set the button loading state when there is no data of the next page in redux
        const isBtnLoading = currentPage > 1 && results.length === (currentPage - 1) * loadMoreItemCount;
        // Hide the button when all the items have been displayed
        const isBtnHide = results.length === count;

        return shouldRenderBtn && (
            <button
                className={`button primary centered || ${isBtnHide ? 'is-hide' : ''}`}
                disabled={isBtnLoading}
                onClick={loadMoreBtnClickHandler}
            >
                {isBtnLoading ? (<Loader type="small" />) : 'Load more'}
            </button>
        );
    };

    useEffect(() => {
        if (searchDialogIsActive) {
            updateBody(true);

            // Focus cursor on search input after user clicks on search icon
            focusSearchInput();
        } else {
            updateBody(false);
        }
    }, [searchDialogIsActive]);

    return (
        // TODO: Consider focus trap wrapper
        <aside role="dialog" className={searchClass}>
            <div className="constrain-width large">
                <div className="search-dialog-actions">
                    <button className="search-dialog-close" type="button" onClick={toggleSearchDialog}>
                        <Icon name="close" title="Close dialog" />
                    </button>
                </div>
            </div>
            <div className="constrain-width">
                <div className="search-dialog-inner">
                    <form className="search-header" onSubmit={handleSubmit}>
                        <label className="search-header-title || heading-5" htmlFor="serch-input">Type in your search and press enter</label>
                        <input
                            className="search-header-input || heading-1"
                            id="search-input"
                            placeholder="Search..."
                            type="text"
                            onChange={handleChange}
                            defaultValue={searchKeywords}
                            name="q"
                            ref={searchInput}
                        />
                        {Boolean(searchKeywords) && renderLoaderOrSearchResultCount()}
                    </form>
                    {Boolean(searchResults.results.length) && renderSearchItems()}
                    {renderLoadMoreBtn()}
                </div>
            </div>
        </aside>
    );
};

SearchDialog.propTypes = {
    initiateSearch: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    searchDialogIsActive: PropTypes.bool.isRequired,
    searchKeywords: PropTypes.string.isRequired,
    searchResults: PropTypes.shape({
        count: PropTypes.number.isRequired,
        results: PropTypes.array.isRequired,
    }).isRequired,
    toggleSearchDialog: PropTypes.func.isRequired
};

export default SearchDialog;
