/* eslint-disable require-jsdoc */
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    cache: {},
    keywords: '',
    searchDialogIsActive: false
};

// Actions
export const TOGGLE_SEARCH_DIALOG = 'rfa-zoo-website/search/TOGGLE_SEARCH_DIALOG';
export const INITIATE_SEARCH_RESULT_FETCH = 'rfa-zoo-website/search/INITIATE_SEARCH_RESULT_FETCH';
export const SEARCH_RESULT_FETCH_SUCCESS = 'rfa-zoo-website/search/SEARCH_RESULT_FETCH_SUCCESS';

// Action Creators
export const toggleSearchDialogAction = () => ({
    type: TOGGLE_SEARCH_DIALOG
});

export const initiateSearchResultFetchAction = (keywords, page) => ({
    type: INITIATE_SEARCH_RESULT_FETCH,
    keywords,
    page
});

export const searchResultFetchSuccessAction = (keywords, searchResults, resultCount, page) => ({
    type: SEARCH_RESULT_FETCH_SUCCESS,
    payload: {
        keywords,
        searchResults,
        resultCount,
        page
    }
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_SEARCH_DIALOG:
            return toggleSearchDialog(state);
        case INITIATE_SEARCH_RESULT_FETCH:
            return initiateSearchResultFetch(state, action.keywords);
        case SEARCH_RESULT_FETCH_SUCCESS:
            return searchResultFetchSuccess(state, action.payload);
        default:
            return state;
    }
};

function toggleSearchDialog(state) {
    return {
        ...state,
        searchDialogIsActive: !state.searchDialogIsActive
    };
}

function initiateSearchResultFetch(state, keywords) {
    return {
        ...state,
        keywords
    };
}

function searchResultFetchSuccess(state, { keywords, searchResults, resultCount, page }) {
    return {
        ...state,
        cache: {
            ...state.cache,
            [keywords]: {
                count: resultCount,
                results: state.cache[keywords] && page !== 1 ?
                    [...state.cache[keywords].results, ...searchResults] : searchResults
            }
        }
    };
}

// Epic creator
export const createSearchEpic = (searchEndpoint, formatSearchResults, searchResultFetchSuccessAction) => {
    return (action$) => action$.pipe(
        ofType(INITIATE_SEARCH_RESULT_FETCH),
        mergeMap(({ keywords, page }) => {
            return (
                fetch(searchEndpoint(keywords, page))
                    .then((response) => {
                        // eslint-disable-next-line camelcase
                        const { meta: { result_count: resultCount } = { result_count: 0 } } = response || {};

                        return searchResultFetchSuccessAction(keywords, formatSearchResults(response), resultCount, page);
                    })
                    .catch(() => {
                    // TODO: Add proper error handling
                        return searchResultFetchSuccessAction(keywords, [], 0, 1);
                    })
            );
        })
    );
};

// Epics
const searchEpic = createSearchEpic(
    ENDPOINTS.SEARCH,
    formatSearchResults,
    searchResultFetchSuccessAction
);

// Helpers
function formatSearchResults(response) {
    if (response && response.data && response.data.length) {
        return response.data;
    }

    return [];
}

export const epics = combineEpics(searchEpic);
