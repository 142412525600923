import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import updateBody from 'app/utilities/update-body';
import React, { useEffect } from 'react';

const Modal = ({ children, isVisible, onCloseHandler, isCustom = false }) => {
    const onClose = () => {
        onCloseHandler();
        updateBody(false);
    };

    const handleKeyUp = (e) => {
        // Handles "Esc" keypress, calling the onClose method.
        // TODO: fix bug the ESC key doesn't work after we use it once to close the modal
        const keys = {
            27: () => {
                e.preventDefault();
                onClose();
                window.removeEventListener('keyup', handleKeyUp, false);
            },
        };

        if (keys[e.keyCode]) { keys[e.keyCode](); }
    };

    useEffect(() => {
        updateBody(isVisible);
    }, [isVisible]);

    useEffect(() => {
        updateBody(isVisible);
        window.addEventListener('keyup', handleKeyUp, false);

        return () => {
            window.removeEventListener('keyup', handleKeyUp, false);
        };
    }, []);

    const modalClass = classnames('modal', {
        'hidden': !isVisible
    });

    // Allow user to fully customize the content container and close button
    if (isCustom) {
        return (
            <div className={modalClass}>
                {children}
            </div>
        );
    }

    // Render a default content container and close button
    return (
        <div className={modalClass}>
            <div className="modal-content || constrain-width">
                <button
                    type="button"
                    className="modal-close-button"
                    onClick={onClose}>
                    <span>
                        <Icon name="close" />
                    </span>
                </button>
                {children}
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    isCustom: PropTypes.bool
};

export default Modal;
