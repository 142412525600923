import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

class Introduction extends React.Component {
    render() {
        const {
            content,
            file
        } = this.props.data;

        return (
            <section className="introduction-block">
                <div className="constrain-width large">
                    <div className="inner" style={{ backgroundImage: `url(${file})` }}>
                        <div className="constrain-content || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content) }} />
                    </div>
                </div>
            </section>
        );
    }
}

Introduction.propTypes = {
    data: PropTypes.object.isRequired
};

export default Introduction;
