import MapInteraction from './map-interaction';
import React from 'react';

const COMPONENT_NAME = 'scalable-object';

const ScalableObject = (props) => {
    const { children, image } = props; // eslint-disable-line react/prop-types

    return (
        <MapInteraction {...props}>
            {
                ({ translation, scale }) => {
                    // Translate first and then scale. Otherwise, the scale would affect the translation
                    const transform = `translate(${translation.x}px, ${translation.y}px) scale(${scale})`;

                    return (
                        <div
                            className={`${COMPONENT_NAME}-mask`}
                            // Important this style is declared inline not as SASS
                            style={{
                                transform: transform,
                                transformOrigin: '0 0 '
                            }}
                        >
                            {/* Render image if provided in props by default */}
                            {image &&
                                <img
                                    draggable="false" // Prevents drag like CSS: { pointer-events: none; }
                                    className={`${COMPONENT_NAME}-image`}
                                    src={image.src || ''} // eslint-disable-line react/prop-types
                                    alt={image.alt || ''} // eslint-disable-line react/prop-types
                                /> || children}
                        </div>
                    );
                }
            }
        </MapInteraction>
    );
};

export default ScalableObject;
