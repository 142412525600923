
import { createValidationDataObject } from 'app/utilities/form-validation';
import { DATE_FORMAT } from 'config/api';
import DatePicker from 'app/components/partials/date-picker';
import Input from 'app/components/partials/input';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { displayDateFormat, RECEIVER_FORM_FIELDS } from 'config/gift-memberships-forms';
import { setGiftReceiverFieldValueAction, touchedGiftReceiverFieldAction } from 'app/ducks/buy-memberships';
import { useDispatch, useSelector } from 'react-redux';

const ReceiverDetails = ({ stepClickHandler }) => {
    const dispatch = useDispatch();
    const { currentStepIndex } = useSelector((state) => state.buyMemberships);
    const receiver = useSelector((state) => state.buyMemberships.giftReceiver);

    const getFieldValue = (fieldName) => receiver[fieldName].value;
    const getFieldTouched = (fieldName) => receiver[fieldName].touched;
    const setFieldValue = (fieldName, value) => dispatch(setGiftReceiverFieldValueAction(fieldName, value));
    const touchedField = (fieldName) => dispatch(touchedGiftReceiverFieldAction(fieldName));

    const validateForm = () => {
        const invalid = RECEIVER_FORM_FIELDS.some(({ name, validator }) => {
            const value = getFieldValue(name);

            return !validator(value).valid;
        }, []);

        return invalid;
    };

    const renderDatePicker = (fieldData) => {
        // Send gift email from now til 12 months later
        const fromDate = moment().toDate();
        // eslint-disable-next-line no-magic-numbers
        const toDate = moment().add(12, 'months').toDate();

        // Set to today by default (we can't do this in INITIAL_STATE)
        if (!fieldData.value) {
            const today = moment().format(DATE_FORMAT);
            setFieldValue(fieldData.key, today);
        }

        return <DatePicker { ...fieldData } displayDateFormat={displayDateFormat} fromDate={fromDate} toDate={toDate} />;
    };

    const renderForm = () => (
        <form className="receiver-details-form">
            <div className="section">
                <div className="form-group">
                    {RECEIVER_FORM_FIELDS.map((FIELD, index) => {
                        const { name: fieldName, label, validator, type, placeholder, isRequired, helpText, isEmpty } = FIELD;

                        // Hack: on desktop force new row after email field
                        if (isEmpty) {
                            return <div key={fieldName} className="form-field-empty"></div>;
                        }

                        const value = getFieldValue(fieldName);
                        const touched = getFieldTouched(fieldName);

                        const validationData = touched ? validator(value) : createValidationDataObject();

                        const fieldData = {
                            index,
                            key: fieldName,
                            id: fieldName,
                            name: fieldName,
                            label,
                            helpText,
                            type,
                            placeholder,
                            value,
                            onChangeHandler: (event) => {
                                setFieldValue(fieldName, event.target.value);
                                touchedField(fieldName);
                            },
                            onBlurHandler: () => touchedField(fieldName),
                            isRequired,
                            error: validationData.valid ? '' : validationData.message
                        };

                        return fieldData.type === 'date' ?
                            renderDatePicker(fieldData) :
                            <Input { ...fieldData } />;
                    })}
                </div>
            </div>
        </form>
    );

    // useEffect(() => {

    // });

    return (
        <div className="inner">
            <div className="constrain-width medium no-pad">
                <h4 className="title">Recipient details</h4>
                <p className="subtitle">This is the contact who will receive the gift and create an account (customers must be at least 15 years old to create an account)</p>
                {renderForm()}

                <section className="section button-group">
                    <button
                        className="button"
                        onClick={() => stepClickHandler(currentStepIndex - 1)}
                    >Back</button>
                    <button
                        className="button primary"
                        onClick={() => stepClickHandler(currentStepIndex + 1)}
                        disabled={validateForm()}
                    >
                        Next
                    </button>
                </section>
            </div>
        </div>
    );
};

ReceiverDetails.propTypes = {
    stepClickHandler: PropTypes.func.isRequired
};

export default ReceiverDetails;
