import aucklandZooLogo from 'static/images/logo-new.svg';
import aucklandZooLogoMobile from 'static/images/logo-mobile.svg';
import classnames from 'classnames';
import { FROM_NORMAL_SCREEN_W } from 'config/breakpoints';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import { hideNotification } from 'app/utilities/notifications';
import Icon from './icon';
import { NAVIGATION_ITEMS } from 'config/members-portal';
import NotificationBanner from 'app/components/partials/notification-banner.container';
import PropTypes from 'prop-types';
import React from 'react';
import Toast from 'app/components/partials/toast';
import updateBody from 'app/utilities/update-body';
import { HEADER_CTA, NAVIGATION_HEADER, NAVIGATION_HEADER_SECONDARY } from 'config/navigation.js';
import { Link, NavLink } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.scrollListener = this.scrollListener.bind(this);

        this.state = this.defaultState();
    }

    defaultState() {
        return {
            isScrolling: false,
            isNavigationActive: false,
            navigationItemHovered: null,
            navigationItemOpen: null,
            isPortalNavDropDownDesktopActive: false,
            isPortalNavDropdownMobileActive: false
        };
    }

    // Life Cycle
    componentDidMount() {
        if (this.header) {
            window.addEventListener('scroll', this.scrollListener);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isNotificationsActive === nextProps.isNotificationsActive) {
            // Reset Header State
            this.setState(this.defaultState(), updateBody(false));
        }

        if (this.props.status.isRouting && !nextProps.status.isRouting) {
            // Scroll to top
            window.scrollTo(0, 0);
        }
    }

    // Listener
    scrollListener() {
        const scrollElementHeight = this.header.clientHeight;
        const scrollTop = window.pageYOffset === undefined
            ? (document.documentElement ||
                    document.body.parentNode ||
                    document.body).scrollTop
            : window.pageYOffset;

        if (scrollTop > scrollElementHeight) {
            this.setState({ isScrolling: true });
        } else {
            this.setState({ isScrolling: false });
        }
    }

    // Handler
    handleToggleNavigation() {
        let { navigationItemsOpen } = this.state;
        const { isNavigationActive } = this.state;
        // Notifications must be closed before mobile navigations become active
        if (this.props.isNotificationsActive && !isNavigationActive) {
            this.handleToggleNotifications();
        }

        // On close reset child navigation items
        if (isNavigationActive) {
            navigationItemsOpen = this.defaultState().navigationItemsOpen;
        }

        this.setState({
            isNavigationActive: !isNavigationActive,
            navigationItemOpen: navigationItemsOpen
        }, updateBody(!isNavigationActive));
    }

    handleToggleChildNavigation(number) {
        let { navigationItemOpen } = this.state;

        // Upate/remove the current navigationItemOpen using number
        if (navigationItemOpen === number) {
            navigationItemOpen = this.defaultState().navigationItemOpen;
        } else {
            navigationItemOpen = number;
        }

        this.setState({
            navigationItemHovered: null,
            navigationItemOpen: navigationItemOpen
        });
    }

    handleCloseNavigation() {
        this.resetHeaderState();
    }

    handleToggleNotifications(closeNavigation) {
        hideNotification('all');

        // Mobile navigation must be close if notifications is open.
        if (closeNavigation && this.state.isNavigationActive) this.handleCloseNavigation();

        this.props.toggleNotifications();
    }

    handleToggleDropDownMenu() {
        const { isPortalNavDropDownDesktopActive } = this.state;
        this.setState({
            isPortalNavDropDownDesktopActive : !isPortalNavDropDownDesktopActive
        });
    }

    handleToggleMobileDropDownMenu() {
        const { isPortalNavDropdownMobileActive } = this.state;
        this.setState({
            isPortalNavDropdownMobileActive: !isPortalNavDropdownMobileActive
        });
    }

    // Helper
    resetHeaderState() {
        this.setState(this.defaultState());
    }

    // Render
    renderNavigationItem({ children, label, link, isMobileOnly }, index) {
        const itemClass = classnames('item', {
            'is-hovered': this.state.navigationItemHovered === index,
            'is-active': this.state.navigationItemOpen === index,
            'is-mobile-only': isMobileOnly
        });

        return (
            <div
                className={itemClass}
                key={index}
                onMouseEnter={() => {
                    if (window.matchMedia(FROM_NORMAL_SCREEN_W).matches) this.setState({ navigationItemHovered: index });
                }}
                onMouseLeave={() => {
                    if (window.matchMedia(FROM_NORMAL_SCREEN_W).matches) this.setState({ navigationItemHovered: null });
                }}
            >
                {link ? (
                    <NavLink
                        className={({ isActive }) => classnames('link', { 'is-active': isActive })}
                        to={link}
                    >
                        {label}
                    </NavLink>
                ) : (
                    <span className="link">{label}</span>
                )}
                {children &&
                    <button
                        className="children-toggle"
                        onClick={this.handleToggleChildNavigation.bind(this, index)}>
                        <Icon name="chevron" title="Open subpages" />
                    </button>}
                {children &&
                    <nav className="navigation-children">
                        {children.map(({ label, link, type }, subIndex) => {
                            const linkClass = classnames('sub-item', {
                                '|| button primary': type === 'button'
                            });

                            // Render Link external links
                            if (link.indexOf('http') > -1) {
                                return (
                                    <a className={linkClass} href={link} key={`${index}-${subIndex}`}>{label}</a>
                                );
                            }

                            if (link) {
                                return (
                                    <NavLink
                                        className={({ isActive }) => classnames(linkClass, { 'is-active': isActive })}
                                        to={link}
                                        key={`${index}-${subIndex}`}
                                    >
                                        {label}
                                    </NavLink>
                                );
                            }

                            return (
                                <span
                                    className={linkClass}
                                    key={`${index}-${subIndex}`}
                                >
                                    {label}
                                </span>
                            );
                        })}
                    </nav>}
            </div>
        );
    }

    renderPortalNavItem() {
        const { isPortalNavDropdownMobileActive } = this.state;
        const { isAuth, user, userLogout } = this.props;
        const isAuthUser = isAuth && user;

        const portalNavItemClass = classnames('item is-mobile-only portal-nav-item', {
            'is-active': isPortalNavDropdownMobileActive
        });

        // mobile version membership login button
        const renderPortalLogin = () => (
            <NavLink
                to={FRONTEND_ROUTES.PORTAL_LOGIN}
                className={({ isActive }) => classnames('link', { 'is-active': isActive })}
            >
                Member Login
            </NavLink>
        );

        // mobile version login menu
        const renderPortalNav = () => (
            <>
                <button className="user-info link" onClick={this.handleToggleMobileDropDownMenu.bind(this)}>
                    <Icon name="user-circle" width="30" height="30" />
                    <h2 className="heading">Kia ora,<span>{user.first_name}</span></h2>
                </button>
                <button className="children-toggle" onClick={this.handleToggleMobileDropDownMenu.bind(this)}>
                    <Icon name="chevron" title="Open subpages" />
                </button>
                <nav className="navigation-children">
                    {NAVIGATION_ITEMS.map(({ label, link }, index) => (
                        <NavLink
                            key={index}
                            className={({ isActive }) => classnames('sub-item', { 'is-active': isActive })}
                            to={link}
                        >
                            {label}
                        </NavLink>
                    ))}
                    <button className="sub-item" onClick={userLogout}>Logout</button>
                </nav>
            </>
        );

        return (
            <div className={portalNavItemClass}>
                {isAuthUser ? renderPortalNav() : renderPortalLogin()}
            </div>
        );
    }

    renderSecondaryNav() {
        const { isPortalNavDropDownDesktopActive } = this.state;
        const { isAuth, user, userLogout } = this.props;

        const navPortalNavItemClass = classnames('navigation-secondary-item || portal-nav-item', {
            'is-active': isPortalNavDropDownDesktopActive
        });

        const isAuthUser = isAuth && user;

        return (
            <div className="navigation-secondary">
                {NAVIGATION_HEADER_SECONDARY.map(({ label, link, external }, navigationItemIndex) => {
                    return external ?
                        <a
                            className="navigation-secondary-item"
                            key={navigationItemIndex}
                            href={link}
                        >
                            {label}
                        </a> :
                        <NavLink
                            key={navigationItemIndex}
                            to={link}
                            className={({ isActive }) => classnames('navigation-secondary-item', {
                                'is-active': isActive
                            })}
                        >
                            {label}
                        </NavLink>;
                })}
                {isAuthUser ? (
                    <div className={navPortalNavItemClass}>
                        <div
                            role="button"
                            tabIndex="0"
                            className="dropdown-toggle-btn"
                            onClick={this.handleToggleDropDownMenu.bind(this)}
                            onKeyDown={this.handleToggleDropDownMenu.bind(this)}
                        >
                            <Icon name="user-circle" width="30" height="30" />
                        </div>
                        <div className="portal-nav-dropdown">
                            <div className="user-info" >
                                <Icon name="user-circle" width="30" height="30" />
                                <h2 className="heading">Kia ora,<span>{user.first_name}</span></h2>
                            </div>
                            <hr className="hr" />
                            <div className="nav-links-wrapper">
                                {NAVIGATION_ITEMS.map(({ label, link }, index) => (
                                    <NavLink
                                        key={index}
                                        className={({ isActive }) => classnames('nav-link', { 'is-active': isActive })}
                                        to={link}
                                    >
                                        {label}
                                    </NavLink>
                                ))}
                                <button className="nav-link" onClick={userLogout}>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <NavLink
                        to={FRONTEND_ROUTES.PORTAL_LOGIN}
                        className={({ isActive }) => classnames('navigation-secondary-item', {
                            'is-active': isActive
                        })}
                    >
                       Member Login
                    </NavLink>
                )}
            </div>
        );
    }

    renderPrimaryNav() {
        return (
            <div className="navigation-primary">
                {this.renderPortalNavItem()}
                {NAVIGATION_HEADER.map((item, index) => this.renderNavigationItem(item, index))}
                <div className="item || navigation-cta desktop">
                    <Link className="button primary" to={HEADER_CTA.link}>{HEADER_CTA.label}</Link>
                </div>
                <div className="item || navigation-cta">
                    <a className="button outline" href="https://shop.aucklandzoo.co.nz/">Zoo Shop Online</a>
                </div>
            </div>
        );
    }

    renderHeaderActions() {
        const { isNavigationActive } = this.state;

        const navigationToggleClass = classnames('navigation-toggle', {
            'is-active': isNavigationActive
        });

        const notificationToggleClass = classnames('header-notification-toggle', {
            'is-active': this.props.isNotificationsActive
        });

        const mobileIconButton = <Link className="navigation-toggle ticket" to="/tickets">
            <Icon width="28" height="28" name="ticket" title="Tickets" />
        </Link>;

        return (
            <div className="header-actions">
                <button
                    type="button"
                    className={navigationToggleClass}
                    onClick={this.handleToggleNavigation.bind(this)}>
                    {!isNavigationActive &&
                        <span className="button-inner || menu">
                            <Icon name="hamburger" />
                            <span className="button-text">Menu</span>
                        </span>}
                    {isNavigationActive &&
                        <span className="button-inner || close">
                            <Icon name="close" />
                            <span className="button-text">Close</span>
                        </span>}
                </button>
                <button
                    type="button"
                    className="header-search-toggle"
                    onClick={this.props.toggleSearchDialog.bind(this)}>
                    <Icon name="search" />
                </button>

                {/* Used to increase blank space on Mobile */}
                <span className="header-actions–spacer"></span>

                {this.props.notifications.length > 0 &&
                    <button
                        type="button"
                        aria-label="Toggle Notifications"
                        className={notificationToggleClass}
                        onClick={this.handleToggleNotifications.bind(this, true)}>
                        <Icon name="bell" title="Notifications" />
                    </button>
                }
                {mobileIconButton}
            </div>
        );
    }

    renderHeaderCtaOnTablet() {
        return (
            <div className="header-cta">
                <Link
                    className="button primary"
                    aria-hidden="true"
                    tabIndex="-1"
                    to={HEADER_CTA.link}>
                    {HEADER_CTA.label}
                </Link>
                <a className="button" href="https://shop.aucklandzoo.co.nz/">Shop</a>
            </div>
        );
    }

    render() {
        const {
            isNavigationActive,
            isScrolling,
            navigationItemOpen,
        } = this.state;

        const headerClass = classnames('header', {
            'is-scrolling': isScrolling
        });

        const navigationClass = classnames('navigation', {
            'is-active': isNavigationActive
        });

        const { notifications, status } = this.props;

        return (
            <header className={headerClass} ref={(header) => { this.header = header; }}>
                <NotificationBanner />
                <div className="inner || constrain-width large">
                    <Link className="logo" to="/">
                        <img className="img desktop" src={aucklandZooLogo} alt="Auckland Zoo" />
                        <img className="img mobile" src={aucklandZooLogoMobile} alt="Auckland Zoo" />
                        <span className="logo-text">Auckland Zoo</span>
                    </Link>
                    <nav className={navigationClass}>
                        {this.renderSecondaryNav()}
                        {this.renderPrimaryNav()}
                    </nav>
                    {this.renderHeaderCtaOnTablet()}
                    {this.renderHeaderActions()}
                </div>
                {navigationItemOpen &&
                    <button
                        className="close-mouse-capture"
                        aria-hidden="true"
                        tabIndex="-1"
                        onFocus={this.handleCloseNavigation.bind(this)}
                        onMouseOver={this.handleCloseNavigation.bind(this)}>
                        Close child navigation
                    </button>
                }
                <Toast isActive={Boolean(status.internalError)} message={status.internalError ? status.internalError.message : ''} />
            </header>
        );
    }
}

Header.propTypes = {
    notifications: PropTypes.array.isRequired,
    isNotificationsActive: PropTypes.bool.isRequired,
    status: PropTypes.shape({
        isRouting: PropTypes.bool.isRequired,
        internalError: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool
        ]),
    }).isRequired,
    toggleNotifications: PropTypes.func.isRequired,
    toggleSearchDialog: PropTypes.func.isRequired,
    isAuth: PropTypes.bool.isRequired,
    user: PropTypes.object,
    userLogout: PropTypes.func.isRequired
};

export default Header;
