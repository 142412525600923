import classnames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const BUTTON_CLASS = 'button small';

class Faq extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            itemsOpen: null
        };
    }


    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.setState(this.getInitialState);
        }
    }


    // Handlers
    handleToggle(index) {
        let { itemsOpen } = this.state;
        const { items } = this.props;

        if (itemsOpen === index) {
            itemsOpen = null;
        } else {
            itemsOpen = index;

            /* ↓ Tracking FAQ question expansion events */
            dataLayerPush({
                event: 'faqQuestionExpanded',
                id: 'micro_conversion',
                type: 'engaging_content',
                action: 'faq_click',
                text: items[itemsOpen].title
            });
            /* ↑ Tracking FAQ question expansion events */
        }

        this.setState({ itemsOpen: itemsOpen });
    }


    // Rendering
    render() {
        const { itemsOpen } = this.state;
        const { items } = this.props;

        if (items.length < 1) {
            return null;
        }

        // TODO: Heading should not be nested withing each item?
        const heading = items[0].heading === '' ? null : items[0].heading;

        return (
            <section className="section || text-block">
                <div className="constrain-width large">
                    <div className="inner">
                        <div className="content">
                            {heading && <h2>{heading}</h2>}
                            <div className="accordion">
                                {items.map((item, index) => {
                                    const { content, title } = item;

                                    const itemClass = classnames('item', {
                                        'is-active': itemsOpen === index
                                    });

                                    return (
                                        <div className={itemClass} key={index}>
                                            <button className="item-header" onClick={this.handleToggle.bind(this, index)}>
                                                <Icon name="chevron" title="Toggle Icon" />
                                                <h4 className="title">{title}</h4>
                                            </button>
                                            <div className="item-content">
                                                <div className="constrain-content || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content, BUTTON_CLASS) }}></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Faq.propTypes = {
    items: PropTypes.array.isRequired
};

export default Faq;
