/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    encounters: undefined,
    weekDay: 'today'
};

// Actions
export const FETCH_ENCOUNTERS_SUCCESS = 'rfa-zoo-website/encounters/FETCH_ENCOUNTERS_SUCCESS';
export const FETCH_ENCOUNTERS = 'rfa-zoo-website/encounters/FETCH_ENCOUNTERS';

// Action Creators
export const fetchEncountersSuccessAction = (weekDay, encounters) => ({
    type: FETCH_ENCOUNTERS_SUCCESS,
    payload: {
        encounters,
        weekDay
    }
});

export const fetchEncountersAction = (weekDay) => ({
    type: FETCH_ENCOUNTERS,
    weekDay
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ENCOUNTERS:
            return fetchEncounters(state, action.weekDay);
        case FETCH_ENCOUNTERS_SUCCESS:
            return fetchEncountersSuccess(state, action.payload);
        default:
            return state;
    }
};

function fetchEncounters(state, weekDay) {
    return {
        ...state,
        weekDay,
        encounters: undefined
    };
}

function fetchEncountersSuccess(state, { weekDay, encounters }) {
    // making sure the user hasn't changed the week day prior to the api respnose arriving
    if (state.weekDay === weekDay) {
        return {
            ...state,
            encounters
        };
    }

    return state;
}

// Epic creator

/**
 * @callback formatEncounters
 * @param {object} response
 * @returns {array} encounters - encounters array
 */

/**
 * @callback fetchEncountersSuccessAction
 * @param {string} weekDay
 * @param {array} encounters - encounters array
 * @returns {object} action
 */

/**
 * Creates Encounters epic
 * @param {string} endpoint - encounters endpoint
 * @param {formatEncounters} formatEncounters - response formatter function
 * @param {fetchEncountersSuccessAction} formatEncounters - action creator
 */
export const createEncountersEpic = (endpoint, formatEncounters, fetchEncountersSuccessAction) => {
    return (action$) => action$.pipe(
        ofType(FETCH_ENCOUNTERS),
        mergeMap(({ weekDay }) => {
            return (
                fetch(`${endpoint}?week_day=${weekDay}`)
                    .then((response) => {
                        return fetchEncountersSuccessAction(weekDay, formatEncounters(response));
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return fetchEncountersSuccessAction(weekDay, []);
                    })
            );
        })
    );
};

// Epics
const encountersEpic = createEncountersEpic(
    ENDPOINTS.ENCOUNTERS,
    formatEncounters,
    fetchEncountersSuccessAction
);

// Helpers
function formatEncounters(response) {
    if (response && response.data && response.data.length) {
        return response.data.map((encounter) => ({
            name: encounter.attributes.name,
            ...encounter.attributes
        }));
    }

    return [];
}

export const epics = combineEpics(encountersEpic);
