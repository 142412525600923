import Banner from 'app/components/partials/banner';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import StacklaWidget from 'app/components/content-blocks/stackla-widget';
import TextContent from 'app/components/content-blocks/text-content';
import React, { Component } from 'react';

class GoConnect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stacklaAllActive: true,
            stacklaWinnersActive: false,
            showModal: false
        };
    }

    onStacklaButtonClick(context) {
        if (context === 'all') {
            this.setState({
                stacklaAllActive: true,
                stacklaWinnersActive: false
            });
        } else {
            this.setState({
                stacklaAllActive: false,
                stacklaWinnersActive: true
            });
        }
    }

    setModalVisibility(visible) {
        this.setState({ showModal: visible });
    }

    renderUploadButton() {
        return (
            <button className="button primary" onClick={this.setModalVisibility.bind(this, true)}>Upload here</button>
        );
    }

    render() {
        const {
            data,
            widgetId,
            all,
            winners
        } = this.props;

        const {
            blocks,
            name: title,
            highlighted_information: highlight,
            main_images: images,
            latin_name: latinName,
            short_description: description,
        } = data;

        const {
            stacklaAllActive,
            stacklaWinnersActive,
            showModal
        } = this.state;

        /**
         * Finds the first text content aside block so it can be used to render
         * a custom block with stackla upload image button.
         */
        const textContentBlock = blocks.find((block) => {
            return block.type === 'contentaside';
        });

        return (
            <div className="page || page-view">
                <Banner
                    title={title}
                    subtitle={latinName}
                    introduction={description}
                    highlight={highlight}
                    images={images} />

                <TextContent
                    {...textContentBlock}
                    childrenAsideAfter={this.renderUploadButton()}
                />

                <Modal isVisible={showModal} onCloseHandler={() => this.setModalVisibility(false)}>
                    <div id="stackla-goconnect-widget" data-widget-id={widgetId}></div>
                </Modal>

                {all && winners && (
                    <div>
                        <div className="go-connect-button-group || constrain-width large">
                            <div className="button-group-wrapper">
                                <div className="button-group-inner">
                                    <button
                                        className={`button large ${stacklaAllActive ? ' || is-active' : ''}`}
                                        onClick={this.onStacklaButtonClick.bind(this, 'all')}>
                                        All photos
                                    </button>
                                    <button
                                        className={`button large ${stacklaWinnersActive ? '|| is-active' : ''}`}
                                        onClick={this.onStacklaButtonClick.bind(this, 'winners')}>
                                        Winners
                                    </button>
                                    <span aria-hidden={true} className={`button-group-active ${stacklaWinnersActive ? 'last' : ''}`}></span>
                                </div>
                            </div>
                        </div>
                        <div className="stackla-group">
                            <StacklaWidget
                                isActive={stacklaAllActive}
                                {...all} />
                            <StacklaWidget
                                isActive={stacklaWinnersActive}
                                {...winners} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

GoConnect.propTypes = {
    data: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ]),
    widgetId: PropTypes.string.isRequired,
    all: PropTypes.shape({
        hash: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    }),
    winners: PropTypes.shape({
        hash: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    })
};

export default GoConnect;
