import { EntityLink } from 'rfa-react-core';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

class SearchResult extends React.Component {
    // Handler
    handleSearchResultLinkClick() {
        // We need to close the search dialog and clear the keywords field.

        const { initiateSearch, toggleSearchDialog } = this.props;

        initiateSearch('');
        toggleSearchDialog();
    }

    render() {
        const { image, linkedItem, title, type } = this.props;

        const style = image ? { backgroundImage: `url(${image})` } : {};

        return (
            <article className="search-result">
                <EntityLink
                    item={linkedItem}
                    promoterBuName="Auckland Zoo"
                    className="search-result-inner"
                    onClick={this.handleSearchResultLinkClick.bind(this)}
                >
                    <div className="search-result-image || preserve-image-ratio" style={style}>
                        {image && <img className="fixed-image" alt={title} src={image} />}
                        {!image && <Icon className="icon" name="image" />}
                    </div>
                    <div className="tile-description">
                        <h4 className="tile-description-title">{title}</h4>
                        {type && <h5 className="tile-description-type">{type}</h5>}
                    </div>
                </EntityLink>
            </article>
        );
    }
}

SearchResult.propTypes = {
    image: PropTypes.string,
    initiateSearch: PropTypes.func.isRequired,
    linkedItem: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    toggleSearchDialog: PropTypes.func.isRequired,
    type: PropTypes.string
};

export default SearchResult;
