/* eslint-disable camelcase */

import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const BYTE_BASE = 1024;
const DECIMAL_PLACES = 1;
const KILO_BYTE = 1024;
const MEGA_BYTE = KILO_BYTE * BYTE_BASE;
const GIGA_BYTE = MEGA_BYTE * BYTE_BASE;

class FileDownload extends React.Component {
    get filesize() {
        const { filesize: bytes, extension } = this.props;
        const extensionText = `${extension.toUpperCase()} file`;

        if ((bytes >= KILO_BYTE) && (bytes < MEGA_BYTE)) {
            return `${(bytes / KILO_BYTE).toFixed(DECIMAL_PLACES)} KB ${extensionText}`;
        } else if ((bytes >= MEGA_BYTE) && (bytes < GIGA_BYTE)) {
            return `${(bytes / MEGA_BYTE).toFixed(DECIMAL_PLACES)} MB ${extensionText}`;
        }

        return `${bytes} B ${extensionText}`;
    }

    render() {
        const { display_name, file, filename } = this.props;

        return (
            <section className="section || text-block || file-download-block">
                <div className="constrain-width large">
                    <div className="inner">
                        <div className="content">
                            <div className="constrain-content">
                                <div className="file-download">
                                    <div className="details">
                                        <Icon name="file" className="icon" />
                                        <h4 className="title">{display_name || filename}</h4>
                                        <p>{this.filesize}</p>
                                    </div>
                                    <a className="button primary" href={file} download={filename} target="_blank" rel="noreferrer">
                                        Download<Icon name="chevron" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

FileDownload.propTypes = {
    display_name: PropTypes.string,
    extension: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    filesize: PropTypes.string.isRequired
};

export default FileDownload;
