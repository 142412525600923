import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

class Pricing extends React.Component {
    /**
     * Removes special characters including $, Free price and doesn't return tickets that doesn't have a valid price, such as 'Group
     * @param {Object} ticket - The ticket object including a price property.
     * @returns {Boolean} True values will return the validated ticket when used on Array.filter.
     */
    validateTicket(ticket) {
        const price = ticket.price;

        if (price.toLowerCase().includes('free') || price.includes('$')) {
            ticket.price = price.replace(/\$+/g, '').toLowerCase().replace(/free/g, '0').trim();

            return true;
        }

        return false;
    }

    getTicketList(data) {
        let ticketsSections = [];

        data.map((ticketGroup) => {
            Array.prototype.push.apply(ticketsSections, ticketGroup.sections);
        });

        // Validate all tickets and remove special characters
        ticketsSections = ticketsSections.filter(this.validateTicket);

        const ticketList = [];

        // Run through all the tickets and creates unique ticket list with min and max prices from duplicated tickets.
        ticketsSections.reduce((ticketList, current) => {
            const existingTicketIndex = ticketList.findIndex((ticket) => ticket.title === current.title);

            if (existingTicketIndex > -1) {
                const existingTicket = ticketList[existingTicketIndex];

                if (existingTicket.price < current.price) {
                    existingTicket.minPrice = existingTicket.price;
                    existingTicket.maxPrice = current.price;
                } else {
                    existingTicket.minPrice = current.price;
                    existingTicket.maxPrice = existingTicket.price;
                }

                return ticketList;
            }

            ticketList.push(current);

            return ticketList;
        }, ticketList);

        return ticketList;
    }

    renderStructuredData(data) {
        const ticketList = this.getTicketList(data);

        const structuredData = ticketList.map(({ title, description, minPrice, maxPrice, price }) => ({
            '@context' : 'http://schema.org',
            '@type' : 'Product',
            'name' : title,
            'description' : description,
            'offers' : {
                '@type' : 'Offer',
                'url': 'https://www.aucklandzoo.co.nz/tickets',
                'priceSpecification' : {
                    'minPrice' : minPrice,
                    'maxPrice': maxPrice,
                    'price': minPrice || price,
                    'priceCurrency': 'NZD',
                }
            }
        }));

        return (
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `
                /* <![CDATA[ */
                    ${JSON.stringify(structuredData)}
                /* ]]> */
            ` }} />
        );
    }

    render() {
        const { data } = this.props;

        return (
            <section className="section || pricing-block">
                <div className="constrain-width large">
                    <div className="inner">
                        {data.map((planGroup, index) => {
                            const { content, label, sections, title, url } = planGroup;

                            return (
                                <div className="plan-group" key={index}>
                                    <h2>{title}</h2>
                                    {content && <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(content) }}></div>}
                                    {sections.map((plan, index) => {
                                        const { description, price, title } = plan;

                                        return (
                                            <div className="plan" key={index}>
                                                <h4 className="plan-heading">
                                                    <span>{title}</span>
                                                    <span className="price">
                                                        {url ?
                                                            <a href={url} className="figure"><span>{price}</span></a> :
                                                            <span className="figure"><span>{price}</span></span>
                                                        }
                                                    </span>
                                                </h4>
                                                <p>{description}</p>
                                            </div>
                                        );
                                    })}
                                    {url && <div className="plan-group-actions">
                                        <a className="button primary large" href={url}>{label}<Icon name="chevron" /></a>
                                    </div>}
                                </div>
                            );
                        })}
                        {this.renderStructuredData(data)}
                    </div>
                </div>
            </section>
        );
    }
}

Pricing.propTypes = {
    data: PropTypes.array.isRequired
};

export default Pricing;
