/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import Header from './header';
import selectStatus from 'app/selectors/status';
import { selectUserAuth, selectUserInfo } from 'app/selectors/auth';
// eslint-disable-next-line sort-imports
import { toggleNotificationsAction } from 'app/ducks/notifications';
import { toggleSearchDialogAction } from 'app/ducks/search';
import { userLogoutAction } from 'app/ducks/auth';
import { withLocation } from 'app/utilities/router-hoc.js';
import {
    selectIsActive,
    selectNotifications
} from 'app/selectors/notifications';

function mapStateToProps(state) {
    return {
        notifications: selectNotifications(state),
        isNotificationsActive: selectIsActive(state),
        status: selectStatus(state),
        isAuth: selectUserAuth(state),
        user: selectUserInfo(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSearchDialog: () => dispatch(toggleSearchDialogAction()),
        toggleNotifications: () => dispatch(toggleNotificationsAction()),
        userLogout: () => dispatch(userLogoutAction())
    };
}

const VisibleHeader = withLocation(connect(
    mapStateToProps,
    mapDispatchToProps
)(Header));

export default VisibleHeader;
