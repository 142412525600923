import Banner from 'app/components/partials/banner';
import classnames from 'classnames';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import Rezdy from '../content-blocks/rezdy';
import { TICKET_TYPES } from '../../../config/rezdy';
import React, { Component } from 'react';

class DayPass extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedType: TICKET_TYPES[0].title
        };
    }

    renderSelectTicketType() {
        const { selectedType } = this.state;

        return (
            <div className="select-tickets">
                <div className="block || section">
                    <div className="constrain-width large">
                        <div className="inner" style={{ display: 'none' }}>
                            <div className="constrain-width medium no-pad">
                                <div className="ticket-type-selector">
                                    {TICKET_TYPES.map((type, index) => {
                                        return (
                                            <label
                                                key={index}
                                                className={classnames('radio', { 'is-active': selectedType === type.title })}
                                                tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
                                            >
                                                <input
                                                    type="radio"
                                                    name="buy-tickets-ticket-type"
                                                    value={type.title}
                                                    checked={selectedType === type.title}
                                                    onChange={(event) => { this.setState({ selectedType: event.target.value }); }}
                                                />
                                                <span className="radio-inner">
                                                    {type.title}
                                                </span>
                                                <div aria-hidden="true" className="radio-circle" />
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderRezdyOptions() {
        return (
            <div className="day-pass-options">
                {TICKET_TYPES.map((type, index) => {
                    const isActive = this.state.selectedType === type.title;
                    const itemClass = classnames('day-pass-options-item', {
                        'is-active': isActive
                    });

                    return (
                        <div className={itemClass} key={index} aria-hidden={!isActive} >
                            <Rezdy url={type.url} title={type.title} />
                        </div>
                    );
                })
                }
            </div>
        );
    }

    render() {
        const {
            blocks,
            name: title,
            highlighted_information: highlight,
            main_images: images,
            latin_name: latinName,
            short_description: description,
            button_label: buttonLabel,
            button_link: buttonLink,
        } = this.props.data;

        return (
            <div className="page || page-view">

                <Banner
                    buttonLabel={buttonLabel}
                    buttonLink={buttonLink}
                    title={title}
                    subtitle={latinName}
                    introduction={description}
                    highlight={highlight}
                    images={images}
                />
                <div className="day-pass">
                    {this.renderSelectTicketType()}
                    {this.renderRezdyOptions()}
                </div>
                <ContentBlocks data={blocks} />
            </div>
        );
    }
}

DayPass.propTypes = {
    data: PropTypes.object.isRequired,
};

export default DayPass;
