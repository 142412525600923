/* eslint-disable require-jsdoc */

import { connect } from 'react-redux';
import Footer from './footer';
import selectGlobalFields from 'app/selectors/global-fields';

function mapStateToProps(state) {
    return {
        globalFields: selectGlobalFields(state)
    };
}

const FooterContainer = connect(
    mapStateToProps
)(Footer);

export default FooterContainer;
