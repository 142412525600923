import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Stackla extends Component {
    componentDidMount() {
        if (window && window.Stackla) {
            // Re-build every case on the page
            window.Stackla.WidgetManager.rebuild(); // eslint-disable-line no-undef
        }
    }

    UNSAFE_componentWillReceiveProps({ id }) {
        // Re-build if ID changes
        if (window && window.Stackla && id !== this.props.id) {
            window.Stackla.WidgetManager.rebuild(); // eslint-disable-line no-undef
        }
    }

    render() {
        const { hash, id, title } = this.props;


        // All of these properties are needed for the embedded JS to work correctly
        const STACKLA_WIDGET_RESERVED_PROPERTIES = {
            className:      'stackla-widget', // This class name is used by the client side script to render the widget
            'data-alias':   'regionalfacilitiesauckland.stackla.com',
            'data-ct':      '',
            'data-hash':    hash,
            'data-id':      id,
            'data-title':   title,
            'data-ttl':     '30'
        };

        return (
            <div {...STACKLA_WIDGET_RESERVED_PROPERTIES} />
        );
    }
}

const WIDGET_TYPES = ['masonry'];

Stackla.defaultProps = {
    type: WIDGET_TYPES[0]
};

Stackla.propTypes = {
    hash: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    // TODO: Include type for future variants/layout potentially
    type: PropTypes.oneOf(WIDGET_TYPES).isRequired
};

export default Stackla;
