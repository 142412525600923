import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HorizontalSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blobTop: 0,
            blobLeft: 0,
            blobWidth: 0,
            blobHeight: 46
        };
        this.resizeListener = this.resizeListener.bind(this);
    }

    componentDidMount() {
        this.recalculateBlobPosition(this.props.value);
        window.addEventListener('resize', this.resizeListener);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.recalculateBlobPosition(nextProps.value);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListener);
    }

    resizeListener() {
        this.recalculateBlobPosition(this.props.value);
    }

    recalculateBlobPosition(value) {
        const {
            left: labelsBoxX,
            top: labelsBoxY
        } = this.labelsBox.getBoundingClientRect();
        const {
            left: labelX,
            top: labelY,
            width: labelWidth,
            height: labelHeight
        } = this.labelsBox.childNodes[value].getBoundingClientRect();

        this.setState({
            blobTop: labelY - labelsBoxY,
            blobLeft: labelX - labelsBoxX,
            blobWidth: labelWidth,
            blobHeight: labelHeight
        });
    }

    renderBlob() {
        const {
            blobTop,
            blobLeft,
            blobWidth,
            blobHeight
        } = this.state;

        return (
            <div
                className="blob"
                style={{
                    top: blobTop,
                    left: blobLeft,
                    width: blobWidth,
                    height: blobHeight
                }}
            />
        );
    }

    renderLabel(label, labelIndex) {
        const { onChange, value } = this.props;

        return (
            <button
                key={labelIndex}
                className={classnames('label', { 'is-active': labelIndex === value })}
                role="tab"
                tabIndex="0"
                onClick={() => { onChange(labelIndex); }}
                style={{
                    cursor: 'pointer'
                }}
            >
                {label}
            </button>
        );
    }

    renderMobile() {
        const { labels, onChange, value } = this.props;

        return (
            <label className="horizontal-selector-mobile">
                <span className="label">{labels[value]}</span>
                <Icon name="chevron" title="Filter by" />
                <select // eslint-disable-line jsx-a11y/no-onchange
                    className="select"
                    value={value}
                    onChange={(event) => {
                        event.preventDefault();
                        onChange(Number(event.target.value));
                    }}
                >
                    {labels.map((label, labelIndex) => (
                        <option key={labelIndex} value={labelIndex}>{label}</option>
                    ))}
                </select>
            </label>
        );
    }

    renderDesktop() {
        const { labels } = this.props;

        return (
            <div
                className="horizontal-selector-desktop"
                style={{ position: 'relative' }}
            >
                {this.renderBlob()}
                <div
                    ref={(me) => { this.labelsBox = me; }}
                    className="label-box"
                >
                    {labels.map((label, labelIndex) => this.renderLabel(label, labelIndex))}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderMobile()}
                {this.renderDesktop()}
            </div>
        );
    }
}

HorizontalSelector.propTypes = {
    labels: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.number.isRequired
};

export default HorizontalSelector;
