import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

const BUTTON_CLASS = 'button inline has-chevron';

class CallToAction extends React.Component {
    render() {
        const {
            content,
            file,
            thumbnail,
        } = this.props.data;

        const backgroundImage = thumbnail && thumbnail.file ? thumbnail.file : '';

        return (
            <section className="content-media-block">
                <div className="constrain-width large">
                    <div className="inner">
                        <div className="media">
                            {file && <div className="media-inner || preserve-image-ratio" style={{ backgroundImage: `url(${file})` }}>
                                <img className="fixed-image" src={file} alt={file} />
                            </div>}
                        </div>
                        <div className="content">
                            <div className="title-group light background-image || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content, BUTTON_CLASS) }} style={{ backgroundImage: `url(${backgroundImage})` }}></div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

CallToAction.propTypes = {
    data: PropTypes.object.isRequired
};

export default CallToAction;
