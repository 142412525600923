import ArticleTile from 'app/components/partials/article-tile';
import ExtendedArticleTile from 'app/components/partials/extended-article-tile';
import FeaturedArticleTile from 'app/components/partials/featured-article-tile';
import { NEWS } from 'config/news';
import PropTypes from 'prop-types';
import React from 'react';
import Tile from 'app/components/partials/tile';

class TileGrid extends React.Component {
    render() {
        const { items } = this.props;

        return (
            <div className="tile-grid">
                {items.map((item, index) => {
                    const { type } = item;

                    // Use entity to identify layout
                    if (type === 'news') {
                        const { displayType } = item;

                        // Use 'displayType' to differentiate
                        switch (displayType) {
                            case NEWS.DISPLAY_TYPES.FEATURED:
                                return <FeaturedArticleTile key={index} item={item} />;
                            case NEWS.DISPLAY_TYPES.EXTENDED:
                                return <ExtendedArticleTile key={index} item={item} />;
                            default:
                                return <ArticleTile key={index} item={item} />;
                        }
                    }

                    return <Tile key={index} item={item} />;
                })}
            </div>
        );
    }
}

TileGrid.propTypes = {
    items: PropTypes.array.isRequired
};

export default TileGrid;
