import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { NEWS } from 'config/news';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    items: [NEWS.CATEGORY_ALL],
    isBusy: false
};

// Actions
export const FETCH_NEWS_CATEGORIES_FAIL = 'rfa-zoo-website/news-categories/FETCH_NEWS_CATEGORIES_FAIL';
export const FETCH_NEWS_CATEGORIES_SUCCESS = 'rfa-zoo-website/news-categories/FETCH_NEWS_CATEGORIES_SUCCESS';
export const FETCH_NEWS_CATEGORIES = 'rfa-zoo-website/news-categories/FETCH_NEWS_CATEGORIES';


// Action Creators
export const fetchNewsCategoriesAction = () => ({
    type: FETCH_NEWS_CATEGORIES,
    payload: {
        isBusy: true
    }
});

export const fetchNewsCategoriesFailAction = () => ({
    type: FETCH_NEWS_CATEGORIES_FAIL,
    payload: {
        isBusy: false
    }
});

export const fetchNewsCategoriesSuccessAction = (categories) => ({
    type: FETCH_NEWS_CATEGORIES_SUCCESS,
    payload: {
        categories: categories,
        isBusy: false
    }
});


// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NEWS_CATEGORIES:
            return fetchNewsCategories(state, action.payload);
        case FETCH_NEWS_CATEGORIES_FAIL:
            return fetchNewsCategoriesFail(state, action.payload);
        case FETCH_NEWS_CATEGORIES_SUCCESS:
            return fetchNewsCategoriesSuccess(state, action.payload);
        default:
            return state;
    }
};

function fetchNewsCategories(state, { isBusy }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        isBusy: isBusy
    };
}

function fetchNewsCategoriesFail(state, { isBusy }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        isBusy: isBusy
    };
}

function fetchNewsCategoriesSuccess(state, { categories, isBusy }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        isBusy: isBusy,
        items: INITIAL_STATE.items.concat(categories)
    };
}


// Epic creator
/**
 * News Epic
 * @param  {func} endpoint                         - News endpoint
 * @param  {func} formatNewsCategories             - Format response function
 * @param  {func} fetchNewsCategoriesSuccessAction - Success action creator
 * @param  {func} fetchNewsCategoriesFailAction    - Fail action creator
 * @return {func}
 */
export const createNewsCategoriesEpic = (endpoint, formatNewsCategories, fetchNewsCategoriesSuccessAction, fetchNewsCategoriesFailAction) => {
    return (action$) => action$.pipe(
        ofType(FETCH_NEWS_CATEGORIES),
        mergeMap(() => {
            return (
                fetch(endpoint)
                    .then((response) => {
                        return fetchNewsCategoriesSuccessAction(formatNewsCategories(response));
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return fetchNewsCategoriesFailAction();
                    })
            );
        })
    );
};


// Epics
const categoriesEpic = createNewsCategoriesEpic(
    ENDPOINTS.NEWS_CATEGORIES,
    formatNewsCategories,
    fetchNewsCategoriesSuccessAction,
    fetchNewsCategoriesFailAction
);


/**
 * Formats the API response
 * @param  {object} response – Raw API response
 * @return {object}          - Formatted API response
 */
export function formatNewsCategories(response) {
    if (response && response.data && response.data.length) {
        const { data } = response;

        const categories = [];

        data.map(({ id, attributes }) => {
            categories.push({
                id: id,
                name: attributes.name
            });
        });

        return categories;
    }

    return INITIAL_STATE.categories;
}

export const epics = combineEpics(categoriesEpic);
