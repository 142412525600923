import { DayPicker } from 'react-day-picker';
import Input from 'app/components/partials/input';
import moment from 'moment';
import PropTypes from 'prop-types';
import { format, isValid, parse } from 'date-fns';
import React, { useState } from 'react';
import 'react-day-picker/dist/style.css';

const DatePicker = ({ disableToday, disableFutureDates, displayDateFormat, toYear, fromYear, fromDate, toDate, value, ...inputProps }) => {
    const [datePickerActive, setDatePickerActive] = useState(false);
    const displayValue = moment(value).isValid() && moment(value).format(displayDateFormat) || '';

    const datePickerSelectHandler = (date) => {
        if (date) {
            inputProps.onChangeHandler({
                target: { value: format(date, 'y-MM-dd') }
            });
        } else {
            inputProps.onChangeHandler({
                target: { value: '' }
            });
        }

        setDatePickerActive(false);
    };

    const inputClickHandler = () => setDatePickerActive(!datePickerActive);

    const today = new Date();
    const selectedDate = parse(value, 'y-MM-dd', today);

    const datePickerProps = {
        mode: 'single',
        captionLayout: 'dropdown',
        selected: selectedDate,
        onSelect: datePickerSelectHandler,
        fromYear: fromYear,
        defaultMonth: isValid(selectedDate) && selectedDate || '',
        ...disableFutureDates ? { toDate: today } : { toYear },
        ...disableToday ? { disabled: [today] } : {},
        ...fromDate ? { fromDate, fromYear: null } : {},
        ...toDate ? { toDate, toYear: null } : {},
    };

    return (
        <Input
            {...inputProps}
            value={displayValue}
            extraClass="date-picker"
            onClickHandler={inputClickHandler}
            type="text"
            readOnly
            inputIcon="calendar"
        >
            {datePickerActive &&
                <DayPicker {...datePickerProps} />}
        </Input>
    );
};

DatePicker.defaultProps = {
    disableToday: false,
    disableFutureDates: false,
    displayDateFormat: 'YYYY-MM-DD',
    toYear: moment().year(),
    fromYear: 1900
};

DatePicker.propTypes = {
    disableToday: PropTypes.bool,
    disableFutureDates: PropTypes.bool,
    displayDateFormat: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    onBlurHandler: PropTypes.func.isRequired,
    toYear: PropTypes.number,
    fromYear: PropTypes.number,
    // Specific dates override the year
    fromDate: PropTypes.objectOf(Date),
    toDate: PropTypes.objectOf(Date),
};

export default DatePicker;
