/* eslint-disable require-jsdoc */

export function selectNews(state) {
    return state.news.items;
}

export function selectNewsFeaturedItem(state) {
    return state.news.featuredItem;
}

export function selectNewsIsBusy(state) {
    return state.news.isBusy;
}

export function selectNewsResultCount(state) {
    return state.news.resultCount;
}
