/*
    Routable entites are a map of the entites and their template

    Defined Attributes:
    - 'custom'          = is used to override the default template
    - 'default'         = fallback template, per entity and global
 */
export const CMS_ENTITIES = {
    pages: 'pages',
    animals: 'animals',
    news: 'news'
};

export const ROUTABLE_ENTITIES = {
    animals: {
        default: 'page',
        custom: null
    },
    news: {
        default: 'article',
        custom: null
    },
    pages: {
        default: 'page',
        // Custom page slugs
        custom: [
            'home',
            'news',
            'zoosnaps',
            'zoo-map'
        ]
    }
};

export const DEFAULT_TEMPLATE = ROUTABLE_ENTITIES.pages.default;


/**
 * Returns the template type using the entity slug and type. A template type is
 * is used to define which React template to render – see
 * app/components/templates/index.jsx
 *
 * @param  {string} slug entity slug
 * @param  {string} type entity type
 *
 * @return {string} templateType
 */
export function templateType(slug, type) {
    // Check if type is in ROUTABLE_ENTITIES
    if (type in ROUTABLE_ENTITIES) {
        // If custom route used for entity return that
        if (ROUTABLE_ENTITIES[type].custom && ROUTABLE_ENTITIES[type].custom.includes(slug)) {
            // Return the custom slug
            return slug;
        }

        // Return default if not
        return ROUTABLE_ENTITIES[type].default;
    }

    return DEFAULT_TEMPLATE;
}

export default templateType;
