import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { truncateOnWordBoundary } from 'app/utilities/truncate';
import { metaTime, publishedTime } from 'app/utilities/date-time';

const CHARACTER_LIMIT = {
    TITLE: 90
};

class ArticleTile extends React.Component {
    render() {
        const { isFeatured, item } = this.props;

        let style = {};

        const {
            author,
            name,
            publish_at: publishDate,
            thumbnail
        } = item.data;

        if (thumbnail) {
            style = { backgroundImage: `url(${thumbnail})` };
        }

        return (
            <article className={`article-tile ${isFeatured ? 'featured' : ''}`}>
                <EntityLink
                    item={item}
                    promoterBuName="Auckland Zoo"
                    className="tile-inner || image-overlay"
                    style={style}
                >
                    <div className="heading-group">
                        <span className="flag"><Icon name="chevron" /></span>
                        <h3 className="title">{truncateOnWordBoundary(name, CHARACTER_LIMIT.TITLE)}</h3>
                        <p className="meta">
                            {publishDate && <time dateTime={metaTime(publishDate)}>{publishedTime(publishDate)}</time>}
                            {author && <span className="author">by {author}</span>}
                        </p>
                    </div>
                </EntityLink>
            </article>
        );
    }
}

ArticleTile.propTypes = {
    isFeatured: false
};

ArticleTile.propTypes = {
    isFeatured: PropTypes.bool,
    item: PropTypes.object.isRequired
};

export default ArticleTile;
