/* eslint-disable camelcase */
import CheckBox from 'app/components/partials/checkbox';
import { clientFetch } from 'app/utilities/fetch';
import { createValidationDataObject } from 'app/utilities/form-validation';
import DatePicker from 'app/components/partials/date-picker';
import { displayDateOfBirthFormat } from 'config/buy-memberships-passholder-details';
import { generatePassHolders } from 'app/ducks/buy-memberships';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import { MEMBERS_PORTAL_ROUTES } from 'app/utilities/routes';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS, ERROR_MESSAGE } from 'config/api';
import { generatePassHoldersData, generatePassholdersFormData } from 'app/utilities/buy-memberships';
import React, { useState } from 'react';


const PassHolderDetailsForm = ({ products, voucher }) => {
    const [passholdersFormValue, setPasshodersFormValue] = useState(generatePassHolders(products));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const [hasCareGiver, setHasCareGiver] = useState(false);

    const navigate = useNavigate();
    const formData = generatePassholdersFormData(products);

    const getFieldValueAndTouched = (product, passIndexByProduct, fieldName) => {
        const pass = passholdersFormValue
            .find((pass) => pass.product === product && pass.passIndexByProduct === passIndexByProduct);

        return pass.formFields.find((field) => field.fieldName === fieldName);
    };

    const setPassHoldersFieldValue = (product, passIndexByProduct, fieldName, value) => {
        setPasshodersFormValue(passholdersFormValue.map((pass) => {
            if (pass.product === product && pass.passIndexByProduct === passIndexByProduct) {
                return {
                    ...pass,
                    formFields: pass.formFields.map((field) => {
                        if (field.fieldName === fieldName) {
                            return {
                                ...field,
                                value,
                                // fix date picker not triggering the blur event on the input field if we click on the icon instead of the field
                                touched: true
                            };
                        }

                        return field;
                    })
                };
            }

            return pass;
        }));
    };

    const touchedPassHoldersField = (product, passIndexByProduct, fieldName) => {
        setPasshodersFormValue(passholdersFormValue.map((pass) => {
            if (pass.product === product && pass.passIndexByProduct === passIndexByProduct) {
                return {
                    ...pass,
                    formFields: pass.formFields.map((field) => {
                        if (field.fieldName === fieldName) {
                            return {
                                ...field,
                                touched: true
                            };
                        }

                        return field;
                    })
                };
            }

            return pass;
        }));
    };

    const validateForm = () => {
        const invalid = formData.some(({ product, formFieldsGroup }) => {
            return formFieldsGroup.some((group, index) => {
                const passIndexByProduct = index;

                return group.some(({ name, validator }) => !validator(getFieldValueAndTouched(product, passIndexByProduct, name).value).valid);
            });
        }, []);

        return invalid;
    };

    const activateMembershipSubmit = async(event) => {
        event.preventDefault();
        setStatus(null);
        setIsSubmitting(true);

        try {
            const generatedPassHoldersData = generatePassHoldersData(passholdersFormValue);
            await clientFetch(ENDPOINTS.REDEEM_GIFT(voucher), {
                method: 'POST',
                body: JSON.stringify({
                    pass_holders: generatedPassHoldersData,
                    requires_caregiver: hasCareGiver
                })
            }, true);

            navigate(MEMBERS_PORTAL_ROUTES.DASHBOARD, { replace: true });
        } catch (err) {
            const { error } = err.error;

            const errorMessage =
                    error &&
                    error.message || ERROR_MESSAGE.DEFAULT;

            setStatus({ error: errorMessage });
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderFormField = (
        FIELD,
        setValue,
        setTouched,
        product,
        passIndexByProduct
    ) => {
        const { name: fieldName, label, validator, type, placeholder, isRequired } = FIELD;
        const { value, touched } = getFieldValueAndTouched(product, passIndexByProduct, fieldName);

        const validationData = touched ? validator(value) : createValidationDataObject();
        const formFieldId = `${product}-${passIndexByProduct}-${fieldName}`;

        const fieldData = {
            key: formFieldId,
            id: formFieldId,
            name: fieldName,
            label,
            type,
            placeholder,
            value,
            onChangeHandler: (event) => setValue(fieldName, event.target.value),
            onBlurHandler: () => {
                if (!touched) {
                    setTouched(fieldName);
                }
            },
            isRequired,
            error: validationData.valid ? '' : validationData.message
        };

        return type === 'date' ? <DatePicker { ...fieldData } displayDateFormat={displayDateOfBirthFormat} disableFutureDates disableToday /> : <Input { ...fieldData } />;
    };

    const renderSecionByProduct = (
        product,
        description,
        formFieldsGroup
    ) => {
        return formFieldsGroup.map((formFields, passIndexByProduct) => {
            const setValue = (fieldName, value) => setPassHoldersFieldValue(product, passIndexByProduct, fieldName, value);
            const setTouched = (fieldName) => touchedPassHoldersField(product, passIndexByProduct, fieldName);

            return (
                <div className="section" key={passIndexByProduct}>
                    <h4 className="title with-tail">
                        {`${product} ${passIndexByProduct + 1}`}
                        <small>{description}</small>
                    </h4>
                    <div className="form-group">
                        {formFields.map((field) =>
                            renderFormField(field, setValue, setTouched, product, passIndexByProduct)
                        )}
                    </div>
                </div>
            );
        });
    };


    return (
        <form className="pass-details-form" onSubmit={activateMembershipSubmit}>
            {formData.map(({ product, description, formFieldsGroup }) => renderSecionByProduct(product, description, formFieldsGroup))}
            <div className="section">
                <CheckBox
                    name="supportPerson"
                    type="checkbox"
                    inputType="large"
                    label="<strong>Support person</strong><br>For members who require assistance to visit the zoo, a free support person pass is given at the discretion of our friendly staff"
                    value={hasCareGiver}
                    onChangeHandler={(event) => setHasCareGiver(event.target.checked)}
                />
            </div>
            {status && status.error && <div className="error-message" dangerouslySetInnerHTML={{ __html: status.error }}></div>}
            <button className="button primary" type="submit" disabled={validateForm() || isSubmitting}>
                {isSubmitting ? <Loader type="small" /> : 'Activate Membership'}
            </button>
        </form>
    );
};

PassHolderDetailsForm.propTypes = {
    products: PropTypes.array.isRequired,
    voucher: PropTypes.string.isRequired
};

export default PassHolderDetailsForm;
