import AnnualPassOption from './portal/annual-pass-option';
import { canPurchaseTicket } from 'app/utilities/buy-memberships';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import { isAppProd } from 'config/env';
import { Link } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';

const TicketsSection = () => {
    const user = useSelector((state) => state.auth.user);
    const userCanBuyTicket = canPurchaseTicket(user);

    // Render link for annual pass membership description
    const renderMoreInfoLink = () => {
        return (
            <Link
                to="/visit/membership">
                More info
            </Link>
        );
    };

    const renderDayPassCategory = () => {
        const dayPassBackground = 'https://cdn.aucklandunlimited.com/zoo/assets/media/day-pass-background.jpeg';

        return (
            <div className="flexi-item ticket day-pass">
                <div className="media" style={{ backgroundImage: `url(${dayPassBackground})` }}>
                    <div className="preserve-image-ratio">
                        <img className="fixed-image" src={dayPassBackground} alt="Day Pass" />
                    </div>
                </div>
                <div className="title-group annual-pass-wrapper light">
                    <div className="annual-pass-description">
                        <h2 className="title-group-heading">Day Pass</h2>
                        <div className="title-group-content">
                            <p className="ticket-category-description">There's lots to see and do at Auckland Zoo, all year round! Thanks to the generous support from Auckland Council, we can offer a subsidised ticket price to visitors who live in Auckland and book a minimum of one day in advance. For all visitors, visiting us during off-peak times (<i>during NZ school terms</i>) means you pay less than peak time (<i>weekends, public and school holidays</i>).</p>
                        </div>
                    </div>
                    <div className="annual-pass-options-wrapper">
                        <AnnualPassOption
                            from="tickets-section"
                            title="I live in Auckland"
                            price="19"
                            image="https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_NZ+Resident.jpg"
                            link={isAppProd ? 'https://tickets.aucklandzoo.co.nz/events/27f13cef-509f-984e-4b34-3dd9f91bdb99?tg=19aed43f-63d4-643c-741e-e4f342b15023&tg=eb19e667-9921-3fb5-b21e-a102420a05eb' : 'https://aucklandzoo.web.d1tix.com/phase2/events/0188d23c-0465-5cb7-2c64-44ea6d869608?tg=0188d23c-046b-4fd0-4b33-d6ef176acc9c&tg=0188d23c-046b-124a-061d-f52bfe72eb18'}
                        />
                        <AnnualPassOption
                            from="tickets-section"
                            title="I do not live in Auckland"
                            price="29"
                            image="https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_International.jpg"
                            link={isAppProd ? 'https://tickets.aucklandzoo.co.nz/events/27f13cef-509f-984e-4b34-3dd9f91bdb99?tg=66f6b6c3-79c5-c443-e895-8472b169a5b7&tg=bb25b079-f93c-cfcf-b46c-061af61f9359' : 'https://aucklandzoo.web.d1tix.com/phase2/events/0188d23c-0465-5cb7-2c64-44ea6d869608?tg=0188d23c-046b-b9c1-308a-9fdda1b85c07&tg=d01cbb4b-7a8d-6fe0-b041-8a718d21788c'}
                        />
                        {/* Hide gift option for now (we don't remove because that will ruin the layout) */}
                        <div className="day-pass-gift">
                            <AnnualPassOption
                                from="tickets-section"
                                title="Gift a Day Pass"
                                subtitle="For NZ residents only"
                                price="24"
                                image="https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Gift+Day+Pass.jpg"
                                link="https://aucklandzoo.rezdy.com/358483/gift-general-admission"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAnnualPassCategory = () => {
        const name = 'Annual Pass Membership';
        const imageSrc = 'https://cdn.aucklandunlimited.com/zoo/assets/media/giraffe-kiraka-pregnant-gallery-4.jpg';

        return (
            <div className="flexi-item ticket annual-pass">
                <div className="media" style={{ backgroundImage: `url(${imageSrc})` }}>
                    <div className="preserve-image-ratio">
                        <img className="fixed-image" src={imageSrc} alt={name} />
                    </div>
                </div>
                <div className="title-group annual-pass-wrapper light">
                    <div className="annual-pass-description">
                        <h2 className="title-group-heading">{name}</h2>
                        <div className="title-group-content">
                            <p className="ticket-category-description">Auckland Zoo’s Annual Pass Membership gives you and your whānau unlimited entry to the Zoo 364 days a year and other great benefits. Plus, you’ll be contributing to Auckland Zoo’s conservation work at the Zoo and in the wild. {renderMoreInfoLink()}</p>
                        </div>
                    </div>
                    <div className="annual-pass-options-wrapper">

                        <AnnualPassOption
                            from="tickets-section"
                            title="Become a member"
                            price="49"
                            image="https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Become+a+Member.jpg"
                            link="/select-pass"
                            isVisible={userCanBuyTicket}
                        />

                        <AnnualPassOption
                            from="tickets-section"
                            title="Renew a membership"
                            price="36.75"
                            image="https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Renew+Membership.jpg"
                            link={FRONTEND_ROUTES.RENEW_MEMBERSHIP}
                            mediaStyle={{ 'background-position-y': '20%' }}
                        />

                        <AnnualPassOption
                            from="tickets-section"
                            title="Gift a membership"
                            price="49"
                            image="https://cdn.aucklandunlimited.com/zoo/assets/static/AZ_Ticketing_Image+Tiles_Gift+Membership.jpg"
                            link={FRONTEND_ROUTES.GIFT_MEMBERSHIP}
                            mediaStyle={{ 'background-position-y': '20%' }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section className="tickets-section || flexi-block">
            <div className="constrain-width large">
                <div className="inner">
                    {renderDayPassCategory()}
                    {renderAnnualPassCategory()}
                </div>
            </div>
        </section>
    );
};

TicketsSection.propTypes = {
};

export default TicketsSection;
