import formatContent from 'app/utilities/format-content';
import { normalizeString } from 'app/utilities/normalize-string';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const DualTab = ({ data }) => {
    const tabHeadings = data.map((tabItem) => normalizeString(tabItem.heading));
    const [selectedTabItem, setSelectedTabItem] = useState(tabHeadings[0]);

    const tabTitleClickHandler = (selectedTabItem) => {
        const normalizedHeading = normalizeString(selectedTabItem.heading);
        setSelectedTabItem(normalizedHeading);
        window.location.hash = normalizedHeading;
    };

    const renderTabContent = () => {
        const selectedTab = data.find((tabItem) => normalizeString(tabItem.heading) === selectedTabItem);

        return (
            <div className="tab-content">
                <div className="content || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(selectedTab.content) }}></div>
                { selectedTab.file && <img className="image" src={selectedTab.file} alt={selectedTab.heading} />}
            </div>
        );
    };

    useEffect(() => {
        const urlHash = window.location.hash.split('#')[1];
        if (urlHash && tabHeadings.includes(urlHash)) {
            setSelectedTabItem(urlHash);
        } else {
            window.location.hash = '';
        }
    }, []);

    return (
        <section className="section || dual-tab-block">
            <div className="constrain-width large">
                <div className="inner">
                    <div className="tab-titles">
                        {data.map((tabItem, index) => (
                            <button key={index} className={`title ${selectedTabItem === normalizeString(tabItem.heading) ? 'is-selected' : ''}`} onClick={tabTitleClickHandler.bind(this, tabItem)}>
                                <h2>{tabItem.heading}</h2>
                            </button>
                        ))}
                    </div>
                </div>
                {renderTabContent()}
            </div>
        </section>
    );
};

DualTab.propTypes = {
    data: PropTypes.array.isRequired
};

export default DualTab;
