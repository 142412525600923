import React from 'react';

/**
 * Generates news article JSON-LD.
 *
 * @param  {object} pageData  News page data
 *
 * @return {object}           Structured data object.
 */
const article = (pageData) => ({
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `https://www.aucklandzoo.co.nz/news/${pageData.slug}`
    },
    headline: pageData.name,
    image: pageData.main_images && pageData.main_images.length && pageData.main_images || pageData.thumbnail || [],
    datePublished: pageData.publish_at,
    dateModified: pageData.publish_at,
    author: {
        '@type': 'Person',
        name: pageData.author,
        image: pageData.author_image,
        description: pageData.author_occupation
    },
    publisher: {
        '@type': 'Organization',
        name: 'Auckland Zoo',
        logo: {
            '@type': 'ImageObject',
            url: 'https://lh6.googleusercontent.com/-n-XQr7d80hI/AAAAAAAAAAI/AAAAAAAAAAA/_l9lmFf58WQ/s88-p-k-no-ns-nd/photo.jpg'
        },
        url: 'https://www.aucklandzoo.co.nz/'
    },
    description: pageData.description
});

/**
 * Article markup guide can be found here:
 * https://developers.google.com/search/docs/data-types/article#non-amp
 *
 * @param  {object} pageData  News page data
 *
 * @return {object}           JSX with the structured data
 */
export default (pageData) => ( // eslint-disable-line react/display-name
    <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(article(pageData)) }}
    />
);
