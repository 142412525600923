import { connect } from 'react-redux';
import { fetchNewsByCategoryAction } from 'app/ducks/news-by-category';
import { fetchNewsCategoriesAction } from 'app/ducks/news-categories';
import NewsByCategory from 'app/components/partials/news-by-category';
import { selectNews } from 'app/selectors/news';
import { selectNewsCategories } from 'app/selectors/news-categories';
import {
    selectNewsByCategory,
    selectNewsByCategoryActive,
    selectNewsByCategoryIsBusy,
    selectNewsByCategoryPage,
    selectNewsByCategoryResultCount
} from 'app/selectors/news-by-category';

const mapStateToProps = (state) => ({
    data: selectNewsByCategory(state),
    isBusy: selectNewsByCategoryIsBusy(state),
    resultCount: selectNewsByCategoryResultCount(state),
    categories: selectNewsCategories(state),
    currentCategory: selectNewsByCategoryActive(state),
    page: selectNewsByCategoryPage(state),
    itemsInBanner: selectNews(state)
});

const mapDispatchToProps = (dispatch) => ({
    loadData: (category, page) => dispatch(fetchNewsByCategoryAction(category, page)),
    loadCategories: () => dispatch(fetchNewsCategoriesAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsByCategory);
