import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const PortalAuthCredentials = ({ children, imgSrc, title }) => (
    <main role="main" className="main">
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <div className="page || page-view || no-banner">
            <div className="portal-credential-board || constrain-width">
                <div className="inner">
                    <div className="form-container">
                        <div className="form-section">
                            {children}
                            <div className="link-container">
                                <h4 className="heading-4">Not a member yet?</h4>
                                <Link className="link" to="/tickets">
                                    Get an annual pass now
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="bg-image">
                        <img className="image" src={imgSrc} alt={title} />
                    </div>
                </div>
            </div>
        </div>
    </main>
);

PortalAuthCredentials.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    imgSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default PortalAuthCredentials;
