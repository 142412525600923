/* eslint-disable camelcase */
import Audio from 'app/components/content-blocks/audio';
import Banner from 'app/components/partials/banner';
import CallToAction from 'app/components/content-blocks/call-to-action';
import Carousel from 'app/components/content-blocks/carousel';
import ColumnCallToAction from '../content-blocks/column-call-to-action';
import ContentSet from 'app/components/content-blocks/content-set';
import DualTab from 'app/components/content-blocks/dual-tab';
import Encounters from 'app/components/content-blocks/encounters.container';
import Faq from 'app/components/content-blocks/faq';
import FileDownload from 'app/components/content-blocks/file-download';
import Flexi from 'app/components/content-blocks/flexi';
import Gallery from 'app/components/content-blocks/gallery';
import Icon from 'app/components/partials/icon';
import Introduction from 'app/components/content-blocks/introduction';
import Jotform from 'app/components/content-blocks/jotform';
import MOCK_DATA from 'config/mock-data';
import PagebreakQuote from 'app/components/content-blocks/pagebreak-quote';
import Rezdy from 'app/components/content-blocks/rezdy';
import RollingText from 'app/components/content-blocks/rolling-text';
import StacklaTabs from 'app/components/content-blocks/stackla-tabs';
import StacklaWidget from 'app/components/content-blocks/stackla-widget';
import { TICKET_TYPES } from '../../../config/rezdy';
import TileGrid from 'app/components/partials/tile-grid';
import React, { Component } from 'react';

// All the icons from /static/icons – except the sprite.svg
const ICONS = {
    GLOBAL: [
        'alert',
        'bell',
        'chevron',
        'clock',
        'close',
        'closure',
        'discount',
        'exit-fullscreen',
        'file',
        'fullscreen',
        'hamburger',
        'image',
        'message',
        'maintenance',
        'minus',
        'parking',
        'phone',
        'pointer',
        'plus',
        'reset',
        'search',
        'ticket',
    ],
    SOCIAL: [
        'facebook',
        'instagram',
        'twitter',
        'youtube'
    ]
};

class StyleGuidePage extends Component {
    render() {
        return (
            <main className="main" role="main">
                <Banner title="Style Guide" introduction="A working site style guide built to manage design and code quality control" />

                <div className="content-blocks">
                    <section className="section || text-block">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>Colour</h2>
                                    <hr />

                                    <div className="style-guide-colours" role="group">
                                        {/* Primary, Secondary */}
                                        <div className="item small primary">
                                            <div className="item-inner">
                                                <p className="title">Primary</p>
                                                <div className="variations">
                                                    <div className="tint-1"></div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Dark */}
                                        <div className="item small dark">
                                            <div className="item-inner">
                                                <p className="title">Dark</p>
                                                <div className="variations">
                                                    <div className="tint-1"></div>
                                                    <div className="tint-2"></div>
                                                    <div className="tint-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Light */}
                                        <div className="item small outline light">
                                            <div className="item-inner">
                                                <p className="title">Light</p>
                                                <div className="variations">
                                                    <div className="shade-2"></div>
                                                    <div className="shade-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section || text-block">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>Typography</h2>
                                    <hr />

                                    <h1>Heading 1</h1>
                                    <h2>Heading 2</h2>
                                    <h3>Heading 3</h3>
                                    <h4>Heading 4</h4>
                                    <h5>Heading 5</h5>
                                    <h6>Heading 6</h6>

                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                                    <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue.</p>
                                    <p>Nihil imperdiet doming id <a href="/" title="Link">quod mazim placerat</a> facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>

                                    <p>Really Long link: <a href="/">http://www.aucklandzoo.co.nz/sites/experiences/photography-workshops</a></p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section || text-block">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>Icons</h2>
                                    <hr />

                                    <h4>Global</h4>
                                    <div className="style-guide-icons">
                                        {ICONS.GLOBAL.map((item, index) => {
                                            return <Icon name={item} key={index} />;
                                        })}
                                    </div>

                                    <h4>Social</h4>
                                    <div className="style-guide-icons">
                                        {ICONS.SOCIAL.map((item, index) => {
                                            return <Icon name={item} key={index} />;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section || text-block">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>Buttons</h2>
                                    <hr />

                                    <div className="style-guide-buttons">
                                        <a className="button large" href="/">Button</a>
                                        <button type="button" className="button">Button</button>
                                        <a className="button small" href="/">Button</a>

                                        <br />
                                        <br />

                                        <a className="button primary large" href="/">Primary Button</a>
                                        <button type="button" className="button primary">Primary Button</button>
                                        <a className="button primary small" href="/">Primary Button</a>

                                        <br />
                                        <br />

                                        <a className="button primary large" href="/">
                                            Primary Button with icon
                                            <Icon name="chevron" />
                                        </a>
                                        <button type="button" className="button primary">
                                            Primary Button with icon
                                            <Icon name="chevron" />
                                        </button>
                                        <a className="button primary small" href="/">
                                            Primary Button with icon
                                            <Icon name="chevron" />
                                        </a>

                                        <br />
                                        <br />

                                        <a className="button is-disabled" href="/">Disabled Button</a>
                                        <button type="button" className="button" disabled>Disabled Button</button>

                                        {/* TODO: Add when icons/asset paths have been resolved
                                        <br />
                                        <br />

                                        <button type="button" className="button">
                                            Button
                                            <svg width="40" height="40" viewBox="0 0 40 40" aria-hidden="true" focusable="false">
                                                <title>Example icon</title>
                                                <use xlinkHref="/static/assets/icons/icons.svg#icon-name"></use>
                                            </svg>
                                        </button>

                                        <br />
                                        <br />

                                        <button type="button" className="close-button">Close</button>
                                        */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section || text-block">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>Lists</h2>
                                    <hr />

                                    <h4>Unordered List:</h4>
                                    <ul>
                                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="/">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                                    </ul>
                                    <h4>Ordered List:</h4>
                                    <ol>
                                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="/">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section || text-block">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>Blockquotes</h2>
                                    <hr />

                                    <blockquote><p>Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p></blockquote>
                                    <cite>- Author&rsquo;s Name, 2020</cite>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="style-guide-section">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>Tile Grid: Articles and Pages</h2>
                                    <hr />
                                </div>

                                <TileGrid items={MOCK_DATA.CONTENT_SET_ITEMS} />
                            </div>
                        </div>
                    </section>

                    <section className="style-guide-section" id="news-grid">
                        <div className="constrain-width large">
                            <div className="inner">
                                <div className="content">
                                    <h2>News Grid</h2>
                                    <hr />
                                </div>

                                <TileGrid items={MOCK_DATA.NEWS_ITEMS} />
                            </div>
                        </div>
                    </section>

                    <div className="constrain-width large">
                        <h2>Content set</h2>
                        <hr />
                        <p>The tiles use Thumbnail image images as backgrounds. In the CMS, please use images of dimensions 960px x 640px when selecting Thumbnail image images for pages and animals.</p>
                    </div>
                    <ContentSet {...MOCK_DATA.CONTENT_SET_PROPS} />

                    <div className="constrain-width large">
                        <h2>Image carousel</h2>
                        <hr />
                        <p>In the CMS, please upload images of dimensions 1920px x 1280px and max size 500kb when creating Image carousel content blocks.</p>
                    </div>
                    <Carousel items={MOCK_DATA.CAROUSEL_ITEMS} />

                    <div className="constrain-width large">
                        <h2>Image gallery</h2>
                        <hr />
                        <p>In the CMS, images of any dimensions and max size 500kb can be uploaded.</p>
                    </div>
                    <Gallery items={MOCK_DATA.GALLERY_ITEMS} />

                    <div className="constrain-width large">
                        <h2>Page break with quote</h2>
                        <hr />
                        <p>In the CMS, please upload images of dimensions 1920px x 1280px when creating Page break with quote content blocks.</p>
                    </div>
                    <PagebreakQuote {...MOCK_DATA.PAGE_BREAK_QUOTE_PROPS} />

                    <div className="constrain-width large">
                        <h2>Call to action</h2>
                        <hr />
                        <p>In the CMS, please upload images of dimensions 1920px x 1280px and max size 500kb when creating Call to action content blocks.</p>
                    </div>
                    <CallToAction {...MOCK_DATA.CALL_TO_ACTION_PROPS} />

                    <div className="constrain-width large">
                        <h2>Introduction</h2>
                        <hr />
                        <p>In the CMS, please upload images of dimensions 500px x 450px and max size 150kb when creating Introduction content blocks.</p>
                    </div>
                    <Introduction {...MOCK_DATA.INTRODUCTION_PROPS} />

                    <div className="constrain-width large">
                        <h2>Flexi</h2>
                        <hr />
                        <p>In the CMS, please use images of dimensions 1920px x 1280px and max size 500kb when creating Flexi content blocks.</p>
                    </div>
                    <Flexi items={MOCK_DATA.FLEXI_ITEMS} />

                    <div className="constrain-width large">
                        <h2>FAQ</h2>
                    </div>
                    <Faq items={[
                        {
                            title: 'How do we make a booking for an education visit?',
                            heading: '',
                            content: '<ul>\r\n' +
                              '\t<li>Visit the <a href="https://www.aucklandzoo.co.nz/visit/education">Education</a> page on our website</li>\r\n' +
                              '\t<li>Scroll down to select the relevant age group</li>\r\n' +
                              '\t<li>Click Book Now</li>\r\n' +
                              '\t<li>Simply complete the online booking form and one of the Education team will be in touch</li>\r\n' +
                              '</ul>\r\n'
                        },
                        {
                            title: 'How can we find out what dates are available?',
                            heading: '',
                            content: '<ul>\r\n' +
                              '\t<li>Visit the <a href="https://www.aucklandzoo.co.nz/visit/education">Education</a> page on our website</li>\r\n' +
                              '\t<li>Scroll down to select the relevant age group</li>\r\n' +
                              '\t<li>Click Book Now</li>\r\n' +
                              '\t<li>Simply complete the online booking form and one of the Education team will be in touch</li>\r\n' +
                              '</ul>\r\n' +
                              '\r\n' +
                              '<p>Visits that include an education session must be booked at least 3 weeks in advance.<br />\r\n' +
                              'Self-guided visits must be booked at least 5 working days in advance.</p>\r\n' +
                              '\r\n' +
                              '<p>This is so the Education team can work with you to ensure you have the best possible visit as well as following best practice health and safety protocols.</p>\r\n'
                        },
                        {
                            title: 'What is the minimum number of supervising adults we need to meet Auckland Zoo’s health and safety requirements?',
                            heading: '',
                            content: '<p>Ratio of adult to students</p>\r\n' +
                              '\r\n' +
                              '<ul>\r\n' +
                              '\t<li>Early childhood&nbsp;&nbsp;&nbsp;&nbsp; 1:3</li>\r\n' +
                              '\t<li>Years 0 &ndash; 6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1:6</li>\r\n' +
                              '\t<li>Years 7 &ndash; 10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1:15</li>\r\n' +
                              '\t<li>Years 11 &ndash; 13&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1:30</li>\r\n' +
                              '</ul>\r\n' +
                              '\r\n' +
                              '<p>If these minimum supervision requirements are not met your group will not be able to enter Auckland Zoo.</p>\r\n'
                        },
                    ]} />

                    <div className="constrain-width large">
                        <h2>Encounters</h2>
                        <hr />
                        <p>The tiles use Thumbnail image images as backgrounds. In the CMS, please use images of dimensions 960px x 640px when selecting Thumbnail image images for pages and animals.</p>
                    </div>
                    <Encounters initialData={MOCK_DATA.ENCOUNTERS_INITIAL_DATA} />

                    <div className="constrain-width large" id="stackla-widget">
                        <h2>Stackla Widget</h2>
                        <hr />
                        <p></p>
                    </div>
                    <StacklaWidget {...MOCK_DATA.STACKLA_WIDGET} />

                    <div className="constrain-width large" id="stackla-tabs">
                        <h2>Stackla Tabs</h2>
                        <hr />
                        <p></p>
                    </div>
                    <StacklaTabs {...MOCK_DATA.STACKLA_TABS}/>

                    <div className="content-blocks">
                        <FileDownload
                            display_name="Example File Download"
                            extension=".jpg"
                            file="https://www.example-file.com"
                            filename="Example File Name"
                            filesize="30"
                        />

                        <Audio soundCloudTrackID="438848052" />
                        <Audio soundCloudTrackID="434154456" />
                    </div>
                    <ColumnCallToAction {...MOCK_DATA.COLUMN_CALL_TO_ACTION} />

                    <div className="constrain-width large">
                        <h2>Dual Tab</h2>
                        <hr />
                    </div>
                    <DualTab {...MOCK_DATA.DUAL_TAB_PROPS} />

                    <div className="constrain-width large">
                        <h2>Rolling text</h2>
                        <hr />
                    </div>
                    <RollingText data={MOCK_DATA.ROLLING_TEXT_ITEMS} />

                    <Rezdy {...TICKET_TYPES[0]} />

                    <div className="constrain-width large" id="jotform">
                        <h2>Jotform</h2>
                        <hr />
                        <p></p>
                    </div>
                    <Jotform form_id="91947894683883" />
                </div>
            </main>
        );
    }
}

export default StyleGuidePage;
