import { NAVIGATION_FOOTER } from 'config/navigation';
import { NavLink } from 'react-router-dom';
import React from 'react';

const FooterNavigation = () => (
    <nav className="footer-navigation" role="group">
        {NAVIGATION_FOOTER.map(({ title, children }) => (
            <div key={title} className="item" role="group">
                <h5 className="title">{title}</h5>
                {children.map(({ label, link, internal }) => {
                    if (internal) {
                        return <NavLink key={label} className="link" to={link}>{label}</NavLink>;
                    }

                    return <a key={label} className="link" href={link}>{label}</a>;
                })}
            </div>
        ))}
    </nav>
);

export default FooterNavigation;
