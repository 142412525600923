/* eslint-disable require-jsdoc */
import { setInternalErrorAction } from 'app/ducks/status';
import { store } from 'app/app';
import { userLogoutAction } from 'app/ducks/auth';
import { ERROR_MESSAGE, RESPONSE, TIMEOUT } from 'config/api';
import 'isomorphic-fetch';
// const SENTRY = require('config/sentry');

// Parse JSON without throwing error
function safeParseJson(text) {
    try {
        return JSON.parse(text);
    } catch (e) {
        // Never return empty object as that can cause pre-route into an infinite loop
        return {
            errors: 'Could not parse response in JSON',
        };
    }
}

function Api(url, token = null, options = { method: 'GET' }) {
    return new Promise((resolve, reject) => {
        authoriseApiRequest(url, token, options)
            .then((response) => {
                if ((response.status >= RESPONSE.OK) && (response.status < RESPONSE.BAD_REQUEST)) {
                    // winston.info('Response 200:', response.url);

                    // Using this instead of resolve(response.json()) because of
                    // https://github.com/github/fetch/issues/268
                    response.text().then((text) => {
                        resolve(safeParseJson(text));
                    });
                } else if (response.status >= RESPONSE.BAD_REQUEST)
                    response.text().then((text) => {
                        reject({ status: response.status, error: safeParseJson(text) });
                    });
            })
            .catch((error) => {
                // Sentry error logging
                // Raven.config(SENTRY.DSN).install();
                // Raven.captureException(error);

                reject({ status: RESPONSE.INTERNAL_SERVER_ERROR, error });
            });
    });
}

function authoriseApiRequest(url, token, options) {
    // winston.info('Token for request:', token.access_token);
    // console.log(JSON.stringify(token))
    let headers = {};

    if (token) {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: `${token.token_type} ${token.access_token}`
        };
    }

    return fetch(url, {
        method: options.method,
        headers,
        timeout: TIMEOUT,
        ...options.method !== 'GET' && { body: options.body }
    });
}

export const clientFetch = async(url, options, takeCookieWithRequest = false) => {
    try {
        const response = await Api(url, null, {
            ...options,
            ...takeCookieWithRequest ? { credentials: 'same-origin' } : {}
        });

        return response;
    } catch (error) {
        // Handler internal error here
        if (error.status === RESPONSE.INTERNAL_SERVER_ERROR) {
            store.dispatch(setInternalErrorAction({
                message: ERROR_MESSAGE.INTERNAL_SERVER_ERROR }));
        }

        // Handler 422 error here
        if (error.status === RESPONSE.UNPROCESSABLE_ENTITY) {
            store.dispatch(setInternalErrorAction({
                message: `${ERROR_MESSAGE.INTERNAL_SERVER_ERROR}(${RESPONSE.UNPROCESSABLE_ENTITY})`
            }));
        }

        // If the salesforce contact could not be found for the currently logged in account
        if (error.status === RESPONSE.CONFLICT) {
            store.dispatch(setInternalErrorAction({
                message: error.error.error.message,
            }));
            store.dispatch(userLogoutAction());
        }

        const state = store.getState();
        // Handle 401 error here if protected request and authed user
        if (takeCookieWithRequest && error.status === RESPONSE.UNAUTHORIZED && state.auth.isAuth) {
            store.dispatch(userLogoutAction());
        }

        throw error;
    }
};

export default Api;
