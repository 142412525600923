import PropTypes from 'prop-types';
import React from 'react';

class PagebreakQuote extends React.Component {
    render() {
        const { cite, quote, file } = this.props;

        if (!quote) return null;

        const style = file ? { backgroundImage: `url(${file})` } : {};

        return (
            <section className="section || pagebreak-quote-block || constrain-width large">
                <div className={`pagebreak-quote-block-inner ${file ? '' : 'dark'}`} style={style}>
                    <div className="pagebreak-quote-block-quote">
                        <blockquote>
                            <p>{quote}</p>
                        </blockquote>
                        <cite>{cite}</cite>
                    </div>
                </div>
            </section>
        );
    }
}

PagebreakQuote.propTypes = {
    cite: PropTypes.string.isRequired,
    file: PropTypes.string,
    quote: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired
};

PagebreakQuote.defaultProps = {
    theme: 'blue'
};

export default PagebreakQuote;
