export const zooSnaps = {
    widgetId: '5a976ceb480df',
    all: { hash: '5ab41946330c7', id: '39194' },
    winners: { hash: '5ab2cf4376416', id: '39145' }
};

export const createWithAucklandZoo = {
    widgetId: '5eccb930e486d',
    all: null,
    winners: null
};
