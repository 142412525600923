/* eslint-disable camelcase */
import { clientEnv } from 'config/env';
import { clientFetch } from 'app/utilities/fetch';
import { generatePassHoldersData } from 'app/utilities/buy-memberships';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import React from 'react';
import { setExtraDiscountAction } from 'app/ducks/buy-memberships';
import { ENDPOINTS, ERROR_MESSAGE, RESPONSE } from 'config/api';
import { useDispatch, useSelector } from 'react-redux';

const GaDiscountRedeem = ({ isCreateAccountFormSubmmiting, promoCodeInputValue, setPromoCodeInputValue, setRedeemDiscountStatus, redeemDiscountStatus, setCreateAccountFormFieldValue, setHelperMessage }) => {
    const { passHolders, extraDiscount } = useSelector((state) => state.buyMemberships);
    const { isAuth, user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const resetRedeemDiscountInputStatus = () => {
        if (extraDiscount && extraDiscount.ga) dispatch(setExtraDiscountAction('ga'));
        setRedeemDiscountStatus(null);
        setPromoCodeInputValue('');
    };

    const redeemDiscountInputChangeHandler = (value) => {
        setPromoCodeInputValue(value);
        setRedeemDiscountStatus(null);
    };

    const onRedeemCodeBtnClickHandler = async(event) => {
        event.preventDefault();
        const trimedPromoCode = promoCodeInputValue.trim();

        if (!trimedPromoCode) return;

        setRedeemDiscountStatus('pending');
        setHelperMessage(null);

        try {
            // eslint-disable-next-line no-undef
            const captchaToken = await grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'verify_promocode' });
            const { customer, discount } = await clientFetch(ENDPOINTS.REDEEM_GA_DISCOUNT, {
                method: 'POST',
                body: JSON.stringify({
                    pass_holders: generatePassHoldersData(passHolders),
                    ga_promo_code: trimedPromoCode,
                    captchaToken,
                    ...isAuth ? { email: user.email } : {} // For logged in user, also need to check whether email match
                })
            });

            dispatch(setExtraDiscountAction('ga', discount, trimedPromoCode, customer && customer.email));
            setCreateAccountFormFieldValue('email', customer && customer.email);
            setRedeemDiscountStatus('redeemed');
        } catch (err) {
            const { error, status } = err;

            const errorMessage =
                status === RESPONSE.BAD_REQUEST &&
                error &&
                error.error &&
                error.error.message || ERROR_MESSAGE.DEFAULT;

            setRedeemDiscountStatus({ error: errorMessage });
            dispatch(setExtraDiscountAction('ga'));
        } finally {
            setPromoCodeInputValue('');
        }
    };

    return (
        <div className="section">
            <h4 className="title">Day Pass Upgrade Discount</h4>
            <p className="subtitle">Have you visited the zoo on a day pass in the past 30 days? Enter the discount code or the order number from your day pass ticket to apply a discount. Offer is valid from 17th Jun 2024 for online redemption. Otherwise, you can also upgrade your ticket by visiting the Membership Hub onsite</p>
            {extraDiscount && extraDiscount.ga ?
                <div className="discount-info">
                    <p className="name">{extraDiscount.ga.code}</p>
                    <button className="button secondary" onClick={resetRedeemDiscountInputStatus}>Remove Discount</button>
                </div>
                :
                <form className="redeem-ga-discount-form" onSubmit={onRedeemCodeBtnClickHandler}>
                    <div className="form-group">
                        <Input
                            id="discount-code"
                            name="discount-code"
                            placeholder="Ticket code"
                            value={promoCodeInputValue}
                            onChangeHandler={(event) => redeemDiscountInputChangeHandler(event.target.value)}
                            fullWidthWithBtn
                            error={redeemDiscountStatus && redeemDiscountStatus.error}
                        />
                        <button
                            className="button primary"
                            disabled={isCreateAccountFormSubmmiting || !promoCodeInputValue || redeemDiscountStatus === 'pending'}
                        >
                            {redeemDiscountStatus === 'pending' ? <Loader type="small" /> : 'Apply'}
                        </button>
                    </div>
                </form>
            }
        </div>
    );
};

GaDiscountRedeem.propTypes = {
    isCreateAccountFormSubmmiting: PropTypes.bool.isRequired,
    promoCodeInputValue: PropTypes.string.isRequired,
    setPromoCodeInputValue: PropTypes.func.isRequired,
    setRedeemDiscountStatus: PropTypes.func.isRequired,
    redeemDiscountStatus: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    setCreateAccountFormFieldValue: PropTypes.func.isRequired,
    setHelperMessage: PropTypes.func.isRequired
};

export default GaDiscountRedeem;
