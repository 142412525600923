import Error from '../error/error-page';
import Helmet from 'react-helmet';
import MemberPortal from 'app/components/templates/member-portal';
import NotFound from '../error/not-found-page';
// import printBreadcrumbList from 'app/utilities/print-breadcrumb-list';
import React from 'react';
import { RESPONSE } from 'config/api';
import selectPage from 'app/selectors/page';
import { useSelector } from 'react-redux';

const MemberPortalPage = () => {
    const globalFields = useSelector((state) => state.globalFields);
    const page = useSelector((state) => selectPage(state, state.router.location.pathname));

    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.NOT_FOUND) return <NotFound />;
    if (page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR || !page.attributes) return <Error />;

    const { attributes } = page;

    const {
        name: title,
        seo_title: seoTitle,
        slug
    } = attributes;

    const titleToUse = seoTitle ? seoTitle : title;

    return (
        <main role="main" className="main">
            <Helmet>
                <title>{titleToUse}</title>
            </Helmet>
            <MemberPortal
                type={slug}
                data={attributes}
                globalFields={globalFields}
            />
            {/* {printBreadcrumbList(page)} */}
        </main>
    );
};

export default MemberPortalPage;
