const browserSupportsAllFeatures = window.Promise && window.fetch && window.URLSearchParams;

// TODO: Code a better solution to load the polyfills only on IE
if (!browserSupportsAllFeatures) {
    require('core-js/stable');
    require('whatwg-fetch');
    require('svgxuse');
    require('url-search-params-polyfill');
    console.info('Polyfills loaded'); // eslint-disable-line
}
