// eslint-disable-jsx-a11y/role-has-required-aria-props

import HeroCarousel from 'app/components/partials/hero-carousel';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { metaTime, publishedTime } from 'app/utilities/date-time';

class Banner extends React.Component {
    renderKnockoutText(knockoutText = null) {
        if (!knockoutText) return null;

        return (
            <svg className="knockout-text">
                <defs>
                    <mask id="knockout-text-mask" className="mask" x="0" y="0" width="100%" height="100%">
                        <rect className="alpha" x="0" y="0" width="100%" height="100%" />
                        <text className="text" x="50%" y="80%">{knockoutText}</text>
                    </mask>
                </defs>
                <rect className="background-colour" x="0" y="0" width="100%" height="100%" />
            </svg>
        );
    }

    renderIntroduction() {
        const {
            author,
            introduction,
            publishDate
        } = this.props;

        // Specify layout by fields avaiable
        if (author || publishDate) {
            const { image, name, occuppation } = author;
            const authorStyle = image ? { 'backgroundImage': `url(${image})` } : null;

            return (
                <p className="subtitle">
                    {authorStyle && <span className="avatar" style={authorStyle}></span>}
                    {authorStyle && <br />}
                    {name && <span><strong>{name}</strong>{occuppation && <span>, {occuppation}</span>}</span>}
                    {name && <br />}
                    {publishDate && <time dateTime={metaTime(publishDate)}>{publishedTime(publishDate)}<br /></time>}
                </p>
            );
        } else if (introduction) {
            return (
                <p className="subtitle">{introduction}</p>
            );
        }

        return null;
    }

    renderIntroBlock() {
        const {
            buttonLabel,
            buttonLink,
            highlight,
            knockoutText,
            subtitle,
            title
        } = this.props;

        return (
            <div className="constrain-width large">
                <div className="introduction || highlighter" role="group">
                    <div className="heading-group">
                        <h1 className="title">{title}</h1>
                        {highlight && <p className="heading-5 || tag">{highlight}</p>}

                        {/* Animals */}
                        {subtitle && <p className="inline-tag"><i lang="la">{subtitle}</i></p>}

                        {/* Article */}
                        {this.renderIntroduction()}

                        {/* Page */}
                        {knockoutText && <p className="actions"><Link className="button primary" to="/">Return to homepage</Link></p>}

                        {!knockoutText && buttonLabel && buttonLink && <Link className="button primary" to={buttonLink}>{buttonLabel}</Link>}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            buttonLabel,
            buttonLink,
            contain,
            images,
            knockoutText,
            title,
        } = this.props;

        if (!contain && images && images.length > 0) {
            return (
                <HeroCarousel
                    buttonLabel={buttonLabel}
                    buttonLink={buttonLink}
                    images={images}
                    styles={'banner has-image'}
                >
                    {this.renderIntroBlock()}
                </HeroCarousel>
            );
        }

        return (
            <header role="heading" className={`banner ${images && images.length === 1 ? 'has-image' : 'no-image'}` // eslint-disable-line jsx-a11y/role-has-required-aria-props
            }>
                {images && images.length === 1 &&
                    <div className={`image ${contain ? 'is-contained' : ''}`} style={{ backgroundImage: `url(${images})` }}>
                        {this.renderKnockoutText(knockoutText)}
                        <img src={images} title={title} alt={title} />
                    </div>
                }
                {this.renderIntroBlock()}
            </header>
        );
    }
}

Banner.propTypes = {
    author: PropTypes.shape({
        name: PropTypes.string,
        occuppation: PropTypes.string,
        image: PropTypes.string
    }),
    buttonLabel: PropTypes.string,
    buttonLink: PropTypes.string,
    contain: PropTypes.bool,
    highlight: PropTypes.string,
    knockoutText: PropTypes.string,
    images: PropTypes.array,
    introduction: PropTypes.string,
    name: PropTypes.string,
    publishDate: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string
};

Banner.defaultProps = {
    contain: false,
    type: 'default'
};

export default Banner;
