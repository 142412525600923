import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { truncateOnWordBoundary } from 'app/utilities/truncate';
import { metaTime, publishedTime } from 'app/utilities/date-time';

const CHARACTER_LIMIT = {
    DESCRIPTION: 100,
    TITLE: 80
};

class TileHighlight extends React.Component {
    render() {
        const { item } = this.props;

        const {
            name,
            publish_at: publishDate,
            // short_description: description,
            thumbnail
        } = item.data;

        const style = thumbnail ? { backgroundImage: `url(${thumbnail})` } : null;

        return (
            <article className="news-highlight-item">
                {thumbnail &&
                    <EntityLink
                        item={item}
                        promoterBuName="Auckland Zoo"
                        className="news-highlight-item-image"
                        style={style}
                    />
                }
                <EntityLink
                    item={item}
                    promoterBuName="Auckland Zoo"
                    className="news-highlight-item-content"
                >
                    <span className="flag"><Icon name="chevron" /></span>
                    <div className="title-wrapper">
                        <h4 className="title">{truncateOnWordBoundary(name, CHARACTER_LIMIT.TITLE)}</h4>
                    </div>
                    {publishDate && <p><time dateTime={metaTime(publishDate)}>{publishedTime(publishDate)}</time></p>}
                </EntityLink>
            </article>
        );
    }
}

TileHighlight.propTypes = {
    item: PropTypes.object.isRequired
};

export default TileHighlight;
