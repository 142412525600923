import { createValidationDataObject } from 'app/utilities/form-validation';

export const createNewsletterSignupFormFields = (mode = 'default') => {
    return [
        {
            name: 'firstname',
            placeholder: 'First name',
            type: 'text',
            validator: (firstname) => {
                if (firstname) {
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'This is a required field');
            }
        },
        {
            name: 'lastname',
            placeholder: 'Last name',
            type: 'text',
            validator: (lastname) => {
                if (lastname) {
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'This is a required field');
            }
        },
        {
            name: 'email',
            placeholder: 'Email',
            type: 'email',
            validator: (email) => {
                if (email) {
                    // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
                    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) { // eslint-disable-line
                        return createValidationDataObject();
                    }

                    return createValidationDataObject(false, 'Not a valid email address');
                }

                return createValidationDataObject(false, 'This is a required field');
            }
        },
        ... mode === 'modal' ? [
            {
                name: 'email_confirmation',
                placeholder: 'Confirm Email',
                type: 'email',
                validator: (email, emailToCompare) => {
                    if (email) {
                        if (email === emailToCompare) {
                            return createValidationDataObject();
                        }

                        return createValidationDataObject(false, 'Email addresses do not match');
                    }

                    return createValidationDataObject(false, 'This is a required field');
                },
                fieldToCompare: 'email'
            }
        ] : []
    ];
};
