/* eslint-disable camelcase */

import ContentBlocks from 'app/components/content-blocks/index';
import Directions from 'app/components/partials/directions';
import HeroCarousel from 'app/components/partials/hero-carousel';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Strip from 'app/components/partials/strip';
import { parseTime, renderTime12, renderTime24 } from 'app/utilities/time';
import React, { useEffect, useRef, useState } from 'react';

const Home = ({ data, globalFields }) => {
    const [isStripActive, setIsStripActive] = useState(false);

    const {
        blocks,
        button_label: buttonLabel,
        button_link: buttonLink,
        short_description: subtitle,
        main_images: images,
    } = data;

    const {
        open_time,
        close_time,
        last_entry
    } = globalFields;

    const heroCarouselRef = useRef(null);

    const openTime = parseTime(open_time);
    const closeTime = parseTime(close_time);
    const lastEntry = parseTime(last_entry);

    const checkHeroCarouselInView = () => {
        const heroCarouselOffset = heroCarouselRef.current.getBoundingClientRect();
        if (heroCarouselOffset.top < 0 && heroCarouselOffset.bottom < 0) return setIsStripActive(false);

        setIsStripActive(true);
    };

    useEffect(() => {
        checkHeroCarouselInView();
        window.addEventListener('scroll', checkHeroCarouselInView);

        return () => window.removeEventListener('scroll', checkHeroCarouselInView);
    }, []);

    return (
        <div className="home-view">
            <HeroCarousel
                buttonLabel={buttonLabel}
                buttonLink={buttonLink}
                title={subtitle}
                images={images}
                type={'primary'}
                ref={heroCarouselRef}
            />
            <section className="constrain-width large">
                <div className="sub-introduction-wrapper">
                    <div className="sub-introduction || highlighter">
                        <div className="inner">
                            <div className="content || openning-hours">
                                <Icon className="fixed-icon" name="clock" />
                                <p className="time" itemProp="openingHours" title={`${renderTime12(openTime)} to ${renderTime12(closeTime)} every day except Christmas`}>
                                    <span className="heading-4">Opening hours:</span><br />
                                    <time dateTime={renderTime24(openTime)}>{renderTime12(openTime)}</time>{' '}-{' '}<time dateTime={renderTime24(closeTime)}>{renderTime12(closeTime)}</time> (Last entry at {renderTime12(lastEntry)})
                                </p>
                            </div>
                            <div className="actions">
                                <Link className="button primary large split" to="/visit/plan-your-day">Plan your day<Icon name="chevron" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContentBlocks data={blocks} />
            <Directions />
            <Strip isActive={isStripActive} />
        </div>
    );
};

Home.propTypes = {
    data: PropTypes.object.isRequired,
    globalFields: PropTypes.object.isRequired
};

export default Home;
