/**
 * Format the WYSIWYG content
 * @param  {string} content WYSIWYG content
 * @param  {string} buttonClass
 * @return {string} formatted content
 */
export default function(content, buttonClass = 'button primary') {
    // Change the button class
    const formattedContent = content.replace(
        /class="small primary button"/g,
        `class="${buttonClass}"`
    );

    return formattedContent;
}
