/* eslint-disable require-jsdoc */
/* eslint-disable sort-imports */
import 'core-js/stable';
import canUseDom from './utilities/dom';
import { configureStore } from './store';
import { customUrlsInitAction } from './ducks/custom-urls';
import { HistoryRouter } from 'redux-first-history/rr6';
import { membershipProductsFetchSuccessAction } from 'app/ducks/tickets';
import { Provider } from 'react-redux';
import React from 'react';
import Routes from 'app/router';
import { StaticRouter } from 'react-router-dom/server';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { fetchNewsSuccessAction, formatNews } from './ducks/news';
import { formatGlobalFields, globalFieldsFetchSuccessAction } from './ducks/global-fields';

// Import styles
import './sass/index';

export let store;

export default function getApp(context, url, initialState, extraState) {
    const history = canUseDom() ?
        createBrowserHistory() :
        createMemoryHistory({ initialEntries: [url] });

    const { reduxStore, reduxHistory } = configureStore(initialState, history);

    store = reduxStore;
    /**
     * Re-build the correct state for SSR
     */
    if (context === 'server' && extraState) {
        // For global fields
        if (extraState.globalFields) { // Should always be true
            const newAction = globalFieldsFetchSuccessAction(formatGlobalFields(extraState.globalFields));
            reduxStore.dispatch(newAction);
        }
        // For `news` state
        if (extraState.news) {
            const newAction = fetchNewsSuccessAction(formatNews(extraState.news));
            reduxStore.dispatch(newAction);
        }
        // For membership products state
        if (extraState.membershipProducts) {
            const newAction = membershipProductsFetchSuccessAction(extraState.membershipProducts);
            reduxStore.dispatch(newAction);
        }
        // For custom URLs (always present)
        reduxStore.dispatch(customUrlsInitAction(extraState.customUrls));
    }

    const Router = {
        server: StaticRouter,
        client: HistoryRouter
    }[context];

    const routerProps = {
        server: { context: {}, location: url },
        client: { history: reduxHistory }
    }[context];

    const app = (
        <Provider store={reduxStore}>
            <Router {...routerProps}>
                <Routes />
            </Router>
        </Provider>
    );

    return {
        app: app,
        latestState: reduxStore.getState()
    };
}
