import iconSprite from 'static/icons/sprite.svg';
import PropTypes from 'prop-types';
import React from 'react';

class Icon extends React.Component {
    render() {
        let iconTitle,
            options = this.props;

        const { name, title, type, height, width } = this.props;

        // Turn file name into title
        iconTitle = name.replace(/-/g, ' ').replace(/\b\w/g, (l) => { return l.toUpperCase(); });

        if (type === 'social') {
            iconTitle = `Follow us on ${iconTitle}`;
        }

        if (type === 'default') {
            // Default options should hide SVG
            options = {
                'aria-hidden': true,
                'focusable': true,
                ...options
            };
        }

        return (
            <svg width={width} height={height} viewBox="0 0 40 40" {...options}>
                <title>{title ? title : iconTitle}</title>
                <use xlinkHref={`${iconSprite}#${name}`}></use>
            </svg>
        );
    }
}

Icon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
};

Icon.defaultProps = {
    className: 'icon',
    type: 'default',
    height: '40',
    width: '40'
};

export default Icon;
