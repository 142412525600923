import { createValidationDataObject } from 'app/utilities/form-validation';
import { GIFTER_FORM_FIELDS } from 'config/gift-memberships-forms';
import Input from 'app/components/partials/input';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { setGifterFieldValueAction, touchedGifterFieldAction } from 'app/ducks/buy-memberships';
import { useDispatch, useSelector } from 'react-redux';

const GifterDetails = ({ stepClickHandler }) => {
    const dispatch = useDispatch();
    const { currentStepIndex } = useSelector((state) => state.buyMemberships);
    const gifter = useSelector((state) => state.buyMemberships.gifter);
    const user = useSelector((state) => state.auth.user);

    const getFieldValue = (fieldName) => gifter[fieldName].value;
    const getFieldTouched = (fieldName) => gifter[fieldName].touched;
    const setFieldValue = (fieldName, value) => dispatch(setGifterFieldValueAction(fieldName, value));
    const touchedField = (fieldName) => dispatch(touchedGifterFieldAction(fieldName));

    const autoFillGifterFields = () => {
        if (user) {
            setFieldValue('gifter_first_name', user.first_name);
            setFieldValue('gifter_last_name', user.last_name);
            setFieldValue('gifter_email', user.email);
        }
    };

    const validateForm = () => {
        const invalid = GIFTER_FORM_FIELDS.some(({ name, validator }) => {
            const value = getFieldValue(name);

            return !validator(value).valid;
        }, []);

        return invalid;
    };

    const renderForm = () => (
        <form className="gifter-details-form">
            <div className="section">
                <div className="form-group">
                    {GIFTER_FORM_FIELDS.map((FIELD, index) => {
                        const { name: fieldName, label, validator, type, placeholder, isRequired, helpText } = FIELD;
                        const value = getFieldValue(fieldName);
                        const touched = getFieldTouched(fieldName);

                        const validationData = touched ? validator(value) : createValidationDataObject();

                        const fieldData = {
                            index,
                            key: fieldName,
                            id: fieldName,
                            name: fieldName,
                            helpText: helpText,
                            label,
                            type,
                            placeholder,
                            value,
                            onChangeHandler: (event) => {
                                setFieldValue(fieldName, event.target.value);
                                touchedField(fieldName);
                            },
                            onBlurHandler: () => touchedField(fieldName),
                            isRequired,
                            error: validationData.valid ? '' : validationData.message
                        };

                        return <Input { ...fieldData } />;
                    })}
                </div>
            </div>
        </form>
    );

    useEffect(() => {
        autoFillGifterFields();
    }, []);

    return (
        <div className="inner">
            <div className="constrain-width medium no-pad">
                <h4 className="title">Your details</h4>
                {renderForm()}

                <section className="section button-group">
                    <button
                        className="button"
                        onClick={() => stepClickHandler(currentStepIndex - 1)}
                    >Back</button>
                    <button
                        className="button primary"
                        onClick={() => stepClickHandler(currentStepIndex + 1)}
                        disabled={validateForm()}
                    >
                        Next
                    </button>
                </section>
            </div>
        </div>
    );
};

GifterDetails.propTypes = {
    stepClickHandler: PropTypes.func.isRequired
};

export default GifterDetails;
