import { clientEnv } from './env';

const isProd = clientEnv.APP_ENV.toLowerCase() === 'prod';

export const REZDY_ENV_URL = isProd ? 'https://aucklandzoo.rezdy.com' : 'https://aucklandzoo.rezdy-test.com';

const PROD_TICKET_TYPES = [
    {
        title: 'Day pass',
        url: `${REZDY_ENV_URL}/138616/day-pass?iframe=true`
    }
];

const DEV_TICKET_TYPES = [
    {
        title: 'Same day ticket',
        url: `${REZDY_ENV_URL}/11044/same-day-ticket?iframe=true`
    }
];

export const TICKET_TYPES = isProd ? PROD_TICKET_TYPES : DEV_TICKET_TYPES;
