/**
 * Truncate string to a specified limit
 *
 * @param  {string} string String to be truncated
 * @param  {int} limit Length to truncate to
 *
 * @return {string}        Truncated string with HTML encoded ellipsis
 */
export default function(string, limit) {
    if (string.length > limit) {
        return `${string.substr(0, limit)} \u2026`;
    }

    return string;
}

/**
 * Truncate string to a specified limit on word boundary
 *
 * @param  {string} string String to be truncated
 * @param  {int} limit Max length to truncate to
 *
 * @return {string}        Truncated string with HTML encoded ellipsis
 */
export function truncateOnWordBoundary(string, limit) {
    if (string.length > limit) {
        let stringTrimmed = string.slice(0, limit);

        // Remove the bit following (and including) the last space character
        const lastWordBoundaryPos = stringTrimmed.lastIndexOf(' ');
        if (lastWordBoundaryPos >= 0) {
            stringTrimmed = stringTrimmed.slice(0, lastWordBoundaryPos);
        }

        return `${stringTrimmed} \u2026`;
    }

    return string;
}
