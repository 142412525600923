import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { setInternalErrorAction } from 'app/ducks/status';
import { useDispatch } from 'react-redux';

const Toast = ({ isActive, message, type }) => {
    const dispatch = useDispatch();

    const toastClass = classnames('toast', { 'is-active': isActive });

    // Could re-use for other type of Toast
    return (
        <div className={toastClass}>
            <div className="toast-container || constrain-width large">
                <Icon name="closure" />
                <p dangerouslySetInnerHTML={{ __html: message }}></p>
                <button className="close-btn" onClick={() => dispatch(setInternalErrorAction(false))}>
                    <Icon name="close" />
                </button>
            </div>
        </div>
    );
};

Toast.defaultProps = {
    type: 'error'
};

Toast.propTypes = {
    isActive: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string
};

export default Toast;
