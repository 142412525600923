import ExtendedArticleTile from 'app/components/partials/extended-article-tile';
import { fireNewsStorySelector } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import { NEWS } from 'config/news';
import PropTypes from 'prop-types';
import React from 'react';

class NewsByCategory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isClientLoading: true
        };

        // Load initial data
        this.props.loadCategories();
    }


    // Life cycle
    componentDidMount() {
        const { data, loadData, page } = this.props;

        if (!Array.isArray(data)) {
            loadData(NEWS.CATEGORY_ALL.id, page);
        }
    }

    UNSAFE_componentWillReceiveProps({ isBusy }) {
        // Sync prop with local state
        if (!isBusy) this.setState({ isClientLoading: false });
    }


    // Helper
    currentCategoryObj() {
        const { categories, currentCategory } = this.props;
        const currentCategoryObj = categories.find((option) => option.id === currentCategory);

        return currentCategoryObj || null;
    }


    // Handler
    handleSubmit(event) {
        const { isBusy } = this.props;

        event.preventDefault();

        if (isBusy) return;

        // Reset to page 1
        this.props.loadData(this.categorySelector.value, 1);

        const selectedCategoryText = event.target.options[event.target.selectedIndex].text;

        fireNewsStorySelector(selectedCategoryText);
    }

    handleLoadMore() {
        const { isBusy } = this.props;

        if (isBusy) return;

        // Increase page by 1
        this.props.loadData(this.categorySelector.value, this.props.page + 1);
    }

    // Render
    renderCategorySelector() {
        const { currentCategory, categories } = this.props;
        const currentCategoryObj = this.currentCategoryObj();

        return (
            <form
                className="week-day-selector"
                onSubmit={this.handleSubmit.bind(this)}
            >
                <label className="wrapper" aria-hidden="true" htmlFor="week-day-selector">
                    <span className="week-day">{currentCategoryObj.name}</span>
                    <Icon name="chevron" title="Select day" />
                    <select
                        id="category-selector"
                        className="select"
                        ref={(categorySelector) => { this.categorySelector = categorySelector; } }
                        value={currentCategory}
                        // onBlur={this.handleSubmit.bind(this)}
                        onChange={this.handleSubmit.bind(this)}
                    >
                        {categories.map(({ id, name }, index) => (
                            <option key={index} value={id}>{name}</option>
                        ))}
                    </select>
                </label>
            </form>
        );
    }

    renderActions() {
        const { data, resultCount, isBusy } = this.props;
        const currentCategoryObj = this.currentCategoryObj();

        return (
            <div className="news-list-actions">
                {(isBusy || this.state.isClientLoading) && <Loader />}
                {!isBusy && data && data.length === 0 && <p className="error-message">No articles found for {currentCategoryObj.name}.</p>}
                {data && (data.length > 0 && !isBusy && data.length < resultCount) &&
                    <button
                        className="button primary"
                        onClick={this.handleLoadMore.bind(this)}>See more stories of {currentCategoryObj.name}</button>
                }
            </div>
        );
    }

    render() {
        const { currentCategory, data, itemsInBanner } = this.props;
        const excludeIDs = itemsInBanner ? itemsInBanner.map(({ id }) => id) : [];

        return (
            <section className="encounters-block">
                <header className="section-header">
                    <div className="constrain-width large">
                        <div className="inner">
                            <div className="title-wrapper || week-day-selector-heading">
                                <h2 className="title || heading-1">Stories of {this.renderCategorySelector()}</h2>
                            </div>
                        </div>
                    </div>
                </header>

                {/* News list */}
                <div className="constrain-width large">
                    <div className="tile-grid">
                        {data && data.map((item, index) => {
                            // Hide the items in the banner
                            if (currentCategory === NEWS.CATEGORY_ALL.id && excludeIDs.includes(item.id)) return null;

                            return <ExtendedArticleTile key={index} item={item} />;
                        })}
                    </div>
                    {this.renderActions()}
                </div>
            </section>
        );
    }
}

NewsByCategory.propTypes = {
    // State
    itemsInBanner: PropTypes.array.isRequired,
    isBusy: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    currentCategory: PropTypes.string.isRequired,
    data: PropTypes.array,
    page: PropTypes.number.isRequired,
    resultCount: PropTypes.number,
    // Functions
    loadCategories: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired
};

export default NewsByCategory;
