import classnames from 'classnames';
import { COMPLETE_STEP_INDEX } from 'config/buy-memberships-steps';
import { COMPLETE_STEP_INDEX as COMPLETE_STEP_INDEX_GIFT } from 'config/gift-memberships-steps-defs';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const ProgressBar = ({ activeStepIndex, items, stepClickHandler, isGifting = false }) => {
    const activeItem = items.find((item) => item.index === activeStepIndex);

    return (
        <section className="constrain-width large || progress-bar">
            <div className="step is-active mobile-only">
                <div className="step-index">
                    <Icon name={activeItem.icon}></Icon>
                </div>
                <p className="text">{activeItem.name}</p>
            </div>
            <div className="steps-container">
                {items.map((item) => {
                    const { name, index: stepIndex, icon } = item;
                    const completeStepIndex = isGifting ? COMPLETE_STEP_INDEX_GIFT : COMPLETE_STEP_INDEX;
                    const isDisabled = stepIndex > activeStepIndex || activeStepIndex === completeStepIndex;

                    const isPassedStep = stepIndex < activeStepIndex;
                    const stepClass = classnames('step', {
                        'is-active': stepIndex === activeStepIndex,
                        'is-passed': isPassedStep,
                        'is-disabled': isDisabled
                    });

                    return (
                        <div
                            className={stepClass}
                            key={stepIndex}
                        >
                            <button
                                className="step-index"
                                onClick={() => stepClickHandler(stepIndex)}
                                disabled={isDisabled}
                            >
                                {isPassedStep ?
                                    <Icon name="animate-check" /> : <Icon name={icon}></Icon>}
                            </button>
                            <p className="text">{name}</p>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

ProgressBar.propTypes = {
    activeStepIndex: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    stepClickHandler: PropTypes.func.isRequired,
    isGifting: PropTypes.bool
};

export default ProgressBar;
