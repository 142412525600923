import imgMazda from 'static/images/mazda.svg';
import imgQms from 'static/images/qms-new.svg';
import React from 'react';

class Sponsors extends React.Component {
    render() {
        return (
            <section className="sponsors || constrain-width">
                <div className="inner">
                    <div className="sponsors-group">
                        <div className="sponsors-group-inner">
                            <h3 className="title || heading-5">Principal partner</h3>
                            <div className="image-group" role="group">
                                <div className="item"><img src={imgMazda} alt="Mazda" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="sponsors-group major">
                        <div className="sponsors-group-inner">
                            <h3 className="title || heading-5">Major partner</h3>
                            <div className="image-group" role="group">
                                <div className="item"><img className="large" width="134" height="60" src={imgQms} alt="QMS" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Sponsors;
