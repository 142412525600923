import { EntityLink } from 'rfa-react-core';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { truncateOnWordBoundary } from 'app/utilities/truncate';
import { metaTime, publishedTime } from 'app/utilities/date-time';

const CHARACTER_LIMIT = {
    DESCRIPTION: 100,
    TITLE: 90
};

class FeaturedArticleTile extends React.Component {
    render() {
        const { item } = this.props;

        let style = {};

        const {
            author,
            name,
            publish_at: publishDate,
            short_description: description,
            thumbnail
        } = item.data;

        if (thumbnail) {
            style = { backgroundImage: `url(${thumbnail})` };
        }

        return (
            <article className="featured-article-tile">
                <EntityLink
                    item={item}
                    promoterBuName="Auckland Zoo"
                    className="tile-inner || image-overlay"
                    style={style}
                >
                    {thumbnail && <div className="tile-thumbnail || image-overlay" style={style}></div>}
                    <div className="heading-group">
                        <h3 className="title">{truncateOnWordBoundary(name, CHARACTER_LIMIT.TITLE)}<span className="flag"><Icon name="chevron" /></span></h3>
                        {description && <p className="subtitle">{truncateOnWordBoundary(description, CHARACTER_LIMIT.DESCRIPTION)}</p>}
                        <p className="meta">
                            {publishDate && <time dateTime={metaTime(publishDate)}>{publishedTime(publishDate)}</time>}
                            {author && <br />}
                            {author && <span className="author">by {author}</span>}
                        </p>
                    </div>
                </EntityLink>
            </article>
        );
    }
}

FeaturedArticleTile.propTypes = {
    item: PropTypes.object.isRequired
};

export default FeaturedArticleTile;
