import Icon from 'app/components/partials/icon';
import { isTouchDevice } from 'app/utilities/scalable-object';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Controls extends Component {
    componentDidMount() {
        this.setPointerHandlers();
    }

    // Can't use react methods unfortunately
    setPointerHandlers() {
        const eventName = isTouchDevice() ? 'touchstart' : 'click';

        // Add
        this.plusNode.addEventListener(eventName, () => {
            this.plusNode.blur();
            this.props.handleScalePlus();
        });

        // Minus
        this.minusNode.addEventListener(eventName, () => {
            this.minusNode.blur();
            this.props.handleScaleMinus();
        });

        // Fullscreen
        this.fullscreenNode.addEventListener(eventName, () => {
            this.fullscreenNode.blur();
            this.props.handleToggleFullscreen();
        });

        // Reset
        this.resetNode.addEventListener(eventName, () => {
            this.resetNode.blur();
            this.props.handleReset();
        });
    }

    render() {
        const {
            isFullscreen,
            scale,
            minScale,
            maxScale,
            componentName
        } = this.props;

        return (
            <div className={`${componentName}-actions`}>
                {/* Toggle Fullscreen */}
                <button
                    ref={(node) => { this.fullscreenNode = node; }}
                    className={`${componentName}-button fullscreen ${isFullscreen ? 'is-active' : ''}`}
                >
                    <Icon className="icon in-active" name="fullscreen" title="Expand to fullscreen" />
                    <Icon className="icon active" name="exit-fullscreen" title="Exit fullscreen" />
                </button>

                <div className="zoom">
                    {/* Zoom In */}
                    <button
                        ref={(node) => { this.plusNode = node; }}
                        className={`${componentName}-button zoom-in`}
                        disabled={scale >= maxScale}
                    >
                        <Icon name="plus" title="Zoom in" />
                    </button>

                    {/* Zoom Out */}
                    <button
                        ref={(node) => { this.minusNode = node; }}
                        className={`${componentName}-button zoom-out`}
                        disabled={scale <= minScale}
                    >
                        <Icon name="minus" title="Zoom out" />
                    </button>

                    {/* Reset */}
                    <button
                        ref={(node) => { this.resetNode = node; }}
                        className={`${componentName}-button reset`}
                    >
                        <Icon name="reset" title="Reset" />
                    </button>
                </div>
            </div>
        );
    }
}

Controls.propTypes = {
    componentName: PropTypes.string.isRequired,
    isFullscreen: PropTypes.bool.isRequired,
    handleToggleFullscreen: PropTypes.func.isRequired,
    handleScaleMinus: PropTypes.func.isRequired,
    handleScalePlus: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    scale: PropTypes.number,
    minScale: PropTypes.number,
    maxScale: PropTypes.number
};

export default Controls;
