/* eslint-disable require-jsdoc */

import { connect } from 'react-redux';
import SearchResult from './search-result';
import {
    initiateSearchResultFetchAction,
    toggleSearchDialogAction
} from 'app/ducks/search';

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        initiateSearch: (keywords) => dispatch(initiateSearchResultFetchAction(keywords)),
        toggleSearchDialog: () => dispatch(toggleSearchDialogAction())
    };
}

const SearchResultContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchResult);

export default SearchResultContainer;
