/* eslint-disable no-undef */
import { clientEnv } from 'config/env';

export default (cardData) => {
    return new Promise((resolve, reject) => {
        Stripe.setPublishableKey(clientEnv.STRIPE_PUBLIC_KEY);

        // eslint-disable-next-line func-style
        const stripeResponseHandler = function(_status, response) {
            if (response.error) { // Problem!
                // Show the errors on the form:
                reject(response.error);
            } else { // Token was created!
                // Get the token ID:
                const token = response.id;

                resolve(token);
            }
        };

        // Create a token when the form is submitted
        Stripe.card.createToken(cardData, stripeResponseHandler);
    });
};
