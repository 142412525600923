import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';
import StacklaGoConnect from '../partials/stackla-go-connect';

class TextContent extends React.Component {
    render() {
        const { aside, citation, content, quote, widgetId } = this.props.data;
        const { childrenAsideAfter, type } = this.props;

        return (
            <section className="section || text-block">
                <div className="constrain-width large">
                    <div className="inner">
                        <div className="content">
                            <div className="constrain-content || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content) }}></div>
                        </div>
                        {aside && <aside className="aside">
                            <div className="content-group">
                                <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(aside) }}></div>
                                {childrenAsideAfter}
                                {type === 'stackladirectuploader' && <StacklaGoConnect widgetId={widgetId} />}
                            </div>
                        </aside>}
                        {quote && <aside className="aside">
                            <div className="content-group">
                                <blockquote><p dangerouslySetInnerHTML={{ __html: formatContent(quote) }}></p></blockquote>
                                <cite>{citation}</cite>
                            </div>
                        </aside>}
                    </div>
                </div>
            </section>
        );
    }
}

TextContent.propTypes = {
    childrenAsideAfter: PropTypes.node,
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};

export default TextContent;
