/* eslint-disable require-jsdoc */

export const clamp = (min, value, max) => {
    return Math.max(min, Math.min(value, max));
};

export const isTouchDevice = () => {
    return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
};

export const eventNames = () => {
    const isTouch = isTouchDevice();

    return {
        down: isTouch ? 'touchstart' : 'mousedown',
        move: isTouch ? 'touchmove' : 'mousemove',
        up: isTouch ? 'touchend' : 'mouseup'
    };
};

export const distance = (p1, p2) => {
    const dx = p1.x - p2.x;
    const dy = p1.y - p2.y;

    return Math.sqrt(Math.pow(dx, 2), Math.pow(dy, 2));
};

export const midpoint = (p1, p2) => {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2
    };
};

export const touchPoint = (touch) => {
    return { x: touch.clientX, y: touch.clientY };
};

export const touchDistance = (t0, t1) => {
    const p0 = touchPoint(t0);
    const p1 = touchPoint(t1);

    return distance(p0, p1);
};

export const coordChange = (coordinate, scaleRatio) => {
    return (scaleRatio * coordinate) - coordinate;
};
