/* eslint-disable camelcase */
import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { useRef, useState } from 'react';

const RollingText = ({ data, renderAfter }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const { rolling_group: items } = data;
    const sliderRef = useRef(null);

    const paginationBtnClickHandler = (type = 'prev') => {
        if (type === 'prev') {
            sliderRef.current.slickPrev();

            return;
        }

        sliderRef.current.slickNext();
    };

    const dotClickHandler = (index) => {
        sliderRef.current.slickGoTo(index);
        setActiveItemIndex(index);
    };

    const renderArrow = (type = 'prev') => (
        <button
            className={`btn-${type}`}
            onClick={() => paginationBtnClickHandler(type)}
        >
            <Icon name="chevron" />
        </button>
    );

    const renderDots = () => (
        <div className="dots">
            {new Array(items.length).fill().map((ele, index) => {
                const dotClass = classnames('dot', { 'is-active': index === activeItemIndex });

                return <button className={dotClass} key={index} onClick={() => dotClickHandler(index)} />;
            })}
        </div>
    );

    const settings = {
        dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => setActiveItemIndex(index)
    };

    const rollingTextBlockClass = classnames('section || rolling-text-block || constrain-width large', {
        'dual-items': renderAfter
    });

    return (
        <section className={rollingTextBlockClass}>
            <div className="rolling-text-wrapper">
                <Slider { ...settings } ref={sliderRef}>
                    {items.map((item, index) => (
                        <div className="item" key={index}>
                            <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(item.text) }} />
                        </div>
                    ))}
                </Slider>
                {items && items.length > 1 && (
                    <div className="pagination">
                        {renderArrow('prev')}
                        {renderDots()}
                        {renderArrow('next')}
                    </div>
                )}
            </div>
            {renderAfter && renderAfter}
        </section>
    );
};

RollingText.propTypes = {
    data: PropTypes.shape({
        rolling_group: PropTypes.array.isRequired
    }),
    renderAfter: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array
    ]),
};

export default RollingText;
