import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Select = ({ id, name, isRequired, small, medium, large, label, placeholder, options, value, onChangeHandler, onBlurHandler, error }) => {
    const selectClass = classnames('form-field', {
        'is-required': isRequired,
        'small': small,
        'medium': medium,
        'large': large,
        'is-invalid': error
    });

    return (
        <div className={selectClass}>
            <label className="label" htmlFor={id}>{label}</label>
            <select className="input" name={name} id={id} value={value} onChange={onChangeHandler} onBlur={onBlurHandler}>
                {placeholder && <option value="DEFAULT" disabled>{placeholder}</option>}
                {options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

Select.defaultProps = {
    placeholder: '',
    isRequired: false,
    small: false,
    medium: false,
    large: false,
    options: [],
    error: ''
};

Select.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    onBlurHandler: PropTypes.func.isRequired,
    error: PropTypes.string
};

export default Select;
