/* eslint-disable camelcase */
import { DATE_FORMAT } from 'config/api';
import moment from 'moment';
import {
    createValidationDataObject,
    isEmailValid,
    isValueRequiredValid,
} from 'app/utilities/form-validation';

export const displayDateFormat = 'DD/MM/YYYY';

export const GIFTER_FORM_FIELDS = [
    {
        name: 'gifter_first_name',
        label: 'First name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (first_name) => {
            if (!isValueRequiredValid(first_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'gifter_last_name',
        label: 'Last name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (last_name) => {
            if (!isValueRequiredValid(last_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'gifter_email',
        label: 'Email',
        helpText: 'We will use this email address to send you a receipt',
        placeholder: '',
        type: 'email',
        defaultValue: '',
        isRequired: true,
        validator: (email) => {
            if (!isValueRequiredValid(email)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            if (email && !isEmailValid(email)) {
                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject();
        }
    },
];

export const RECEIVER_FORM_FIELDS = [
    {
        name: 'receiver_first_name',
        label: 'First name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (first_name) => {
            if (!isValueRequiredValid(first_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'receiver_last_name',
        label: 'Last name',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: true,
        validator: (last_name) => {
            if (!isValueRequiredValid(last_name)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'receiver_email',
        label: 'Email',
        helpText: 'We will use this email address to send a gift voucher',
        placeholder: '',
        type: 'email',
        defaultValue: '',
        isRequired: true,
        validator: (email) => {
            if (!isValueRequiredValid(email)) {
                return createValidationDataObject(false, 'This is a required field');
            }

            if (email && !isEmailValid(email)) {
                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject();
        }
    },
    // Hack: on desktop force new row after email field
    {
        name: 'empty',
        isEmpty: true,
        validator: () => createValidationDataObject()
    },
    {
        name: 'send_email_at',
        label: 'Date to send gift',
        helpText: 'Please select a date to send the email notifying the recipient of their gift',
        placeholder: 'dd/mm/yyyy',
        type: 'date',
        defaultValue: '',
        isRequired: true,
        validator: (dt) => {
            if (dt) {
                const date = moment(dt, DATE_FORMAT, true);

                if (!date.isValid()) {
                    return createValidationDataObject(false, 'Invalid date format: e.g. dd/mm/yyyy');
                }
            }

            return createValidationDataObject();
        }
    },
    {
        name: 'gift_message',
        label: 'Custom message',
        helpText: 'Enter a custom message here for your gift recipient',
        placeholder: '',
        type: 'text',
        defaultValue: '',
        isRequired: false,
        validator: (message) => {
            if (message.length > 255) {
                return createValidationDataObject(false, 'Your message is too long (needs to be 255 characters or below)');
            }

            return createValidationDataObject();
        }
    },
];
