import { canRenew } from 'app/utilities/buy-memberships';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const getRenewLink = (renewPassId, prefill = false) => {
    const params = new URLSearchParams({
        renewPassId: renewPassId,
    });

    if (prefill) {
        params.set('prefill', '1');
    }

    return `${FRONTEND_ROUTES.RENEW_MEMBERSHIP}?${params.toString()}`;
};

const renderRenewButtons = (annualPassId) => {
    return (
        <div>
            <div className="button-renew">
                <Link
                    className="button primary"
                    to={getRenewLink(annualPassId, true)}>
                    Renew membership
                </Link>
            </div>
        </div>
    );
};

const renderIneligible = () => {
    return <p>You don’t have a membership to renew at this time.</p>;
};

const Renew = ({ data }) => {
    const user = useSelector((state) => state.auth.user);

    const { name: title, short_description: shortDescription } = data;

    const userCanRenew = canRenew(user);

    return (
        <div className="members-portal-renew || constrain-width large">
            <h2>{title}</h2>
            <p>{shortDescription}</p>
            { userCanRenew ? renderRenewButtons(user.annual_passes[0].id) : renderIneligible()}
        </div>
    );
};

Renew.propTypes = {
    data: PropTypes.object.isRequired
};

export default Renew;
