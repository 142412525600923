import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import customUrls from './custom-urls';
import globalFields from './global-fields';
import status from './status';
import strip from './strip';
import auth, { epics as authEpics } from './auth';
import buyMemberships, { epics as buyMembershipsEpics } from './buy-memberships';
import donationPayment, { epics as donationPaymentEpics } from './donation-payment';
import encounters, { epics as encountersEpics } from './encounters';
import news, { epics as newsEpics } from './news';
import newsByCategory, { epics as newsByCategoryEpics } from './news-by-category';
import newsCategories, { epics as newsCategoriesEpics } from './news-categories';
import newsletter, { epics as newsletterSignupEpics } from './newsletter-signup';
import notifications, { epics as notificationsEpics } from './notifications';
import pages, { epics as pageEpics } from './page';
import search, { epics as searchEpics } from './search';
import tickets, { epics as ticketsEpics } from './tickets';

export const epics = combineEpics(
    encountersEpics,
    donationPaymentEpics,
    newsEpics,
    newsByCategoryEpics,
    newsCategoriesEpics,
    newsletterSignupEpics,
    notificationsEpics,
    pageEpics,
    searchEpics,
    ticketsEpics,
    authEpics,
    buyMembershipsEpics
);

const createRootReducer = (routerReducer) => combineReducers({
    router: routerReducer,
    encounters,
    donationPayment,
    globalFields,
    news,
    newsByCategory,
    newsCategories,
    newsletter,
    notifications,
    customUrls,
    pages,
    search,
    status,
    tickets,
    auth,
    buyMemberships,
    strip
});

export default createRootReducer;
