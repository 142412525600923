/* eslint-disable camelcase */
import Complete from 'app/components/partials/buy-memberships/steps/complete';
import Customise from 'app/components/partials/buy-memberships/steps/customise';
import MemberDetails from 'app/components/partials/buy-memberships/steps/member-details';
import Payment from 'app/components/partials/buy-memberships/steps/payment';
import Review from 'app/components/partials/buy-memberships/steps/review';

export const CUSTOMISE_STEP_INDEX = 1;
export const MEMBER_DETAILS_STEP_INDEX = 2;
export const REVIEW_STEP_INDEX = 3;
export const PAYMENT_STEP_INDEX = 4;
export const COMPLETE_STEP_INDEX = 5;

export const BUY_MEMBERSHIP_STEPS = [
    {
        key: 'customise',
        title: 'Create your membership',
        subtitle: '',
        index: CUSTOMISE_STEP_INDEX,
        name: 'Customise',
        icon: 'user',
        component: Customise
    },
    {
        key: 'member-details',
        title: 'Member details',
        subtitle: 'Please complete your membership by filling out details for all members.',
        index: MEMBER_DETAILS_STEP_INDEX,
        name: 'Member details',
        icon: 'pass-details',
        component: MemberDetails
    },
    {
        key: 'review',
        title: 'Please review your order',
        subtitle: 'You’re almost done! Review your membership and create your account.',
        index: REVIEW_STEP_INDEX,
        name: 'Review',
        icon: 'cart',
        component: Review
    },
    {
        key: 'payment',
        title: 'Billing detail',
        subtitle: '',
        index: PAYMENT_STEP_INDEX,
        name: 'Payment',
        icon: 'payment',
        component: Payment
    },
    {
        key: 'complete',
        title: 'Thank you for your purchase!',
        subtitle: '',
        index: COMPLETE_STEP_INDEX,
        name: 'Complete',
        icon: 'check',
        component: Complete
    }
];


export const CUSTOMISE_SECTIONS = [
    {
        title: 'Named passholders',
        subtitle: 'You can add as many or as few people to your membership as you like. You will need to provide details for each named member in your membership. Named members will require photo ID as part of their membership.',
        type: 'normal'
    },
    {
        title: 'Guests',
        subtitle: 'You can add one guest member to your membership – which can be used by any adult at one time. An adult guest member must be accompanied by a named member.',
        type: 'guest'
    }
];

