import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { NEWS } from 'config/news';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    lastActiveCategory: undefined, // Used to help track displaying the next page for recent category
    activeCategory: NEWS.CATEGORY_ALL.id,
    items: undefined,
    isBusy: false,
    page: 1,
    resultCount: undefined
};

// Actions
export const FETCH_NEWS_BY_CATEGORY_FAIL = 'rfa-zoo-website/news/FETCH_NEWS_BY_CATEGORY_FAIL';
export const FETCH_NEWS_BY_CATEGORY_SUCCESS = 'rfa-zoo-website/news/FETCH_NEWS_BY_CATEGORY_SUCCESS';
export const FETCH_NEWS_BY_CATEGORY = 'rfa-zoo-website/news/FETCH_NEWS_BY_CATEGORY';


// Action Creators
export const fetchNewsByCategoryAction = (category, page) => ({
    type: FETCH_NEWS_BY_CATEGORY,
    payload: {
        category: category,
        page: page,
        isBusy: true
    }
});

export const fetchNewsByCategoryFailAction = (category) => ({
    type: FETCH_NEWS_BY_CATEGORY_FAIL,
    payload: {
        category: category,
        isBusy: false
    }
});

export const fetchNewsByCategorySuccessAction = ({ items, resultCount }, category, page) => ({
    type: FETCH_NEWS_BY_CATEGORY_SUCCESS,
    payload: {
        category: category,
        items: items,
        isBusy: false,
        page: page,
        resultCount: resultCount
    }
});


// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_NEWS_BY_CATEGORY:
            return fetchNewsByCategory(state, action.payload);
        case FETCH_NEWS_BY_CATEGORY_FAIL:
            return fetchNewsByCategoryFail(state, action.payload);
        case FETCH_NEWS_BY_CATEGORY_SUCCESS:
            return fetchNewsByCategorySuccess(state, action.payload);
        default:
            return state;
    }
};

function fetchNewsByCategory(state, { category, isBusy, page }) { // eslint-disable-line require-jsdoc
    // Append if current category otherwise clear
    const items = state.activeCategory === category ? state.items : [];

    return {
        ...state,
        lastActiveCategory: state.activeCategory,
        activeCategory: category,
        items: items, // Fail
        isBusy: isBusy,
        page: page
    };
}

function fetchNewsByCategoryFail(state, { category, isBusy }) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        activeCategory: category,
        items: [], // Fail
        isBusy: isBusy
    };
}

function fetchNewsByCategorySuccess(state, { category, items, page, resultCount }) { // eslint-disable-line require-jsdoc
    let currentItems = [];

    // Only append if searching the current category but a new page
    if (state.lastActiveCategory === category && page >= state.page) {
        currentItems = Array.isArray(state.items) ? state.items : [];
    }

    return {
        ...state,
        activeCategory: category,
        isBusy: false,
        items: [...currentItems, ...items], // Append new items
        resultCount: resultCount
    };
}


// Epic creator
/**
 * News Epic
 * @param  {func} endpoint                           - News endpoint
 * @param  {func} formatNews                         - Format response function
 * @param  {func} fetchNewsByCategorySuccessAction   - Success action creator
 * @param  {func} fetchNewsByCategoryFailAction      - Success action creator
 * @return {func}
 */
export const createNewsEpic = (endpoint, formatNews, fetchNewsByCategorySuccessAction, fetchNewsByCategoryFailAction) => {
    return (action$) => action$.pipe(
        ofType(FETCH_NEWS_BY_CATEGORY),
        mergeMap(({ payload }) => {
            const { category, page } = payload;

            // Pass null to the endpoint for 'all'
            const queryCategory = category === NEWS.CATEGORY_ALL.id ? null : category;

            return (
                fetch(endpoint(NEWS.BY_CATEGORY_LIMIT, false, page, queryCategory))
                    .then((response) => {
                        return fetchNewsByCategorySuccessAction(formatNews(response), category, page);
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return fetchNewsByCategoryFailAction(category);
                    })
            );
        })
    );
};


// Epics
const itemsEpic = createNewsEpic(
    ENDPOINTS.NEWS,
    formatNews,
    fetchNewsByCategorySuccessAction,
    fetchNewsByCategoryFailAction
);


/**
 * Formats the API response
 * @param  {object} response    – Raw API response
 * @param  {string} category  – category id
 * @return {object}             - Formatted API response
 */
export function formatNews(response, category) {
    if (response && response.data && response.data.length) {
        const { data, meta } = response;

        // Format "attributes" to "data"
        const items = data.map((item) => ({
            ...item,
            displayType: NEWS.DISPLAY_TYPES.EXTENDED,
            data: item.attributes
        }));

        return {
            category,
            items,
            resultCount: meta.result_count
        };
    }

    return INITIAL_STATE;
}

export const epics = combineEpics(itemsEpic);
