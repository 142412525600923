import getPageParentBySlug from 'app/utilities/get-page-parent-by-slug.js';
import React from 'react';

/**
 * Outputs breadcrumb list items following the homepage.
 *
 * @param  {object} pageData  Page data object with properties such as attributes, types, etc.
 *
 * @return {array}            Breadcrumb list items.
 */
const breadcrumbListTail = (pageData) => {
    const { attributes, type } = pageData;

    const { slug, name } = attributes;

    // The homepage has only one breadcrumb list item.
    if (slug === 'home') {
        return [];
    }

    if (type === 'animals') {
        return [
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': 'https://aucklandzoo.co.nz/animals',
                    name: 'Animals'
                }
            },
            {
                '@type': 'ListItem',
                position: 3,
                item: {
                    '@id': `https://aucklandzoo.co.nz/animals/${slug}`,
                    name
                }
            }
        ];
    }

    if (type === 'news') {
        return [
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': 'https://aucklandzoo.co.nz/news',
                    name: 'News'
                }
            },
            {
                '@type': 'ListItem',
                position: 3,
                item: {
                    '@id': `https://aucklandzoo.co.nz/news/${slug}`,
                    name
                }
            }
        ];
    }

    // Pages with parents have three breadcrumb list items.
    if (attributes.parent) {
        const { slug: parentSlug, name: parentName } = getPageParentBySlug(attributes.parent);

        return [
            {
                '@type': 'ListItem',
                position: 2,
                item: {
                    '@id': `https://aucklandzoo.co.nz/${parentSlug}`,
                    name: parentName
                }
            },
            {
                '@type': 'ListItem',
                position: 3,
                item: {
                    '@id': `https://aucklandzoo.co.nz/${parentSlug}/${slug}`,
                    name
                }
            }
        ];
    }

    // Pages without have two breadcrumb list items.
    return [
        {
            '@type': 'ListItem',
            position: 2,
            item: {
                '@id': `https://aucklandzoo.co.nz/${slug}`,
                name
            }
        }
    ];
};

/**
 * Generates a breadcrumb list structured data object.
 *
 * @param  {object} pageData  Page data object with properties such as attributes, type, etc.
 *
 * @return {object}           Structured data object.
 */
const breadcrumbList = (pageData) => ({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
        {
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': 'https://aucklandzoo.co.nz',
                name: 'Auckland Zoo'
            }
        },
        ...breadcrumbListTail(pageData)
    ]
});

/**
 * Breadcrumb markup guide can be found here:
 * https://developers.google.com/search/docs/data-types/breadcrumb
 *
 * @param  {object} pageData  Page data object with properties such as attributes, type, etc.
 *
 * @return {object}           JSX with the structured data
 */
export default (pageData) => ( // eslint-disable-line react/display-name
    <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbList(pageData)) }}
    />
);
