import { clientFetch } from 'app/utilities/fetch';
import Icon from 'app/components/partials/icon';
import ImageCropper from 'app/components/partials/image-cropper';
import Loader from 'app/components/partials/loader';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import SingleFileDropzone from 'app/components/partials/single-file-dropzone';
import { useFormik } from 'formik';
import { ENDPOINTS, ERROR_MESSAGE, RESPONSE } from 'config/api';
import React, { useEffect, useState } from 'react';

const PassholderUploadImageModal = ({ passholder, modalVisible, closeHandler, fetchPassholdersHandler }) => {
    // Used for dropzone dropped image before crop
    const [file, setFile] = useState(null);
    // Used for preview
    const [preview, setPreview] = useState(null);

    // Init preview image
    useEffect(() => {
        setPreview(passholder.profile_image_url);
    }, [passholder.profile_image_url]);

    const formik = useFormik({
        initialValues: {
            photo: null,
        },
        onSubmit: async(values, { setStatus, setSubmitting }) => {
            setStatus();

            try {
                // Update passholder's photo (generate from cropper, using base64 string)
                // Reason to use base64 string:
                // Client will call node server first and then node server call api to upload photo, use file object is way more complex in this process (need to install some library and save some temp file in node server)
                await clientFetch(ENDPOINTS.UPLOAD_PASSHOLDER_PHOTO(passholder.id), {
                    method: 'POST',
                    body: JSON.stringify({
                        photo: values.photo,
                    })
                }, true);

                const successMessage = 'Your account data has been updated.';
                setStatus({ success: successMessage });
                // Refetch the passholder information and close this modal
                fetchPassholdersHandler();
                closeModalHandler();
            } catch (err) {
                const { error, status } = err;

                const errorMessage =
                    status === RESPONSE.BAD_REQUEST &&
                    error &&
                    error.error &&
                    error.error.message || ERROR_MESSAGE.DEFAULT;

                setStatus({ error: errorMessage });
            } finally {
                setSubmitting(false);
            }
        }
    });

    // Call when dropzone catch a file, set preview and input value to null, show crop area
    const setDroppedFile = (droppedFile) => {
        setFile(droppedFile);
        setPreview(null);
        formik.setFieldValue('photo', null);
    };

    // Call when crop complete, set preview to cropped image and set input value for form to upload, hide crop area
    const setInputFile = (croppedImage) => {
        setPreview(croppedImage);
        setFile(null);
        formik.setFieldValue('photo', croppedImage);
    };

    // Close this modal and init the values
    const closeModalHandler = () => {
        setFile(null);
        setPreview(null);
        formik.setFieldValue('photo', null);
        closeHandler();
    };

    return (
        <Modal isVisible={ modalVisible } onCloseHandler={ closeModalHandler }>
            <div className="pass-upload-image-modal-content constrain-width">
                <form className="pass-upload-image-form" onSubmit={formik.handleSubmit}>
                    <h4>Please upload a photo for your membership</h4>
                    <p>Your photo must be:</p>
                    <ul>
                        <li>Portrait with your face visible. Filtered or multiple individual photo will not be accepted</li>
                        <li>Saved in standard photo format .jpg, .jpeg, .png or .heif</li>
                        <li>Maximum file size 5MB</li>
                    </ul>
                    <p>Click the grey box below to upload your photo. If you are on mobile, you can use your camera app to take a photo to upload.</p>
                    <p>Using the cropping tool, adjust your photo into the specified aspect ratio.</p>
                    <p className="import-message">Note: Photos can only be uploaded once. Please email us <a href = "mailto: annual.passes@aucklandzoo.co.nz">annual.passes@aucklandzoo.co.nz</a> if any changes are required.</p>
                    <SingleFileDropzone setDroppedFile={setDroppedFile} />
                    {file && <ImageCropper file={file} setInputFile={setInputFile} />}
                    <div className="image-container">
                        {preview && <img src={ preview } alt="preview" />}
                        {!preview && !file && <Icon name="user-circle" width="180" height="210" title="preview" />}
                    </div>
                    {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}
                    {formik.status && formik.status.success && <div className="success-message">{formik.status.success}</div>}
                    <div>
                        <button type="submit" className="button primary" disabled={!formik.values.photo}>{formik.isSubmitting ? <Loader type="small" /> : 'Submit'}</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

PassholderUploadImageModal.propTypes = {
    passholder: PropTypes.object.isRequired,
    modalVisible: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired,
    fetchPassholdersHandler: PropTypes.func.isRequired
};

export default PassholderUploadImageModal;
