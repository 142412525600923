/*
    Breakpoints – Same as app/sass/variables.scss
 */
export const TO_TINY_SCREEN_W = '(max-width: 400px)';
export const TO_SMALL_SCREEN_W = '(max-width: 600px)';
export const TO_MEDIUM_SCREEN_W = '(max-width: 800px)';
export const TO_NORMAL_SCREEN_W = '(max-width: 1000px)';
export const TO_LARGE_SCREEN_W = '(max-width: 1200px)';

export const FROM_TINY_SCREEN_W = '(min-width: 401px)';
export const FROM_SMALL_SCREEN_W = '(min-width: 601px)';
export const FROM_MEDIUM_SCREEN_W = '(min-width: 801px)';
export const FROM_NORMAL_SCREEN_W = '(min-width: 1001px)';
export const FROM_LARGE_SCREEN_W = '(min-width: 1201px)';
